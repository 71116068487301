// ProblemContent.tsx
import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import Colors, { getColorViz } from "./common/Colors";
import Fonts from "./common/Fonts";
import Document from "./basic/Document";
import Visualization from "./Visualization";
import Expression from "./Expression";
import Button from "./basic/Button"; 
import Label from "./basic/Label";
import Button2Line from "./basic/Button2Line";
import ButtonImage from "./basic/ButtonImage";
import Icon from "./common/Icon";
import ButtonLink from "./basic/ButtonLink";
import Loading from "./basic/LoadingDots";
import { useNavigate } from "react-router-dom";
import Answer from "./Answer";
import { useProblemStateContext } from './ProblemState';
import Latex from "./basic/Latex";
import ProblemFooter from "./ProblemFooter";
import { tokenStorage } from '../apollo/tokenStorage';

const ProblemContentContainer = styled.div<{ mode: string; inputMode?: string; $horizontalPadding: number; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ mode, inputMode }) => 
    mode === 'new' && inputMode === 'Scan' ? Colors.transparent 
    : mode === 'new' && inputMode === 'Type' ? Colors.background 
    : Colors.white};
  width: 100%;
  max-width: 860px;
  height: ${({ mode }) => (mode === 'new' ? '100%' : 'auto')};
  padding: ${({ mode, $horizontalPadding }) => (mode === 'new' ? '10px 0px 0px 0px' : `${$horizontalPadding}px 10px`)};
  margin: ${({ mode }) => (mode === 'new' ? '0px' : '0px 10px')};
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 10;
  pointer-events: ${({ mode, inputMode }) => 
    mode === 'new' && inputMode === 'Scan' ? 'none' : 'auto'};
  flex: ${({ mode }) => (mode === 'new' ? '1' : 'initial')};
`;

const LabelContainer = styled.div`
  position: absolute;
  top: -20px;
  left: 38px;
  z-index: 1;
`;

const ProblemModeContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 38px;
  z-index: 1;
`;

const TextTitle = styled.div`
  font-size: 32px;
  line-height: 1.2em;
  color: ${Colors.grey8};
  font-family: ${Fonts.quicksandBold.fontFamily};
  font-weight: ${Fonts.quicksandBold.fontWeight};
  text-wrap: pretty;
`;

const AnimateTextTitle = styled(TextTitle)`
  position: relative;
  overflow: visible;
  padding: 0px 5px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: -5px;
    width: calc(100% + 10px);
    height: 100%;
    background-color: #fdff70;
    border-radius: 6px;
    z-index: -1;
    transform: scaleX(0) rotate(-0.5deg);
    transform-origin: left;
    animation: highlight 0.6s ease-in-out forwards;
    animation-delay: 0.6s;
  }

  @keyframes highlight {
    to {
      transform: scaleX(1) rotate(-0.5deg);
    }
  }
`;

const TextSub = styled.div<{ inputMode?: string }>`
  display: inline-flex;
  font-size: 24px;
  color: ${({ inputMode }) => (inputMode === 'Scan' ? Colors.white : Colors.grey8)};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  line-height: 1.4em;
  gap: 10px;
`;

const TextLight = styled.div`
  display: inline-flex;
  font-size: 24px;
  color: ${Colors.white};
  font-family: ${Fonts.quicksandLight.fontFamily};
  font-weight: ${Fonts.quicksandLight.fontWeight};
  gap: 10px;
`;

const TextLoading = styled.div`
  display: inline-flex;
  font-size: 16px;
  color: ${Colors.grey6};
  font-family: ${Fonts.quicksandLight.fontFamily};
  font-weight: ${Fonts.quicksandLight.fontWeight};
  margin: 10px;
`;

const TextError = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${Colors.red};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-size: 16px;
  margin: 10px;
  gap: 8px;
`;

const TextRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0;
  gap: 0;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  gap: 2px;
`;

const JoinContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0;
  gap: 0;
`;

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 860px;
`;

const VizGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 10px;
  height: auto;
`;

const ExpressionContainer = styled.div<{ $horizontalPadding: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 24px;
  line-height: 1.4em;
  width: 100%;
  height: auto;
  padding: 10px ${({ $horizontalPadding }) => $horizontalPadding}px;
  gap: 12px;
`;

const AnswerContainer = styled.div<{ $horizontalPadding: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 24px;
  line-height: 1.4em;
  width: 100%;
  height: auto;
  padding: 10px ${({ $horizontalPadding }) => $horizontalPadding}px;
  gap: 12px;
`;

const ErrorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const NewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: fit-content;
  height: 100%;
  gap: 10px;
  flex: 1;
`;

const LoginPromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 15%;
  gap: 10px;
`;

const InputContainer = styled.div<{ inputMode: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${Colors.white};
  border-radius: 8px;
  flex: 1;
  gap: 10px;
  padding: 20px 10px 10px 10px;
`;

const ButtonContainer = styled.div<{ $bottomOffset: number }>`
  position: ${({ $bottomOffset }) => ($bottomOffset > 0 ? 'relative' : 'sticky')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 20px;
  z-index: 10;
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  z-index: 10;
`;

const ExampleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 10px;
  gap: 10px;

  & > * {
    flex: 1;
  }
`;

const ExplainContainer = styled.div<{ $horizontalPadding: number }>`
  position: relative;
  display: flex;

  flex-direction: row;
  gap: 10px;
  @media (max-width: 358px) {
    flex-direction: column;
    gap: 2px;
  }

  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0px ${({ $horizontalPadding }) => $horizontalPadding}px;
  font-size: 14px;
  color: ${Colors.black};
  font-family: ${Fonts.quicksandSemiLight.fontFamily};
  font-weight: ${Fonts.quicksandSemiLight.fontWeight};
`;

const DividerLine = styled.hr`
  border: none;
  border-top: 1px solid ${Colors.grayPale};
  width: 100%;
  margin: 20px;
`;

const ModeNew: FunctionComponent<{
  inputValue: string,
  handleExampleProblemsClick: () => void,
  handleInputChange: (value: string) => void,
  handleSubmitClick: () => void,
  inputMode: string,
  todayProblemCount?: number,
  submitClicked: boolean,
  userInfo?: any,
  isLoggedIn: boolean,
  promptLogin: boolean,
  horizontalPadding: number,
  bottomOffset: number,
}> = ({
  inputValue,
  handleExampleProblemsClick,
  handleInputChange,
  handleSubmitClick,
  inputMode,
  todayProblemCount,
  submitClicked,
  userInfo,
  isLoggedIn,
  promptLogin,
  horizontalPadding,
  bottomOffset,
}) => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState('there');
  const hasEverLoggedIn = tokenStorage.hasEverLoggedIn();

  useEffect(() => {
    if (isLoggedIn && userInfo?.getUserInfo?.name) {
      setDisplayName(userInfo.getUserInfo.name);
    } else {
      setDisplayName('there');
    }
  }, [isLoggedIn, userInfo?.getUserInfo?.name]);

  // auto-focus the document when switching to type mode
  useEffect(() => {
    if (inputMode === 'Type' && !promptLogin) {
      const isMobile = window.innerWidth < 768;
      if (!isMobile) {
        const inputElement = document.querySelector('textarea');
        if (inputElement) {
          inputElement.focus();
        }
      }
    }
  }, [inputMode]);

  useEffect(() => {
    // if user isn't logged in but has logged in before on this device, send them to login instead
    if (isLoggedIn === false && hasEverLoggedIn === true) {
      onLogInClick('?prompt=true');
    // if user has already computed a problem and not logged in, prompt them to sign up
    } else if (promptLogin === true) {
      onSignUpClick('?prompt=true');
    }
  }, [promptLogin, hasEverLoggedIn, isLoggedIn]);

  const onSignUpClick = (urlEnding?) => {
    navigate("/sign-up" + urlEnding);
  };

  const onLogInClick = (urlEnding?) => {
    navigate("/log-in" + urlEnding);
  };

  // console.log('frontend today problem count', todayProblemCount);

  return (
    <>
      {inputMode === 'Type' && (
        <>
          <ProblemContentContainer mode="new" inputMode={inputMode} $horizontalPadding={horizontalPadding} >
            {promptLogin ? (
              <LoginPromptContainer>
                <TextContainer>
                  <img src="seesolve-logo.svg" alt="logo" width={22} height={22} style={{ margin: '10px' }}/>
                  <AnimateTextTitle>Join SeeSolve</AnimateTextTitle>
                  <TextSub>to solve more problems.</TextSub>
                  <JoinContainer>
                    <TextRow>
                      <div style={{ margin: '10px' }}>
                        <Button size={'medium'} text={'Sign Up'} color={'black'} onClick={onSignUpClick} />
                      </div>
                      <TextSub> or </TextSub>
                      <div style={{ margin: '10px' }}>
                        <Button size={'medium'} text={'Log In'} color={'white'} onClick={onLogInClick} />
                      </div>
                      {/* <ButtonLink text={'Log In'} size={'large'} color={'black'} onClick={onLogInClick} /> */}
                    </TextRow>
                  </JoinContainer>
                </TextContainer>
              </LoginPromptContainer>
            ) : (
              <NewContainer id="center-container">
                <TextContainer>
                  <TextSub>Hi {displayName}! 👋 </TextSub>
                  <TextTitle>Need math help?</TextTitle>
                </TextContainer>
                <InputContainer inputMode={inputMode}>
                  <Document placeholder={"Type a math problem..."} value={inputValue} multiline={false} onChange={handleInputChange} horizontalPadding={horizontalPadding} />
                </InputContainer>
              </NewContainer>
            )}
            {!promptLogin && (
              <ButtonContainer $bottomOffset={bottomOffset}>
                  <Button size={'medium'} text={'Solve'} icon={'send-right'} color={'black'} onClick={handleSubmitClick} disabled={inputValue.trim() === ''} />
              </ButtonContainer>
            )}
            <BottomContainer>
              <ExampleContainer>
                <ButtonImage imgFile={'/ui-assets/example-fish.png'} onClick={handleExampleProblemsClick} />
                <Button2Line text={'Example Problems'} icon={'up'} color={'black'} onClick={handleExampleProblemsClick} />
                <ButtonImage imgFile={'/ui-assets/example-fraction.png'} onClick={handleExampleProblemsClick} />
              </ExampleContainer>
            </BottomContainer>
          </ProblemContentContainer>
        </>
      )}
      {inputMode === 'Scan' && (
        <>
          <ProblemContentContainer mode="new" inputMode={inputMode} $horizontalPadding={horizontalPadding} >
            <TextSub inputMode={inputMode}>Scan a math problem.</TextSub>
            {/* <TextLight>COMING SOON</TextLight> */}
          </ProblemContentContainer>
        </>
      )}
    </>
  );  
};

const GRADUAL_REVEAL = true;
const EXPLAIN_BUTTON = true;

const ModeExampleOrSolution: FunctionComponent<{
  mode: 'example' | 'solution',
  currentProblem: any,
  inputValue: string,
  loading: boolean,
  error: boolean,
  userInfo?: any,
  horizontalPadding: number,
  bottomOffset: number,
}> = ({
  mode,
  currentProblem,
  inputValue,
  loading,
  error,
  userInfo,
  horizontalPadding,
  bottomOffset,
}) => {
  // if (mode === 'example' && currentProblem) {
  //   currentProblem.visibleParts = 100;
  // } else if (mode === 'solution' && currentProblem && currentProblem.visibleParts === undefined) {
  if (currentProblem && currentProblem.visibleParts === undefined) {
    if (GRADUAL_REVEAL) {
      const firstPartWithAnswer = currentProblem.parts.findIndex(part => part.answer);
      // console.log('first part with answer', firstPartWithAnswer);
      if (firstPartWithAnswer >= 0) {
        currentProblem.visibleParts = firstPartWithAnswer + 1; // Show the next part after the first answered one
      } else {
        currentProblem.visibleParts = 100; // Show all parts if no answers
      }
    } else {
      currentProblem.visibleParts = 100;
    }
  }

  const revealPartsOnCorrect = (isCorrect: boolean, partIndex: number) => {
    if (
      isCorrect &&
      currentProblem &&
      partIndex < currentProblem.visibleParts
    ) {
      // Find next part with an answer after current part
      const nextPartWithAnswer = currentProblem.parts.findIndex((part, index) => 
        index > partIndex && part.answer
      );

      if (nextPartWithAnswer >= 0) {
        // Reveal up to and including next part with answer
        currentProblem.visibleParts = nextPartWithAnswer + 1;
      } else {
        // If no more parts with answers, reveal all remaining parts
        currentProblem.visibleParts = currentProblem.parts.length;
      }
    }
  };

  // if (currentProblem?.visibleParts) console.log('visible parts', currentProblem.visibleParts);

  const [showFeedback, setShowFeedback] = useState<Record<number, boolean | null>>({});
  const updateShowFeedback = (partIndex: number, feedback: boolean | null) => {
    setShowFeedback(prev => ({
      ...prev,
      [partIndex]: feedback,
    }));
  };

  const [showExplain, setShowExplain] = useState<boolean[]>([]);
  useEffect(() => {
    if (!currentProblem?.id) {
      setShowExplain([]);
      return;
    }

    if (EXPLAIN_BUTTON) {
      setShowExplain(currentProblem.parts.map((part) => {
        if (part?.answer && (part?.solution || part?.expressions?.length > 0)) {
          return false;
        }
        return true;
      }));
    } else {
      setShowExplain(new Array(currentProblem.parts.length).fill(true));
    }
  }, [currentProblem?.id]);

  const handleNextPartClick = (partIndex: number) => {
    // First update the visible parts
    if (currentProblem) {
      const nextPartWithAnswer = currentProblem.parts.findIndex((part, index) => 
        index > partIndex && part.answer
      );

      if (nextPartWithAnswer >= 0) {
        currentProblem.visibleParts = nextPartWithAnswer + 1;
      } else {
        currentProblem.visibleParts = currentProblem.parts.length;
      }
    }

    // Force a re-render and then scroll
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        const nextPartElement = document.querySelector(`[data-part-index="${partIndex + 1}"]`);
        if (nextPartElement) {
          nextPartElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  };

  // console.log('current problem', currentProblem);
  // console.log('status', currentProblem?.status);
  // status = IN_PROGRESS, SUCCESS, ERROR
  
  return (
    <>
      <ProblemContentContainer mode={mode} key={currentProblem?.id} $horizontalPadding={horizontalPadding} >
        <DocContainer className="initial-doc-container">
          <LabelContainer>
            {mode === 'example' && currentProblem?.label && (
              <Label text={currentProblem.label} />
            )}
          </LabelContainer>
          <ProblemModeContainer>
            {mode === 'solution' && currentProblem?.problemMode && (
              <Label text={`${currentProblem.problemMode} Mode`} />
            )}
          </ProblemModeContainer>
          <Document value={currentProblem ? inputValue : inputValue} multiline={true} readOnly={true} onChange={() => {}} horizontalPadding={horizontalPadding} />
        </DocContainer>
        {(currentProblem?.status && currentProblem?.status === 'ERROR') ? (
          <ErrorContainer>
            <TextError>
              {/* <Icon icon={'delete-fill'} color={Colors.red} size={22} onClick={()=>{}} />  */}
              ❗ Error generating response.
            </TextError>
            {/* <Button size={'small'} text={'Retry'} icon={'reset'} color={'black'} onClick={()=>{}} /> */}
          </ErrorContainer>
        ) : (
          currentProblem && currentProblem.parts && currentProblem.parts.map((part, partIndex) => (
            <React.Fragment key={partIndex}>
              {partIndex < currentProblem.visibleParts && ( // only render parts within visible parts
                <>
                  <React.Fragment>
                    {partIndex > 0 && part.text && (
                      <DocContainer data-part-index={partIndex}>
                        <Document value={part.text} multiline={true} readOnly={true} onChange={() => {}} horizontalPadding={horizontalPadding} />
                      </DocContainer>
                    )}
                    {part.visualizations && part.visualizations.length > 0 && (
                      <VizGrid className="no-clear-hover" data-part-index={partIndex}>
                        {part.visualizations.map((viz, index) => {
                          if (!viz.feedback) { // only render visualizations without feedback
                            return (
                              <Visualization
                                key={index}
                                visualization={viz}
                                index={index}
                                partIndex={partIndex}
                                problemId={currentProblem.id}
                              />
                            );
                          }
                          return null;
                        })}
                      </VizGrid>
                    )}
                    {part.answer && (
                      <AnswerContainer key={currentProblem.id} data-part-index={partIndex} $horizontalPadding={horizontalPadding}>
                        <Answer 
                          showFeedback={(feedback) => updateShowFeedback(partIndex, feedback)}
                          onCorrect={(isCorrect) => {
                            revealPartsOnCorrect(isCorrect, partIndex);
                          }}
                          onNext={() => handleNextPartClick(partIndex)}
                          multipleChoice={part.answer.multipleChoice} 
                          multiselect={part.answer.multiselect} 
                          correctAnswer={part.answer.correctAnswer} 
                          vizUserAnswer={part.answer.vizUserAnswer}
                          hasNextPart={partIndex < currentProblem.parts.length - 1}
                        />
                      </AnswerContainer>
                    )}
                    {part.answer && (part.solution || part.expressions?.length > 0) && showExplain[partIndex] === false && (
                      <ExplainContainer $horizontalPadding={horizontalPadding}>
                        Still don't get it?
                        <Button size={'tiny'} text={'🤔 Explain Step-by-Step'} color={'white'} onClick={()=>{setShowExplain(prev => ({...prev, [partIndex]: true}))}} />
                      </ExplainContainer>
                    )}
                    {showExplain[partIndex] === true && (
                      <>
                        {part.expressions && part.expressions.length > 0 && (
                          <ExpressionContainer className="no-clear-hover" data-part-index={partIndex} $horizontalPadding={horizontalPadding}>
                            {part.expressions.map((expr, index) => (
                              <Expression
                                key={index}
                                problemId={currentProblem.id}                            
                                expression={expr}
                                partIndex={partIndex}
                                index={index}
                              />
                            ))}
                          </ExpressionContainer>
                        )}
                        {part.solution && (
                          <DocContainer data-part-index={partIndex} id={`solution`} className="solution-container">
                            {Array.isArray(part.solution) ? (
                              <Latex content={part.solution.map((solution, index) => `${solution}\n\n`).join('')} fontSize={18} padding={`0px ${horizontalPadding}px`} />
                            ) : (
                              <Latex content={part.solution} fontSize={18} padding={`0px ${horizontalPadding}px`}/>
                            )}
                          </DocContainer>
                        )}
                      </>
                    )}
                    {showFeedback[partIndex] && part.visualizations && part.visualizations.length > 0 && part.visualizations.some((viz) => viz.feedback) && (
                      <VizGrid className="no-clear-hover" data-part-index={partIndex}>
                        {part.visualizations.map((viz, index) => {
                          if (viz.feedback) { // only render visualizations with feedback if showFeedback
                            return (
                              <Visualization
                                key={index}
                                visualization={viz}
                                index={index}
                                partIndex={partIndex}
                                problemId={currentProblem.id}
                              />
                            );
                          }
                          return null;
                        })}
                      </VizGrid>
                    )}
                  </React.Fragment>
                  {partIndex < currentProblem.parts.length - 1 && part.answer && partIndex !== currentProblem.visibleParts - 1 && (
                    <DividerLine></DividerLine>
                  )}
                </>
              )}
            </React.Fragment>
          ))
        )}
        {(loading === true || (currentProblem?.status && currentProblem?.status === 'IN_PROGRESS' // special logic for polling
          && (currentProblem?.parts[0].solution !== undefined || currentProblem?.parts[0].solution !== null))) && (
            <><Loading/>
            {/* <TextLoading>This might take a while. We'll let you know at {userInfo?.getUserInfo?.email} when it's ready.</TextLoading> */}</>
        )}
      </ProblemContentContainer>
      {mode === 'solution' && !loading && currentProblem?.status !== 'IN_PROGRESS' && (
        <ProblemFooter problem={currentProblem} />
      )}
    </>
  );
};

const ProblemContent: FunctionComponent<{
  inputValue: string;
  mode: 'new' | 'example' | 'solution';
  currentProblem: any;
  handleInputChange: (value: string) => void;
  loading: boolean;
  handleSubmitClick: () => void; 
  handleExampleProblemsClick?: () => void; 
  error?: boolean;
  inputMode: 'Type' | 'Scan'; 
  todayProblemCount?: number;
  submitClicked: boolean;
  userInfo: any;
  isLoggedIn: boolean;
  promptLogin: boolean;
  bottomOffset: number;
}> = ({
  inputValue,
  mode,
  currentProblem,
  handleInputChange,
  loading,
  handleSubmitClick,  
  handleExampleProblemsClick,
  error,
  inputMode,
  todayProblemCount,
  submitClicked,
  userInfo,
  isLoggedIn,
  promptLogin,
  bottomOffset,
}) => {
  const { problemsData } = useProblemStateContext();
  const currentProblemData = currentProblem ? problemsData.find(problem => problem.id === currentProblem.id) : null;
  // console.log('current problem data', currentProblemData);
  // console.log('content loading', loading, currentProblem?.id);

  const HORIZONTAL_PADDING = { default: 32, mobile: 10 };
  const horizontalPadding = window.innerWidth < 400 ? HORIZONTAL_PADDING.mobile : HORIZONTAL_PADDING.default;

  return (
    <>
      {mode === 'new' && (
        <ModeNew
          inputValue={inputValue}
          handleExampleProblemsClick={handleExampleProblemsClick}
          handleInputChange={handleInputChange}
          handleSubmitClick={handleSubmitClick}
          inputMode={inputMode}
          todayProblemCount={todayProblemCount}
          userInfo={userInfo}
          isLoggedIn={isLoggedIn}
          submitClicked={submitClicked}
          promptLogin={promptLogin}
          horizontalPadding={horizontalPadding}
          bottomOffset={bottomOffset}
        />
      )}
      {(mode === 'example' || mode === 'solution') && (
        <ModeExampleOrSolution
          key={currentProblem?.id}
          mode={mode}
          currentProblem={currentProblemData}
          inputValue={inputValue}
          loading={loading}
          error={error}
          userInfo={userInfo}
          horizontalPadding={horizontalPadding}
          bottomOffset={bottomOffset}
        />
      )}
    </>
  );
};

export default ProblemContent;
