// CaptureButton.tsx
import React, { FunctionComponent, useState, useEffect, MouseEvent, TouchEvent as ReactTouchEvent } from "react";
import styled from "styled-components";
import Colors from "../common/Colors";
import _ from "lodash";

const CaptureButtonRoot = styled.div<{ $isHovered: boolean, $isActive: boolean, $isDisabled: boolean }>`
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
    -webkit-tap-highlight-color: transparent;

    &:before {
        content: "";
        position: absolute;
        width: 62px;
        height: 62px;
        background-color: ${Colors.white};
        border-radius: 50%;
        ${({ $isHovered }) => $isHovered && `
            width: 58px;
            height: 58px;
            transition: all 0.2s ease;
        `}
        ${({ $isActive }) => $isActive && `
            width: 68px;
            height: 68px;
        `}
        ${({ $isDisabled }) => $isDisabled && `
            background-color: rgba(255, 255, 255, 0.2);
        `}
    }

    &:after {
        content: "";
        position: absolute;
        width: 78px;
        height: 78px;
        border: 4px solid ${props => props.$isDisabled ? `${Colors.yellow}40` : Colors.yellow};
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
    }
`;

interface CaptureButtonProps {
    onClick: (event?: MouseEvent<HTMLDivElement> | ReactTouchEvent<HTMLDivElement> | undefined) => void;
    disabled?: boolean;
}

const CaptureButton: FunctionComponent<CaptureButtonProps> = ({ onClick, disabled }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (disabled) {
          setIsHovered(false);
          setIsActive(false);
        }
    }, [disabled]);

    const playCameraSound = () => {
        const cameraSoundObject = new Audio('/sound-effects/camera-snap.mp3');
        cameraSoundObject.currentTime = 0; // Set the time to 0 to restart the sound
        cameraSoundObject.play();
      };

    const onClickRaw = (e: MouseEvent<HTMLDivElement> | ReactTouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!disabled) {
          // Type guard to ensure correct event type
          if (e.type === 'click' || e.type === 'mousedown' || e.type === 'mouseup') {
            playCameraSound();
            onClick(e as MouseEvent<HTMLDivElement>);
          } else if (e.type === 'touchstart' || e.type === 'touchend') {
            playCameraSound();
            onClick(e as ReactTouchEvent<HTMLDivElement>);
          }
        }
    };  

    return (
        <CaptureButtonRoot
            $isHovered={isHovered}
            $isActive={isActive}
            $isDisabled={disabled}
            onClick={onClickRaw}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
            onMouseDown={() => !disabled && setIsActive(true)}
            onMouseUp={() => !disabled && setIsActive(false)}
            onTouchStart={() => !disabled && setIsActive(true)}
            onTouchEnd={() => !disabled && setIsActive(false)}
        />
    );
};

export default CaptureButton;
