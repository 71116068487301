// Icon.tsx
import { FunctionComponent, useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

type IconProps = {
    icon: string;
    // 'arrow-right' | 'camera' | 'check' | 'delete' | 'down' | 'up' | 'exclamation' | 
    // 'info' | 'key' | 'lock' | 'mail' | 'page' | 'person' | 'plus' | 'question' | 
    // 'scan' | 'table' | 'upload' | 'x' | 'home' | 'logout' | 'page-outline' | 'delete-fill' | 
    // 'check-fill' | 'logo' | 'left' | 'loading' | 'reset' | 'expand' | 'right' | 'send' |
    // 'copy' | 'thumbs-up' | 'thumbs-down | 'keyboard' | 'image' | 'link' | 'caret' | 'eye' | 'lightbulb';
    color: string; // Hex color code
    size: number; // Size in pixels
    onClick?: () => void;
};

const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const shouldForwardProp = (prop: string) => prop !== 'rotate';

const IconWrapper = styled.div.withConfig({
  shouldForwardProp,
})<{ size: number; rotate?: boolean }>`
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    min-height: ${({ size }) => `${size}px`};
    min-width: ${({ size }) => `${size}px`};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;

    svg {
        height: 100%;
        width: 100%;
        pointer-events: none;
        animation: ${({ rotate }) => rotate ? css`${rotateAnimation} 2s linear infinite` : 'none'};
    }
`;

const Icon: FunctionComponent<IconProps> = ({ icon, color, size, onClick = () => {} }) => {
    const [svgContent, setSvgContent] = useState('');

    const doOnClick = () => {
        onClick();
    };

    useEffect(() => {
        fetchSvgContent(icon).then(svg => {
            const updatedSvg = svg
                .replace(/fill="[^"]*"/g, match => (match.includes('none') ? match : `fill="${color}"`)) // Replace the fill color if it's not 'none'
                .replace(/stroke="[^"]*"/g, `stroke="${color}"`) // Replace the stroke color
                .replace(/<svg/, `<svg width="${size}" height="${size}"`); // Explicitly set width and height
            setSvgContent(updatedSvg);
        }).catch(() => {
            console.error(`Icon ${icon} not found`);
            setSvgContent(''); // Handle the case where the SVG is not found
        });
    }, [icon, color, size]);

    return (
        <IconWrapper onClick={doOnClick} size={size} rotate={icon === 'loading'} id='icon-wrapper'>
            <div dangerouslySetInnerHTML={{ __html: svgContent }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
        </IconWrapper>
    );
};

export default Icon;

async function fetchSvgContent(icon: string): Promise<string> {
    const response = await fetch(`/icons/icon-${icon}.svg`);
    if (!response.ok) {
        throw new Error('Icon not found');
    }
    return await response.text();
}
