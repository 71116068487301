// apolloClient.js
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { tokenStorage } from './tokenStorage';
// import { createBrowserHistory } from 'history';
import {onError} from "@apollo/client/link/error";

// Use an environment variable for the URI, with a default for local development
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://l7uxwkqx3d.execute-api.us-east-1.amazonaws.com/dev/graphql';
// process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';
// 'https://l7uxwkqx3d.execute-api.us-east-1.amazonaws.com/dev/graphql'; // qa url
// 'https://svl6e7mq0l.execute-api.us-east-1.amazonaws.com/prod/graphql'; // prod url

const httpLink = createHttpLink({
        uri: BACKEND_URL, // Use the BACKEND_URL environment variable
});

const authLink = setContext((_, { headers }) => {
    const token = tokenStorage.getToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

// const history = createBrowserHistory();

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            console.trace(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if ((message || '').includes("Unauthorized! You must be logged in to perform this action.") && path.includes("getUserInfo")) { // Check for authentication errors
                console.warn('User is not authenticated or authorized');
                tokenStorage.clearToken();
                // if (history.location.pathname !== '/log-in' && history.location.pathname !== '/') {
                //     window.location.href = '/log-in';
                // }
            }
        });
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]), // Include errorLink in the chain
    cache: new InMemoryCache(),
});

export default client;