import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Input from "../components/basic/Input";
import Button from "../components/basic/Button";
import ButtonLink from "../components/basic/ButtonLink";
import Fonts from "../components/common/Fonts";
import Colors from "../components/common/Colors";
import { useDoPasswordReset, getIsLoggedIn } from '../apollo/useAuth';
import Toast from "../components/basic/Toast";

const ResetRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.background};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.quicksandMedium.fontFamily};
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const CenterContainer = styled.div`
  margin: 0;
  width: 343px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
`;
const ResetHeader = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.quicksandMedium.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
`;
const SubText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  padding-bottom: 26px;
  cursor: text;
`;
const InputContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  padding-top: 20px;
`;
const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  max-width: 400px;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const ToastContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
`;

const ResetPassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { doPasswordReset, error: resetError, data: resetData } = useDoPasswordReset();
  const resetToken = searchParams.get('resetToken');
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const onResetPasswordClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // console.log('Reset password process initiated.');

    if (!resetToken) {
      setError('Click the link in your password reset email.');
      setIsLoading(false);
      return;
    }
    
    if (password.length < 8) {
      setError('Your password must be at least 8 characters.');
      setIsLoading(false);
      return;
    }
    
    try {
      await doPasswordReset(resetToken, password);
      // console.log('Password reset successful.');
      setShowToast(true);
      const isLoggedIn = await getIsLoggedIn();
      // console.log('User logged in status:', isLoggedIn);
      setTimeout(() => {
        navigate(isLoggedIn ? "/" : "/log-in");
        // console.log('Navigating to the appropriate page.');
      }, 2000);
      // console.log('Reset password process completed.');
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Reset link expired. Please request a new one.');
      setIsLoading(false);
      return;
    }
  };

  const onLoginClick = () => {
    navigate("/log-in");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onResetPasswordClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onResetPasswordClick]);

  return (
    <ResetRoot>
      <Logo src="/seesolve-logo.svg" alt="SeeSolve Logo" />
      {showToast && (
        <ToastContainer>
          <Toast color={'white'} bodyText={"Password updated! Logging you in..."} icon={'logo'} seconds={2} />
        </ToastContainer>
      )}
      <CenterContainer>
        <ResetHeader>New Password</ResetHeader>
        <InputContainer>
          <Input
            placeholder="Password"
            icon={'lock'}
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            errorMessage={error}
          />
        </InputContainer>
        <ButtonContainer>
          <Button text="RESET PASSWORD" onClick={onResetPasswordClick} size={'small'} fixed={true} color={'black'} icon={isLoading ? 'loading' : undefined} />
        </ButtonContainer>
      </CenterContainer>
      <BottomContainer>
        <BottomFixed>
          <FooterText>Back to</FooterText>
          <ButtonLink text="Log In" onClick={onLoginClick} />
        </BottomFixed>
      </BottomContainer>
    </ResetRoot>
  );
};

export default ResetPassword;
