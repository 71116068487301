// useImageUpload.js
import { useState, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';

// GraphQL mutation to request an S3 image upload URL
const REQUEST_IMAGE_UPLOAD_URL_MUTATION = gql`
  mutation requestImageUploadURL {
    requestImageUploadURL {
      url
      imageId
    }
  }
`;

// GraphQL mutation to confirm the image has been uploaded
const CONFIRM_IMAGE_UPLOAD_MUTATION = gql`
  mutation confirmImageUpload($imageId: ID!) {
    confirmImageUpload(imageId: $imageId) {
      id
      uploadDate
      imageS3Id
      size
      resolution
    }
  }
`;

export function useImageUpload() {
  const [requestImageUploadURLMutation] = useMutation(REQUEST_IMAGE_UPLOAD_URL_MUTATION);
  const [confirmImageUploadMutation] = useMutation(CONFIRM_IMAGE_UPLOAD_MUTATION);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const uploadInProgress = useRef(false);
  const uploadCounter = useRef(0);
  const uploadQueue = useRef([]);

  const updateLoadingState = () => {
    setLoading(uploadCounter.current > 0);
  };

  // Function to handle the actual upload process
  const processUpload = async (file) => {
    uploadCounter.current += 1; // Increment upload counter
    updateLoadingState();
    setError('');

    try {
      // Request an S3 Upload URL
      const { data: requestData } = await requestImageUploadURLMutation();
      if (!requestData || !requestData.requestImageUploadURL) {
        throw new Error("Failed to get an upload URL");
      }

      const { url, imageId } = requestData.requestImageUploadURL;

      // Determine the correct Content-Type
      let contentType = 'image/png';
      if (file instanceof File) {
        contentType = file.type;
      } else if (typeof file === 'string' && file.startsWith('data:')) {
        contentType = file.split(';')[0].split(':')[1];
      }

      // Upload the Image to S3
      const uploadResponse = await fetch(url, {
        method: 'PUT',
        body: file instanceof File ? file : await (await fetch(file)).blob(),
        headers: {
          'Content-Type': contentType,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload image to S3: ${uploadResponse.statusText}`);
      }

      // Confirm the Upload
      const { data: confirmData } = await confirmImageUploadMutation({
        variables: { imageId },
      });
      if (!confirmData || !confirmData.confirmImageUpload) {
        throw new Error("Failed to confirm image upload");
      }

      uploadCounter.current -= 1; // Decrement upload counter on success
      updateLoadingState();
      return {
        success: true,
        processedImageId: confirmData.confirmImageUpload.id,
        imageId: imageId,
        message: "Image uploaded and confirmed successfully",
      };
    } catch (error) {
      uploadCounter.current -= 1; // Decrement upload counter on success
      updateLoadingState();
      console.error('Error in processUpload:', error);
      setError(error.message || 'An error occurred during the image upload process');
      return {
        success: false,
        message: error.message || 'An error occurred during the image upload process',
      };
    }
  };

  // Function to manage uploads
  const uploadImage = async (file) => {
    if (uploadInProgress.current) {
      // If an upload is already in progress, add to queue
      return new Promise((resolve, reject) => {
        const callback = async () => {
          try {
            const uploadResult = await processUpload(file);
            resolve(uploadResult);
          } catch (error) {
            reject(error);
          }
        };

        uploadQueue.current.push({ file, callback });
      });
    } else {
      // Handle the first upload
      uploadInProgress.current = true;
      const uploadResult = await processUpload(file);
      uploadInProgress.current = false;

      // Process the next items in the queue, if any
      while (uploadQueue.current.length > 0) {
        const { file: nextFile, callback } = uploadQueue.current.shift();
        await callback(); // Call the callback function to process the next upload
      }

      return uploadResult;
    }
  };

  return { uploadImage, loading, error };
}

const DELETE_IMAGES_MUTATION = gql`
  mutation deleteImages($imageIds: [ID!]!) {
    deleteImages(imageIds: $imageIds)
  }
`;

export function useDeleteImages() {
  const [deleteImagesMutation] = useMutation(DELETE_IMAGES_MUTATION);

  const deleteImages = (imageIds) =>
    deleteImagesMutation({
      variables: { imageIds },
    });

  return { deleteImages };
}
