import { problem } from "../Generate";
import * as math from 'mathjs';

export const generateInequality = (): problem[] => {
    const problems: problem[] = [];
    let count = 0;

    const generateBasic = (
        start: number,
        end: number,
        startInclusive: boolean,
        endInclusive: boolean,
        min: number,
        max: number,
        name: string
    ) => {
        count += 1;
        
        const startSymbol = startInclusive ? "≤" : "<";
        const endSymbol = endInclusive ? "≤" : "<";
        
        const template = {
            id: crypto.randomUUID(),
            createdAt: new Date().toISOString(),
            name: `Inequality - ${name}: Number Line`,
            numColors: 1,
            text: `${start} ${startSymbol} x ${endSymbol} ${end}`,
            visualizations: [
                {
                    type: "numberLine",
                    min,
                    max,
                    tick: 1,
                    data: [
                        {
                            type: "line",
                            start,
                            startVar: "start",
                            end,
                            endVar: "end",
                            startPoint: startInclusive ? "dot" : "circle",
                            endPoint: endInclusive ? "dot" : "circle",
                            startLabel: "${start}",
                            endLabel: "${end}",
                            lineLabel: `${start} ${startInclusive ? "≤" : "<"} x ${endInclusive ? "≤" : "<"} ${end}`,
                            startColor: 1,
                            endColor: 1,
                            lineColor: 1
                        }
                    ]
                }
            ]
        };
        problems.push(template);
    };

    const generateBasicFractions = (
        startNumerator: number,
        startDenominator: number,
        endNumerator: number,
        endDenominator: number,
        startInclusive: boolean,
        endInclusive: boolean,
        min: number,
        max: number,
        name: string
    ) => {
        count += 1;

        const startSymbol = startInclusive ? "≤" : "<";
        const endSymbol = endInclusive ? "≤" : "<";

        // Convert to mixed numbers if needed
        const getDisplayStr = (numerator: number, denominator: number) => {
            const fraction = math.fraction(numerator, denominator);
            const isNegative = fraction.s < 0;
            const wholePart = Math.floor(Math.abs(fraction.n / fraction.d));
            const remainder = Math.abs(fraction.n % fraction.d);
            if (wholePart > 0) {
                return `${isNegative ? '-' : ''}${wholePart}` + (remainder > 0 ? ` ${remainder}/${Math.abs(fraction.d)}` : ``);
            }

            const simplify = (numerator: number, denominator: number) => {
                const gcd = math.gcd(numerator, denominator);
                return {
                    numerator: numerator / gcd,
                    denominator: denominator / gcd
                };
            };

            const simplified = simplify(numerator, denominator);
            return `${simplified.numerator}/${simplified.denominator}`;
        };

        const startStr = `${startNumerator}/${startDenominator}`;
        const endStr = `${endNumerator}/${endDenominator}`;
        const startDisplayStr = getDisplayStr(startNumerator, startDenominator);
        const endDisplayStr = getDisplayStr(endNumerator, endDenominator);
        const textFormat = Math.random() < 0.5;
        
        const template = {
            id: crypto.randomUUID(),
            createdAt: new Date().toISOString(),
            name: `Inequality - ${name}: Number Line`,
            numColors: 1,
            text: `${textFormat ? startStr : startDisplayStr} ${startSymbol} x ${endSymbol} ${textFormat ? endStr : endDisplayStr}`,
            visualizations: [
                {
                    type: "numberLine",
                    min,
                    max,
                    tick: 10,
                    data: [
                        {
                            type: "line",
                            start: startNumerator / startDenominator,
                            startVar: "start",
                            end: endNumerator / endDenominator,
                            endVar: "end",
                            startPoint: startInclusive ? "dot" : "circle",
                            endPoint: endInclusive ? "dot" : "circle",
                            startLabel: startDisplayStr,
                            endLabel: endDisplayStr,
                            lineLabel: `${startDisplayStr} ${startInclusive ? "≤" : "<"} x ${endInclusive ? "≤" : "<"} ${endDisplayStr}`,
                            startColor: 1,
                            endColor: 1,
                            lineColor: 1
                        }
                    ]
                }
            ]
        };
        problems.push(template);
    };

    const generateCompound = (
        start1: number,
        start2: number,
        start1Inclusive: boolean,
        start2Inclusive: boolean,
        min: number,
        max: number,
        isAnd: boolean,
        name: string
    ) => {
        count += 1;
        
        const symbol1 = start1Inclusive ? "≤" : "<";
        const symbol2 = start2Inclusive ? "≥" : ">";
        const conjunction = isAnd ? "and" : "or";
        
        const template = {
            id: crypto.randomUUID(),
            createdAt: new Date().toISOString(),
            name: `Inequality - ${name}: Number Line`,
            numColors: isAnd ? 1 : 2,
            text: `x ${symbol1} ${start1} ${conjunction} x ${symbol2} ${start2}`,
            visualizations: [
                {
                    type: "numberLine",
                    min,
                    max,
                    tick: 1,
                    data: [
                        {
                            type: "line",
                            start: start1,
                            startVar: "start1",
                            end: min,
                            startPoint: start1Inclusive ? "dot" : "circle",
                            endPoint: "arrow",
                            startLabel: "${start1}",
                            lineLabel: `x ${start1Inclusive ? "≤" : "<"} ${start1}`,
                            startColor: 1,
                            endColor: 1,
                            lineColor: 1
                        },
                        {
                            type: "line",
                            start: start2,
                            startVar: "start2",
                            end: max,
                            startPoint: start2Inclusive ? "dot" : "circle",
                            endPoint: "arrow",
                            startLabel: "${start2}",
                            lineLabel: `x ${start2Inclusive ? "≥" : ">"} ${start2}`,
                            startColor: isAnd ? 1 : 2,
                            endColor: isAnd ? 1 : 2,
                            lineColor: isAnd ? 1 : 2
                        }
                    ]
                }
            ]
        };
        problems.push(template);
    };

    const problemSettings = [
        {
            name: "Basic 10",
            PERCENT_TO_GEN: 0.05,
            min: -10,
            max: 10,
            interval: 1,
        },
        {
            name: "Basic Fractions 10",
            PERCENT_TO_GEN: 0.001,
            min: -10,
            max: 10,
            interval: 1,
        },
        {
            name: "Compound 10", 
            PERCENT_TO_GEN: 0.08,
            min: -9,
            max: 9,
            interval: 1,
        }
    ];

    // Generate basic, -10 to 10
    let config = problemSettings[0];
    for (let start = config.min; start < config.max; start+=config.interval) {
        for (let end = start + config.interval; end <= config.max; end+=config.interval) {
            if (Math.random() < config.PERCENT_TO_GEN) {
                const min = start >= 0 ? 0 : Math.floor(start / 10) * 10;
                const max = end <= 0 ? 0 : Math.ceil(end / 10) * 10;
                
                const startInclusive = Math.random() < 0.5;
                const endInclusive = Math.random() < 0.5;
                generateBasic(start, end, startInclusive, endInclusive, min, max, config.name);
            }
        }
    }

    // Generate basic fractions, -10 to 10
    config = problemSettings[1];
    for (let startDenominator = 2; startDenominator <= 5; startDenominator++) {
        for (let startNumerator = Math.ceil(config.min * startDenominator); startNumerator < config.max * startDenominator; startNumerator++) {
            for (let endDenominator = 2; endDenominator <= 5; endDenominator++) {
                for (let endNumerator = Math.ceil(startNumerator * endDenominator / startDenominator) + 1; 
                     endNumerator <= config.max * endDenominator; endNumerator++) {
                    
                    const start = startNumerator / startDenominator;
                    const end = endNumerator / endDenominator;
                    const diff = Math.abs(end - start);
                    const min = start >= 0 ? 0 : Math.floor(start / 10) * 10;
                    const max = end <= 0 ? 0 : Math.ceil(end / 10) * 10;
                    
                    if (start >= config.min && end <= config.max && start < end && diff >= 1 
                        && startNumerator !== 0 && endNumerator !== 0 
                        && startDenominator !== 1 && endDenominator !== 1
                        && !Number.isInteger(start) && !Number.isInteger(end)) {
                        if (Math.random() < config.PERCENT_TO_GEN) {
                            generateBasicFractions(
                                startNumerator, startDenominator,
                                endNumerator, endDenominator,
                                Math.random() < 0.5, Math.random() < 0.5,
                                min, max, config.name
                            );
                        }
                    }
                }
            }
        }
    }

    // Generate compound, -9 to 9
    config = problemSettings[2];
    for (let start1 = config.min; start1 < config.max; start1+=config.interval) {
        for (let start2 = start1 + config.interval; start2 <= config.max; start2+=config.interval) {
            if (Math.random() < config.PERCENT_TO_GEN) {
                const min = Math.min(start1, start2) > 0 ? 0 : -10;
                const max = Math.max(start1, start2) < 0 ? 0 : 10;
                
                const start1Inclusive = Math.random() < 0.5;
                const start2Inclusive = Math.random() < 0.5;
                const isAnd = Math.random() < 0.5;
                generateCompound(start1, start2, start1Inclusive, start2Inclusive, min, max, isAnd, config.name);
            }
        }
    }

    return problems;
};
