// LoadingSpinner.tsx
import React, { FunctionComponent } from "react";
import styled from 'styled-components'; 
import Box from '@mui/material/Box'; 
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'; 
import Colors from "../common/Colors";

const LoadingRoot = styled.div<{ size: string }>`
    position: relative;
    background-color: ${props => props.size === 'small' ? Colors.transparent : Colors.white};
    width: ${props => props.size === 'fullscreen' ? '100%' : 'auto'};
    height: ${props => props.size === 'fullscreen' ? '100svh' : 'auto'};
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

function CustomCircularProgress(props: CircularProgressProps & { colormode?: 'light' | 'dark' }) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress variant="determinate" sx={{ color: props.colormode === 'light' ? Colors.grayPale : Colors.grey9 }} size={100} {...props} value={100} />
            <CircularProgress variant="indeterminate" disableShrink 
                sx={{ color: props.colormode === 'light' ? Colors.grey2 : Colors.grey7, animationDuration: '800ms', position: 'absolute', left: 0, [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' } }} size={100} {...props} />
        </Box>
    );
}

interface LoadingProps {
    size?: 'fullscreen' | 'small';
    colorMode?: 'light' | 'dark';
}

const LoadingSpinner: FunctionComponent<LoadingProps> = ({ size = 'fullscreen', colorMode = 'light' }) => {
    return (
        <LoadingRoot size={size}>
            <CustomCircularProgress colormode={colorMode} />
        </LoadingRoot>
    );
};

export default LoadingSpinner;
