// LoadingDots.tsx
import React from "react";
import styled, { keyframes } from "styled-components";
import Colors from "../common/Colors";

const bounce = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: ${Colors.grey3};
  opacity: 1;
  animation: ${bounce} 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const LoadingDots = () => {
  return (
    <LoadingWrapper>
      <Dot />
      <Dot />
      <Dot />
    </LoadingWrapper>
  );
};

export default LoadingDots;
