// Fonts.tsx
const Fonts = {
    quicksandLight: {
        fontFamily: 'Quicksand',
        fontWeight: 300,
    },
    quicksandSemiLight: {
        fontFamily: 'Quicksand',
        fontWeight: 400,
    },
    quicksandMedium: {
        fontFamily: 'Quicksand',
        fontWeight: 500,
    },
    quicksandSemiBold: {
        fontFamily: 'Quicksand',
        fontWeight: 600,
    },
    quicksandBold: {
        fontFamily: 'Quicksand',
        fontWeight: 700,
    },
};

export default Fonts;
