// index.tsx
import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme, StyledEngineProvider, } from "@mui/material";

import { ApolloProvider } from '@apollo/client';
import client from './apollo/apolloClient';
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';

// DISABLE AD BLOCKER FOR POSTHOG TESTING!!!!!!!!

const posthog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'phc_AH5SmkZJmMu82YhwPTHGxnFMqLOcz5jZm46TKbYjCUP';
const posthog_api_host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com';

const configurePosthog = () => {
  if (!posthog_api_host || !posthog_key) {
    console.warn('Posthog configuration missing.', posthog_api_host, posthog_key);
    return;
  } else {
    if (!posthog || posthog.__loaded === false && !window.location.host.includes('localhost')) {
      posthog.init(posthog_key, {
        api_host: posthog_api_host,
        autocapture: true,
        enable_recording_console_log: true,
        enable_heatmaps: true,
        session_recording: {
          maskAllInputs: true,
          maskInputFn: (text, element) => {
            // Check if the parent element (or any ancestor) has the data-unmask attribute
            if (element?.closest('[data-unmask="true"]')) {
              // console.log('Unmasking content:', text);
              return text; // Unmask if specified
            }
            // console.log('Masking content:', text);
            return '*'.repeat(text.length); // Mask input with asterisks
          }
        }
      });
    }
    // console.log('Posthog', posthog);
    // console.log('Posthog Configuration:', '\n  api_host:', posthog_api_host, '\n  key:', posthog_key);
  }
};
configurePosthog();

const container = document.getElementById("root");
const root = createRoot(container!);

const muiTheme = createTheme({
  components: {
      // Override styles for MUI's CssBaseline component
      MuiCssBaseline: {
          styleOverrides: `
      html, body {
        overscroll-behavior-y: none;
        height: 100%;
        margin: 0;
        padding: 0;
      }
    `,
      },
  },
});

root.render(
  <ApolloProvider client={client}>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={{api_host: posthog_api_host}}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </PostHogProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
