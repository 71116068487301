// ContentPage.tsx
import { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../../components/common/Colors";
import Fonts from "../../components/common/Fonts";

const ContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100svh;
  width: 100%;
  background-color: ${Colors.background};
  box-sizing: border-box;
  text-align: left;
  font-size: 18px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  overflow: hidden;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 40px 20px 10px 20px;
  max-width: 800px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 48px;
  line-height: 1em;
  font-weight: ${Fonts.quicksandBold.fontWeight};
  font-family: ${Fonts.quicksandBold.fontFamily};
  color: ${Colors.black};
`;

const Subtitle = styled.h2`
  font-size: 24px;
  line-height: 1em;
  font-weight: ${Fonts.quicksandSemiLight.fontWeight};
  font-family: ${Fonts.quicksandSemiLight.fontFamily};
  color: ${Colors.grey4};
`;

const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100svh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  padding: 20px 20px 40px 20px;
  width: 100%;
  max-width: 860px;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const ContentHeader = styled.h3`
  font-size: 20px;
  line-height: 1.4em;
  font-weight: ${Fonts.quicksandBold.fontWeight};
  font-family: ${Fonts.quicksandBold.fontFamily};
  color: ${Colors.grey8};
  margin: 0;
`;

const ContentBody = styled.div`
  font-size: 16px;
  line-height: 1.4em;
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  color: ${Colors.grey8};
`;

interface ContentItem {
  header: string;
  body: (string | JSX.Element)[];
}

interface ContentPageProps {
  title: string;
  subtitle: string;
  content: ContentItem[];
}

const ContentPage: FunctionComponent<ContentPageProps> = ({ title, subtitle, content }) => {
    return (
      <ContentRoot>
        <TitleContainer>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </TitleContainer>
        <ScrollContainer>
            <ContentContainer>
            {content.map((item, index) => (
                <ContentSection key={index}>
                <ContentHeader>{item.header}</ContentHeader>
                {item.body.map((text, subIndex) => (
                    <ContentBody key={subIndex}>{text}</ContentBody>
                ))}
                </ContentSection>
            ))}
            </ContentContainer>
        </ScrollContainer>
      </ContentRoot>
    );
};

export default ContentPage;
