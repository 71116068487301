// Scanner.tsx
import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import styled from 'styled-components';
import Button from "../components/basic/Button";

const ScannerContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  user-select: none;
`;

const ButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  z-index: 1000;
`;

type ScannerProps = {
  onScan?: (canvas: HTMLCanvasElement) => void;
  problemId?: string | null;
  imageUploaded?: (imageId: string, problemId?: string | null) => void;
  onCameraStart?: () => void;
  onNewThumbnail?: (string) => void;
  cornerPositions?: any;
  menuOffset?: number;
  analyzeCapturedImage: (imageDataUrl: string) => void;
};

const RENDER_DEBUG = false;
const FRAME_SKIP_COUNT = 60;

const Scanner: React.FC<ScannerProps> = forwardRef((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const resultRef = useRef<HTMLCanvasElement>(null);
  const debugRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [started, setStarted] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [videoDims, setVideoDims] = useState({ width: null, height: null, aspectRatio: null });
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [rectangleStyle, setRectangleStyle] = useState({});

  // useEffect(() => {
  //   console.log("STARTED", started);
  //   console.log("CAMERA READY", cameraReady);
  // }, [started, cameraReady]);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  let frameCounter = 0;

  useEffect(() => {
    if (props.onCameraStart != null && started) {
      props.onCameraStart();
    }
  }, [started, props.onCameraStart]);

  useEffect(() => {
    updateRectangleStyle();
  }, [props.cornerPositions]);

  const resize = () => {
    if (videoRef.current && canvasRef.current && resultRef.current
      && (videoDims.width !== null && videoDims.height !== null && videoDims.aspectRatio !== null )) {
      // console.log('RESIZING NOW!!!');
  
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const windowAspectRatio = windowWidth / windowHeight;
  
      const videoAspectRatio = videoDims.aspectRatio;
      // console.log('OG dimensions:', videoDims.width, 'x', videoDims.height, videoAspectRatio);
      // console.log('video current dimensions:', videoRef.current.width, 'x', videoRef.current.height, videoRef.current.width / videoRef.current.height);
      // console.log('result current dimensions:', resultRef.current.width, 'x', resultRef.current.height, resultRef.current.width / resultRef.current.height);
      
      if (videoAspectRatio < windowAspectRatio) {
        // console.log('Window is wider than video');
        videoRef.current.width = windowWidth;
        videoRef.current.height = windowWidth / videoAspectRatio;
        canvasRef.current.width = windowWidth;
        canvasRef.current.height = windowWidth / videoAspectRatio;
        resultRef.current.width = windowWidth;
        resultRef.current.height = windowWidth / videoAspectRatio;
        if (debugRef.current) {
          // console.log('debug current dimensions:', debugRef.current.width, 'x', debugRef.current.height, debugRef.current.width / debugRef.current.height);
          debugRef.current.width = windowWidth;
          debugRef.current.height = windowWidth / videoAspectRatio;
        }
      } else {
        // console.log('Window is taller than video');
        videoRef.current.height = windowHeight;
        videoRef.current.width = windowHeight * videoAspectRatio;
        canvasRef.current.height = windowHeight;
        canvasRef.current.width = windowHeight * videoAspectRatio;
        resultRef.current.height = windowHeight;
        resultRef.current.width = windowHeight * videoAspectRatio;
        if (debugRef.current) {
          // console.log('debug current dimensions:', debugRef.current.width, 'x', debugRef.current.height, debugRef.current.width / debugRef.current.height);
          debugRef.current.height = windowHeight;
        debugRef.current.width = windowHeight * videoAspectRatio;
        }
      }      

      // Log positions for resultRef
      const resultRect = resultRef.current.getBoundingClientRect();
      // console.log('resultRef positions - left:', resultRect.left, ', top:', resultRect.top, ', right:', resultRect.right, ', bottom:', resultRect.bottom);

      // Log positions for debugRef
      if (debugRef.current) {
        const debugRect = debugRef.current.getBoundingClientRect();
        // console.log('debugRef positions - left:', debugRect.left, ', top:', debugRect.top, ', right:', debugRect.right, ', bottom:', debugRect.bottom);
      }
    }
  };
  
  useEffect(() => {
    // console.log('Scanner component mounted.');
  
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // console.log('Requesting camera access...');
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: { ideal: 3840 }, // high resolution (4K)
        }
      })
      .then((stream) => {
        streamRef.current = stream;
        // console.log('Camera access granted.');
  
        if (videoRef.current) {
          (async () => {
            try {
              videoRef.current.srcObject = stream;
              videoRef.current.muted = true;
              await videoRef.current.play();

              // Set global videoDims
              if (videoRef.current.videoWidth !== 0 && videoRef.current.videoHeight !== 0) {
                videoDims.width = videoRef.current.videoWidth;
                videoDims.height = videoRef.current.videoHeight;
                videoDims.aspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
                // console.log('video dimensions: ', videoDims);
                // console.log('Actual video dimensions:', videoRef.current.videoWidth, 'x', videoRef.current.videoHeight);
              }
              resize();
              // console.log('Video stream started.');
              setStarted(true);
            } catch (err) {
              // console.log('Error playing video:', err);
              setShowStartButton(true);
            }
          })()
        }
      })
      .catch((err) => {
        console.error("Error accessing camera:", err);
        setShowStartButton(true);
      });
  
      if (videoRef.current && started === true) {
        videoRef.current.onloadeddata = () => {
          // Set canvas dimensions when video data is loaded
          if (canvasRef.current && videoDims.width && videoDims.height) {
            canvasRef.current.width = videoDims.width;
            canvasRef.current.height = videoDims.height;
          }
          if (resultRef.current && videoDims.width && videoDims.height) {
            resultRef.current.width = videoDims.width;
            resultRef.current.height = videoDims.height;
          }
          if (debugRef.current && videoDims.width && videoDims.height) {
            debugRef.current.width = videoDims.width;
            debugRef.current.height = videoDims.height;
          }
          resize();
          // console.log('FIRST RESIZE');
          processVideo();
          renderDebugCornerDots();
          setCameraReady(true);
        }
      }
    } else if (!started) {
      // console.log('Camera not started.');
      setShowStartButton(true);
    } else {
      // console.log('MediaDevices API or getUserMedia not supported.');
      setShowStartButton(true);
    }
  
    window.addEventListener('resize', resize);
  
    return () => {
      endVideoStream();
      window.removeEventListener('resize', resize);
    };
  }, [started]);  

  const processVideo = () => {
    try {
      if (videoRef.current && canvasRef.current && resultRef.current) {
        const canvasCtx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D;
        const resultCtx = resultRef.current.getContext("2d") as CanvasRenderingContext2D;
        let debugCtx;
        if (debugRef.current) {
          debugCtx = debugRef.current.getContext("2d") as CanvasRenderingContext2D;
        }

        // Ensure the dimensions are set
        if (canvasRef.current.width === 0 || canvasRef.current.height === 0) {
          canvasRef.current.width = videoDims.width;
          canvasRef.current.height = videoDims.height;
        }
        if (resultRef.current.width === 0 || resultRef.current.height === 0) {
          resultRef.current.width = videoDims.width;
          resultRef.current.height = videoDims.height;
        }
        if (debugRef.current && (debugRef.current.width === 0 || debugRef.current.height === 0)) {
          debugRef.current.width = videoDims.width;
          debugRef.current.height = videoDims.height;
        }
        
        // Enable anti-aliasing
        canvasCtx.imageSmoothingEnabled = true;
        resultCtx.imageSmoothingEnabled = true;

        if (canvasCtx && resultCtx) {
          canvasCtx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
          resultCtx.drawImage(canvasRef.current, 0, 0);
          requestAnimationFrame(processVideo);
        }
      }
    } catch (err) {
      console.warn('Frame error:', err);
    }

    frameCounter++;
    if (frameCounter >= FRAME_SKIP_COUNT) {
      frameCounter = 0; // reset counter
      // if (canvasRef.current && started) {
        // const currentFrame = canvasRef.current.toDataURL(); // Get the current frame as a data URL
        // console.log('Current frame dimensions:', canvasRef.current.width, 'x', canvasRef.current.height);
      // }
    }
  };

  const endVideoStream = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach(track => {
        track.stop();
        streamRef.current?.removeTrack(track);
      });
      streamRef.current = null;
    }
  };  

  const renderCapturedImage = (imageDataUrl: string) => {
    setCapturedImage(imageDataUrl);
    if (debugRef.current) {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const ctx = debugRef.current.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, debugRef.current.width, debugRef.current.height);
          ctx.drawImage(img, 0, 0, debugRef.current.width, debugRef.current.height);
          // console.log('Rendered captured image dimensions:', debugRef.current.width, 'x', debugRef.current.height);
        }
      };
    }
  };

  const captureImage = async (callback: (imageDataUrl: string) => void) => {
    if (!cameraReady) {
      // console.log("Camera not yet ready");
      return;
    }
    if (resultRef.current && videoRef.current) {
      const canvasCtx = resultRef.current.getContext("2d");
      const corners = calculateCornerPositions();
      if (canvasCtx && corners) {
        const [topLeft, topRight, bottomRight, bottomLeft] = corners;
  
        // Calculate the cropping area dimensions
        const width = topRight.x - topLeft.x;
        const height = bottomLeft.y - topLeft.y;
  
        // Create an off-screen canvas for cropping
        const offScreenCanvas = document.createElement("canvas");
        offScreenCanvas.width = width;
        offScreenCanvas.height = height;
        const offScreenCtx = offScreenCanvas.getContext("2d");
  
        if (offScreenCtx) {
          // Draw the cropped area onto the off-screen canvas
          offScreenCtx.drawImage(
            resultRef.current,
            topLeft.x, topLeft.y, width, height,
            0, 0, width, height
          );
  
          offScreenCanvas.toBlob(async (blob) => {
            if (blob) {
              // console.log("Blob created, converting to Data URL.");
              const reader = new FileReader();
              reader.onloadend = () => {
                const imageDataUrl = reader.result.toString();
                // console.log("Image Data URL created, rendering captured image.");
                // renderCapturedImage(imageDataUrl); // Call onNewThumbnail with the base64 string of the image
                props.analyzeCapturedImage(imageDataUrl);
                callback(imageDataUrl); // Invoke the callback with the image data URL
              };
              reader.readAsDataURL(blob);
            } else {
              console.warn("Failed to create Blob from off-screen canvas.");
            }
          }, 'image/png');
        } else {
          console.warn("Failed to get off-screen canvas context.");
        }
      } else {
        console.warn("Failed to get canvas context or corner positions.");
      }
    } else {
      console.warn("Canvas or video reference is not available.");
    }
  };  

  useImperativeHandle(ref, () => ({
    captureAndUpload: (callback: (imageDataUrl: string) => void) => {
      captureImage(callback);
    },
  }));

  const startCamera = () => { setStarted(true); };

  const updateRectangleStyle = () => {
    if (props.cornerPositions) {
      const { topLeft, topRight, bottomRight, bottomLeft } = props.cornerPositions;
      const width = topRight.x - topLeft.x;
      const height = bottomLeft.y - topLeft.y;
      const top = topLeft.y;
      const left = topLeft.x;

      setRectangleStyle({ position: 'fixed', top: `${top}px`, left: `${left}px`, width: `${width}px`, height: `${height}px`,
        border: RENDER_DEBUG ? '1px solid red' : 'none', zIndex: 2, pointerEvents: 'none' });
    }
  };

  const calculateCornerPositions = () => {
    // Get the rectangle position and dimensions from rectangleStyle
    const { top, left, width, height } = rectangleStyle as { top: string, left: string, width: string, height: string };

    // Convert string values to numbers
    const topValue = parseFloat(top);
    const leftValue = parseFloat(left);
    const widthValue = parseFloat(width);
    const heightValue = parseFloat(height);

    // console.log('Rectangle position and dimensions:', { topValue, leftValue, widthValue, heightValue });

    // Calculate the corners
    const cssCorners = [
      { x: leftValue, y: topValue }, // Top left
      { x: leftValue + widthValue, y: topValue }, // Top right
      { x: leftValue + widthValue, y: topValue + heightValue }, // Bottom right
      { x: leftValue, y: topValue + heightValue }, // Bottom left
    ];

    // console.log('Actual screen positions of CSS rectangle corners:', cssCorners);

    // console.log('CORNER POSITIONS:', props.cornerPositions);

    // Get the bounding rectangles of the debug canvas and the container
    const resultRect = resultRef.current.getBoundingClientRect();
    // console.log('Debug canvas dimensions:', { width: resultRect.width, height: resultRect.height });

    // DIMENSION CALCULATIONS
    // console.log('Window dimensions:', window.innerWidth, window.innerHeight);
    // console.log('VIDEO DIMS:', videoDims);

    let scale = Math.max(window.innerWidth / videoDims.width, window.innerHeight / videoDims.height);
    let scaledVideoDims = { width: videoDims.width * scale, height: videoDims.height * scale, aspectRatio: videoDims.aspectRatio };

    // console.log('scaled video dims:', scaledVideoDims);

    // Calculate the aspect ratio of the window and the video
    const windowAspectRatio = window.innerWidth / window.innerHeight;
    const videoAspectRatio = scaledVideoDims.aspectRatio;

    // Determine the scaling factor
    let scaleX = 1; let scaleY = 1;

    if (videoAspectRatio > windowAspectRatio) { // wider
      scaleX = scaledVideoDims.width / window.innerWidth;
      // console.log('scaled x:', scaledVideoDims.width, window.innerWidth, scaleX);
    } else { // taller
      scaleY = scaledVideoDims.height / window.innerHeight;
      // console.log('scaled y:', scaledVideoDims.height, window.innerHeight, scaleY);
    }
    // console.log('SCALE:', scaleX, scaleY, scaleX/scaleY);

    let offsetX = Math.abs(scaledVideoDims.width - window.innerWidth);
    // let offsetX = Math.abs(scaledVideoDims.width - window.innerWidth) + props.menuOffset;
    let offsetY = Math.abs(scaledVideoDims.height - window.innerHeight);

    // console.log('OFFSET:', offsetX, offsetY);    
    
    const padding = 8;
    const corners = [
      { x: leftValue + offsetX/2 - padding, y: topValue + offsetY/2 - padding }, // Top left
      { x: leftValue + widthValue + offsetX/2 + padding, y: topValue + offsetY/2 - padding }, // Top right
      { x: leftValue + widthValue + offsetX/2 + padding, y: topValue + heightValue + offsetY/2 + padding }, // Bottom right
      { x: leftValue + offsetX/2 - padding, y: topValue + heightValue + offsetY/2 + padding }, // Bottom left
    ];
    // console.log('Calculated corners:', corners);
    return corners;
  };

  const renderDebugCornerDots = () => {
    if (debugRef.current && resultRef.current) {
      const debugCtx = debugRef.current.getContext("2d");
      if (debugCtx) {
        debugCtx.clearRect(0, 0, debugRef.current.width, debugRef.current.height);
  
        const corners = calculateCornerPositions();
        corners.forEach((corner, index) => {
          switch (index) {
            case 0:
              debugCtx.fillStyle = 'red'; // Top left
              break;
            case 1:
              debugCtx.fillStyle = 'yellow'; // Top right
              break;
            case 2:
              debugCtx.fillStyle = 'cyan'; // Bottom right
              break;
            case 3:
              debugCtx.fillStyle = 'lime'; // Bottom left
              break;
            default:
              debugCtx.fillStyle = 'white'; // Default color
          }
          debugCtx.beginPath();
          debugCtx.arc(corner.x, corner.y, 5, 0, 2 * Math.PI);
          debugCtx.fill();
        });
      }
    }
  };  
  
  useEffect(() => {
    renderDebugCornerDots(); // Run once on mount
  }, []);

  useEffect(() => {
    renderDebugCornerDots(); // Run on updates to cornerPositions
    // // console.log('scanner corner positions:', props.cornerPositions);
  }, [started, props.cornerPositions, props.menuOffset]);

  return (
    <ScannerContainer id="scanner-container">
      {
        (!started && !!showStartButton) && (
          <ButtonContainer id="button-container">
            <Button color={'white'} text="START CAMERA" icon={'camera'} onClick={startCamera} />
          </ButtonContainer>
        )
      }
      {<>
          <video autoPlay muted playsInline ref={videoRef} style={{ display: 'none' }}></video>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          <canvas ref={resultRef} style={{ position: 'fixed', top: '50%', left: '50%', width: '100vw', height: '100svh', 
            transform: `translate(-50%, -50%) translateX(${props.menuOffset/2}px)`, transition: 'transform 0.3s ease',
            overflow: 'hidden', objectFit: 'none', visibility: !started && !!showStartButton ? 'hidden' : 'visible' }}></canvas>
          <div style={rectangleStyle}></div>
          {RENDER_DEBUG && (
            <canvas ref={debugRef} style={{ position: 'fixed', top: '50%', left: '50%', width: '100vw', height: '100svh', 
              transform: `translate(-50%, -50%) translateX(${props.menuOffset/2}px)`, transition: 'transform 0.3s ease',
              overflow: 'hidden', objectFit: 'none', visibility: !started && !!showStartButton ? 'hidden' : 'visible' }}></canvas>
          )}
        </>}
    </ScannerContainer>
  );  
});

export default Scanner;
