// MenuOpen.tsx
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import Icon from "../common/Icon";

const StyledMenuItem = styled(MenuItem)<{ $color?: 'white' | 'black'; $interactive?: boolean }>`
    font-family: ${Fonts.quicksandMedium.fontFamily};
    font-weight: ${Fonts.quicksandMedium.fontWeight};
    font-size: 16px;
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${props => props.$color === 'black' ? Colors.white : Colors.grey9};
    cursor: ${props => props.$interactive ? 'pointer' : 'default'};
    
    &&:hover {
      background-color: ${props => props.$interactive ? (props.$color === 'black' ? Colors.black : Colors.grey1) : 'transparent'};
    }

    &&:active {
      background-color: ${props => props.$interactive ? (props.$color === 'black' ? Colors.black : Colors.grey1) : 'transparent'};
    }
`;

const StyledPaper = styled(Paper)<{ $color?: 'white' | 'black' }>`
    border-radius: 8px;
    border: 1px solid ${props => props.$color === 'black' ? Colors.grey8 : Colors.grey1};
    box-shadow: 0 2px 4px ${props => props.$color === 'black' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    min-width: 180px;
    background-color: ${props => props.$color === 'black' ? Colors.grey9 : Colors.white};
`;

export interface MenuOption {
  text: string;
  icon?: string;
  onClick?: () => void;
}

export interface MenuOpenProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  options: MenuOption[];
  onClose?: () => void;
  color?: 'white' | 'black';
}

const MenuOpen: FunctionComponent<MenuOpenProps> = ({
  anchorEl,
  open,
  onClose,
  options,
  color = 'white',
}) => {
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorEl &&
      (anchorEl as HTMLElement).contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (onClose) onClose();
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-end' ? 'right top' : 'right bottom',
          }}
        >
          <StyledPaper $color={color}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open}>
                {options.map((option, index) => (
                  <StyledMenuItem
                    key={index}
                    $color={color}
                    $interactive={!!option.onClick}
                    onClick={(event) => {
                      if (option.onClick) {
                        option.onClick();
                        handleClose(event);
                      }
                    }}
                    disableRipple={!option.onClick}
                  >
                    {option.icon && (
                      <Icon icon={option.icon} size={16} color={color === 'black' ? Colors.grey3 : Colors.grey7} />
                    )}
                    {option.text}
                  </StyledMenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      )}
    </Popper>
  );
};

export default MenuOpen;