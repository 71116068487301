// utils/sortProblems.tsx
const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setUTCDate(today.getUTCDate() - 1);
  
    const isToday = date.getUTCFullYear() === today.getUTCFullYear() &&
                    date.getUTCMonth() === today.getUTCMonth() &&
                    date.getUTCDate() === today.getUTCDate();
    const isYesterday = date.getUTCFullYear() === yesterday.getUTCFullYear() &&
                        date.getUTCMonth() === yesterday.getUTCMonth() &&
                        date.getUTCDate() === yesterday.getUTCDate();
  
    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    }
};
  
export const sortProblemsData = (problemsData: any[]) => {
    return problemsData
      .slice() // Make a shallow copy to avoid mutating the original array
      .sort((a, b) => {
        const aDate = a.updatedAt || a.createdAt;
        const bDate = b.updatedAt || b.createdAt;
        const dateComparison = new Date(bDate).getTime() - new Date(aDate).getTime();
        if (dateComparison !== 0) {
          return dateComparison; // If the dates are different, sort by date
        }
        return a.name.localeCompare(b.name); // If the dates are identical, sort by name alphabetically
      })
      .map(problem => ({
        ...problem,
        formattedDate: formatDate(problem.updatedAt || problem.createdAt)
      }));
};
  