// ButtonToggle.tsx
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Icon from "../common/Icon";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import _ from 'lodash';

const sizeStyles = {
    small: {
      padding: "7px 11px 7px 11px",
      fontSize: "18px",
      iconSize: 22,
    },
    medium: {
      padding: "8px 14px 8px 14px",
      fontSize: "22px",
      iconSize: 28,
    },
    large: {
      padding: "10px 20px 10px 20px",
      fontSize: "28px",
      iconSize: 40,
    },
};  

const Text1 = styled.div<{ color: string; fontSize: string; }>`
    font-size: ${({ fontSize }) => fontSize};
    line-height: 1.2em;
    font-weight: ${Fonts.quicksandMedium.fontWeight};
    font-family: ${Fonts.quicksandMedium.fontFamily};
    color: ${({ color }) => color};
    text-align: left;
    text-wrap: nowrap;
`;

const ButtonToggleRoot = styled.button<{
  $backgroundColor?: string, 
  $borderColor?: string, 
  $iconTextColor?: string,
  $padding: string,
}>`
    border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
    padding: ${({ $padding }) => $padding};
    color: ${({ $iconTextColor }) => $iconTextColor};
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 42px;
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;

export type ButtonToggleType = {
    text?: string;
    icon?: string | null;
    onToggle: () => void; 
    isSelected?: boolean; 
    useExternalState?: boolean;
    size?: "small" | "medium" | "large";
};

const ButtonToggle: FunctionComponent<ButtonToggleType> = ({
   text,
   icon = null,
   onToggle,
   isSelected = false,
   useExternalState = false,
   size = "small",
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [internalSelected, setInternalSelected] = useState(false);

    const handleClick = _.debounce(() => {
        if (!useExternalState) {
            setInternalSelected(!internalSelected); // toggle internal state only if not using external state
        }
        onToggle();
    }, 100);
    const finalSelected = useExternalState ? isSelected : internalSelected;

    const onClickRaw = (e) => {
        e.preventDefault();
        handleClick();
    }

    let backgroundColor = Colors.white;
    let borderColor = Colors.grayLight;
    let iconTextColor = Colors.grayDark;

    if (isHovered) { 
        if (finalSelected) {
            backgroundColor = Colors.grayLight;
            borderColor = Colors.grey5;
            iconTextColor = Colors.grayDark;
        } else { 
            backgroundColor = Colors.grayVeryPale;
            borderColor = Colors.grey3;
            iconTextColor = Colors.grayDark;
        }
    } else if (finalSelected) {
        backgroundColor = Colors.grey2;
        borderColor = Colors.grey6;
        iconTextColor = Colors.grayDark;
    }

    // ORIGINAL BLUE VERSION
    // let backgroundColor = Colors.white;
    // let borderColor = Colors.grayLight;
    // let iconTextColor = Colors.grayDark;

    // if (isHovered) { 
    //     if (finalSelected) {
    //         backgroundColor = Colors.blueDark;
    //         borderColor = Colors.blueDark;
    //         iconTextColor = Colors.white;
    //     } else { 
    //         backgroundColor = Colors.paleBright;
    //         borderColor = Colors.blueDark;
    //         iconTextColor = Colors.grayDark;
    //     }
    // } else if (finalSelected) {
    //     backgroundColor = Colors.blue;
    //     borderColor = Colors.blueDark;
    //     iconTextColor = Colors.white;
    // }

    const { padding, fontSize, iconSize } = sizeStyles[size];

    return (
        <ButtonToggleRoot
            onClick={onClickRaw}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            $iconTextColor={iconTextColor}
            $padding={padding}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {icon && <Icon onClick={handleClick} icon={icon} color={iconTextColor} size={iconSize} />}
            {text && <Text1 color={iconTextColor} fontSize={fontSize}>{text}</Text1>}
        </ButtonToggleRoot>
    );
};

export default ButtonToggle;
