// LineGraph.tsx
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import { useHover } from '../HoverContext';
import { wrapText } from '../../utils/beautifyText';
import Dragger from './interactives/Dragger';
import { getMaxDecimalPlaces } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

interface AxisConfig {
  min?: number;
  max?: number;
  tick?: number;
  label?: string;
}

interface Dataset {
  name?: string;
  points?: Point[];
  equation?: string;
  color: string;
  style?: 'solid' | 'dash';
  fillAxes?: boolean;
}

interface Point {
  x: number;
  y: number;
  draggable?: 'all' | 'valid' | null;
  invisible?: boolean;
  emphasize?: boolean;
  tutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;
  hoverLabel?: boolean;
}

export interface LineGraphProps {
  datasets?: Dataset[];
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  dragTick?: number;
  actualScale?: number;
  onStateChange?: (state: LineGraphProps, skipLogic?: string[]) => void;
}

const lineGraphPropsTypeDefinition: LineGraphProps = {
  datasets: [{ name: '', points: [{ x: 0, y: 0 }], color: '', style: 'solid' }],
  xAxis: { min: 0, max: 0, tick: 0, label: '' },
  yAxis: { min: 0, max: 0, tick: 0, label: '' },
  dragTick: 0,
  actualScale: 1,
  onStateChange: () => {}, 
};

const LineGraph: React.FC<LineGraphProps> = (props) => {
  props = convertAllTypes(props, lineGraphPropsTypeDefinition);
  const { datasets, xAxis, yAxis, dragTick, onStateChange } = props;
  const MIN_SCALE = 0.37;
  const SCALE_BREAKPOINT = 0.6;
  let { actualScale = 1 } = props;
  if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useHover();

  const consistentAxes = xAxis?.min === yAxis?.min && xAxis?.max === yAxis?.max && xAxis?.tick === yAxis?.tick;
  const width = 700;
  const height = consistentAxes ? 700 : 400;
  const margin = consistentAxes ? { top: 20, right: 20, bottom: 20, left: 20 } 
                                : { top: 30, right: 20, bottom: 65, left: 80 };
  const axisTickFontSize = (consistentAxes ? 18 : 16) / actualScale;
  const axisLabelFontSize = consistentAxes ? 18 : 16;
  const dataFontSize = (consistentAxes ? 24 : 18) / actualScale;

  const xScale = useMemo(() => d3.scaleLinear().range([margin.left, width - margin.right]), [margin.left, margin.right, width]);
  const yScale = useMemo(() => d3.scaleLinear().range([height - margin.bottom, margin.top]), [margin.top, margin.bottom, height]);

  const positionLabelsMemo = useCallback((textSelection: d3.Selection<SVGTextElement, unknown, null, undefined>) => {
    textSelection.each(function() {
      const text = d3.select(this);
      const bbox = (this as SVGTextElement).getBBox();
      const x = parseFloat(text.attr('x'));
      const y = parseFloat(text.attr('y'));

      if (x + bbox.width / 2 > width) {
        text.attr('x', width - bbox.width / 2);
      } else if (x - bbox.width / 2 < 0) {
        text.attr('x', bbox.width / 2);
      }
      if (y - bbox.height / 2 < 0) {
        text.attr('y', margin.top + bbox.height);
      } else if (y + bbox.height / 2 > height) {
        text.attr('y', height - margin.bottom);
      }
    });
  }, [width, height, margin]);

  const updateState = (datasetIndex: number, pointIndex: number, newX: number, newY: number, skipLogic?: string[]) => {
    if (!datasets) return;
    
    const newDatasets = [...datasets];
    if (skipLogic) setHasInteracted(true);

    // Check if the point actually changed to avoid unnecessary updates
    const currentPoint = newDatasets[datasetIndex]?.points[pointIndex];
    if (!currentPoint || (currentPoint.x === newX && currentPoint.y === newY)) {
      return; // Skip update if values haven't changed
    }

    if (newDatasets[datasetIndex]) {
      // Create a new point object only if values have changed
      const updatedPoint = { ...currentPoint, x: newX, y: newY };
      
      // Only update if the values are actually different
      if (currentPoint.x !== updatedPoint.x || currentPoint.y !== updatedPoint.y) {
        newDatasets[datasetIndex] = {
          ...newDatasets[datasetIndex],
          points: newDatasets[datasetIndex].points.map((point, i) =>
            i === pointIndex ? updatedPoint : point
          ),
        };

        if (onStateChange) {
          onStateChange({
            ...props,
            datasets: newDatasets,
          }, skipLogic);
        }
      }
    }
  };

  const handleDrag = useCallback(( 
    datasetIndex: number, 
    pointIndex: number, 
    newX: number, 
    newY: number
  ) => {
    const dataset = datasets ? datasets[datasetIndex] : null;
    if (!dataset) return;

    const skipLogicX = `datasets[${datasetIndex}].points[${pointIndex}].x`;
    const skipLogicY = `datasets[${datasetIndex}].points[${pointIndex}].y`;
    const skipLogic = [skipLogicX, skipLogicY];
    // console.log(skipLogic, skipLogicX, skipLogicY);

    // console.log('Updating position directly to:', { x: newX, y: newY });
    updateState(datasetIndex, pointIndex, newX, newY, skipLogic);
  }, [datasets, updateState]);

  const renderAxes = useCallback((svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, x: d3.ScaleLinear<number, number>, y: d3.ScaleLinear<number, number>) => {
    // console.log('xAxis.min', xAxis.min, 'xAxis.max', xAxis.max, 'xAxis.tick', xAxis.tick);
    // console.log('yAxis.min', yAxis.min, 'yAxis.max', yAxis.max, 'yAxis.tick', yAxis.tick);
    
    const yIncludesZero = yAxis.max > 0 && yAxis.min < 0 ? true : false;
    const xIncludesZero = xAxis.max > 0 && xAxis.min < 0 ? true : false;
    const xTranslate = yIncludesZero === true ? `translate(0,${y(0)})` : `translate(0,${y(yAxis.min)})`;
    const yTranslate = xIncludesZero === true ? `translate(${x(0)},0)` : `translate(${x(xAxis.min)},0)`;
    
    const xAxisCall = d3.axisBottom(x)
      .ticks(xAxis?.tick ? (xAxis.max - xAxis.min) / xAxis.tick : undefined)
      .tickValues(
        xAxis?.tick 
          ? d3.range(xAxis.min, xAxis.max + xAxis.tick, xAxis.tick).filter(d => !(yIncludesZero && d === 0))
          : x.ticks().filter(d => !(yIncludesZero && d === 0))
      );

    const yAxisCall = d3.axisLeft(y)
      .ticks(yAxis?.tick ? (yAxis.max - yAxis.min) / yAxis.tick : undefined)
      .tickValues(
        yAxis?.tick 
          ? d3.range(yAxis.min, yAxis.max + yAxis.tick, yAxis.tick).filter(d => !(xIncludesZero && d === 0))
          : y.ticks().filter(d => !(xIncludesZero && d === 0))
      );
    
    // x-axis
    svg.append('g')
      // .attr('transform', `translate(0,${y(0)})`)
      .attr('transform', xTranslate)
      .call(xAxisCall)
      .selectAll('.tick text')
      .style('font-family', Fonts.quicksandLight.fontFamily)
      .style('font-weight', Fonts.quicksandLight.fontWeight)
      .style('font-size', `${axisTickFontSize}px`);

    // y-axis
    svg.append('g')
      // .attr('transform', `translate(${x(0)},0)`)
      .attr('transform', yTranslate)
      .call(yAxisCall)
      .selectAll('.tick text')
      .style('font-family', Fonts.quicksandLight.fontFamily)
      .style('font-weight', Fonts.quicksandLight.fontWeight)
      .style('font-size', `${axisTickFontSize}px`);

    svg.selectAll('.domain').attr('stroke-width', 2); 
    svg.selectAll('.tick line').attr('stroke-width', 1); 

    if (actualScale > SCALE_BREAKPOINT) {
      // x-axis label
      if (xAxis?.label) {
        svg.append('text')
          .attr('x', width / 2)
          .attr('y', height + margin.top - margin.bottom*0.6)
          .attr('text-anchor', 'middle')
          .attr('fill', 'black')
          .style('font-family', Fonts.quicksandLight.fontFamily)
          .style('font-weight', Fonts.quicksandLight.fontWeight)
          .style('font-size', `${axisLabelFontSize}px`)
          .text(xAxis.label);
      }

      // y-axis label
      if (yAxis?.label) {
        svg.append('text')
          .attr('x', -height / 2)
          .attr('y', margin.left / 3)
          .attr('text-anchor', 'middle')
          .attr('fill', 'black')
          .attr('transform', 'rotate(-90)')
          .style('font-family', Fonts.quicksandLight.fontFamily)
          .style('font-weight', Fonts.quicksandLight.fontWeight)
          .style('font-size', `${axisLabelFontSize}px`)
          .text(yAxis.label);
      }
    }
  }, [xAxis, yAxis, width, height, margin]);

  const renderData = useCallback((svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, x: d3.ScaleLinear<number, number>, y: d3.ScaleLinear<number, number>) => {
    if (datasets && datasets.some(dataset => dataset.points && dataset.points.length > 0)) {
      const labelsSelection = svg.selectAll<SVGTextElement, unknown>('.line-label');

      datasets.forEach((dataset, datasetIndex) => {
        const line = d3.line<{ x: number; y: number }>()
          .x(d => x(d.x))
          .y(d => y(d.y));

        const strokeColor = dataset.color;
        const lineStyle = dataset.style === 'dash' && consistentAxes ? `${14/actualScale}, ${14/actualScale}` : dataset.style === 'dash' ? `${10/actualScale}, ${10/actualScale}` : 'none';
        const strokeWidth = dataset.style === 'dash' ? 1 / actualScale : 4 / actualScale; // width 1 for dashed line, 4 for solid line

        let lineToDraw = dataset.points;
        if (dataset.fillAxes === true) {
          // Find the first and last visible points
          const visiblePoints = dataset.points.filter(point => !point.invisible);
          const firstPoint = visiblePoints[0];
          const lastPoint = visiblePoints[visiblePoints.length - 1];

          // Calculate the slope and y-intercept
          const slope = (lastPoint.y - firstPoint.y) / (lastPoint.x - firstPoint.x);
          const yIntercept = firstPoint.y - slope * firstPoint.x;

          // Function to calculate y for a given x
          const calculateY = (x: number) => slope * x + yIntercept;

          // Add points at the edges of the axes
          const xMin = x.domain()[0];
          const xMax = x.domain()[1];
          const yMin = y.domain()[0];
          const yMax = y.domain()[1];

          lineToDraw = [...dataset.points]; // Start with original points

          // Handle vertical line case
          if (firstPoint.x === lastPoint.x) {
            lineToDraw = [
              { x: firstPoint.x, y: yMin },
              { x: firstPoint.x, y: yMax }
            ];
          // Add point at left edge if needed
          } else {
            if (firstPoint.x > xMin) {
              const leftY = calculateY(xMin);
              if (leftY >= yMin && leftY <= yMax) {
                lineToDraw.unshift({ x: xMin, y: leftY });
              }
            }

            // Add point at right edge if needed
            if (lastPoint.x < xMax) {
              const rightY = calculateY(xMax);
              if (rightY >= yMin && rightY <= yMax) {
                lineToDraw.push({ x: xMax, y: rightY });
              }
            }

            // Add points at top and bottom edges if needed
            const xAtYMin = (yMin - yIntercept) / slope;
            if (xAtYMin >= xMin && xAtYMin <= xMax && (xAtYMin < firstPoint.x || xAtYMin > lastPoint.x)) {
              lineToDraw.push({ x: xAtYMin, y: yMin });
            }

            const xAtYMax = (yMax - yIntercept) / slope;
            if (xAtYMax >= xMin && xAtYMax <= xMax && (xAtYMax < firstPoint.x || xAtYMax > lastPoint.x)) {
              lineToDraw.push({ x: xAtYMax, y: yMax });
            }

            // Sort the points by x-coordinate
            lineToDraw.sort((a, b) => a.x - b.x);

            // Filter out any points outside the visible area
            lineToDraw = lineToDraw.filter(point => 
              point.x >= xMin && point.x <= xMax && point.y >= yMin && point.y <= yMax
            );
          }
        }

        const path = svg.append('path')
          .datum(lineToDraw.filter(point => !point.invisible))
          .attr('fill', 'none')
          .attr('stroke', strokeColor)
          .attr('stroke-width', strokeWidth)
          .attr('stroke-linecap', 'round')
          .attr('stroke-dasharray', lineStyle)
          .attr('d', line);

        path
          .on('click', () => {
            handleMouseEnter(strokeColor);
          });

        dataset.points.forEach((point, pointIndex) => {
          if (point.invisible) return; 

          if (point.emphasize) {
            svg.append('circle')
              .attr('cx', x(point.x))
              .attr('cy', y(point.y))
              .attr('r', 6)
              .attr('fill', strokeColor)
              .attr('stroke', 'none');
          }
        });

        // line label
        const visiblePoints = dataset.points.filter(point => !point.invisible);
        const pointsToRender = visiblePoints.length > 0 ? visiblePoints : dataset.points;
        const lastPoint = pointsToRender.slice(-1)[0];
        if (dataset.name) {
          // Add background rectangle first
          const labelGroup = labelsSelection
            .data([dataset])
            .enter()
            .append('g');

          // Add white background
          labelGroup
            .append('rect')
            .attr('fill', 'white')
            .attr('opacity', 0.2)
            .attr('rx', 10) // Rounded corners
            .attr('ry', 10);

          // Add text
          const maxLabelWidth = 120 / actualScale;
          const text = labelGroup
            .append('text')
            .attr('x', xScale(lastPoint.x))
            .attr('y', yScale(lastPoint.y) - 22/actualScale)
            .attr('text-anchor', 'middle')
            .attr('fill', strokeColor)
            .attr('class', 'line-label')
            .style('font-family', Fonts.quicksandMedium.fontFamily)
            .style('font-weight', Fonts.quicksandMedium.fontWeight)
            .style('font-size', `${dataFontSize}px`)
            .style('line-height', '1em')
            .text(dataset.name)
            .call(wrapText, maxLabelWidth)
            .on('click', () => {
              handleMouseEnter(strokeColor);
            });

          // Position and size background rectangle based on text bounds
          text.each(function() {
            const bbox = this.getBBox();
            const padding = { x: 10, y: 4 };
            labelGroup.select('rect')
              .attr('x', bbox.x - padding.x)
              .attr('y', bbox.y - padding.y)
              .attr('width', bbox.width + (padding.x * 2))
              .attr('height', bbox.height + (padding.y * 2));
          });
        }
      });

      // Position all labels after they are created
      labelsSelection.call(positionLabelsMemo);
    }
  }, [datasets, handleMouseEnter, handleDrag, positionLabelsMemo]);

  const renderDraggers = ( svgRef: React.RefObject<SVGSVGElement>, datasets: Dataset[], x: d3.ScaleLinear<number, number>, y: d3.ScaleLinear<number, number>, dragTick?: number, ) => {
    datasets.forEach((dataset, datasetIndex) => {
      dataset.points?.forEach((point, pointIndex) => {
        if (point.invisible) return; // Skip invisible points
  
        if (point.draggable) {
          Dragger({
            svgRef,
            id: `dragger-${datasetIndex}-${pointIndex}`,
            tutorial: hasInteracted ? null : point.tutorial!,
            color: dataset.color,
            direction: point.draggable,
            x: x(point.x),
            y: y(point.y),
            minPos: { x: x(x.domain()[0]), y: y(y.domain()[1]) },
            maxPos: { x: x(x.domain()[1]), y: y(y.domain()[0]) },
            value: { x: point.x, y: point.y },
            minVal: { x: x.domain()[0], y: y.domain()[0] },
            maxVal: { x: x.domain()[1], y: y.domain()[1] },
            tick: dragTick ? dragTick : null,
            decimalPrecision: dragTick ? getMaxDecimalPlaces([dragTick]) : null,
            showLabel: point.hoverLabel === false ? null : 'bottom',
            lineGraphDataset: dataset,
            actualScale,
            handleDrag: (newX, newY) => {
              if (handleDrag) handleDrag(datasetIndex, pointIndex, newX, newY);
            },
          });
        }
      });
    });
  };

  const render = useCallback(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    svg.attr('viewBox', `0 0 ${width} ${height}`)
       .attr('preserveAspectRatio', 'xMidYMid meet')
       .style('width', '100%')
       .style('height', '100%');
    svg.selectAll('*').remove();

    const x = xScale;
    const y = yScale;

    if (datasets && datasets.length > 0) {
      const allPoints = datasets.flatMap(dataset => dataset.points);
      const xMin = xAxis?.min ?? d3.min(allPoints, d => d.x) - 1;
      const xMax = xAxis?.max ?? d3.max(allPoints, d => d.x) + 1;
      const yMin = yAxis?.min ?? d3.min(allPoints, d => d.y) - 1;
      const yMax = yAxis?.max ?? d3.max(allPoints, d => d.y) + 1;

      x.domain([xMin, xMax]);
      y.domain([yMin, yMax]);
      // console.log('x.domain()', x.domain(), 'y.domain()', y.domain());
    }

    renderAxes(svg, x, y);
    renderData(svg, x, y);
    if (datasets && datasets.length > 0) {
      renderDraggers(svgRef, datasets, x, y, dragTick);
    }
  }, [datasets, xAxis, yAxis, dragTick, width, height, margin, renderAxes, renderData, xScale, yScale]);

  useEffect(() => {
    render();
  }, [render]);

  return <svg ref={svgRef}></svg>;
};

export default LineGraph;


// ---------------------------------------

export const findPointOnLine = (dataset: any, targetX: number, targetY: number, includeOutOfBounds: boolean): { x: number; y: number } | null => {
  const points = dataset.points;

  // console.log('Dataset points:', points);
  // console.log('Target X:', targetX, 'Target Y:', targetY);

  if (points.length < 2) {
    console.warn('Insufficient points to determine a line.');
    return null;
  }

  if (includeOutOfBounds === true) {
    // Check if targetX is out of bounds
    if (targetX !== undefined) {
      const minX = Math.min(...points.map(p => p.x));
      const maxX = Math.max(...points.map(p => p.x));

      if (targetX < minX) {
        // console.log(`targetX (${targetX}) is less than minX (${minX}). Out of bounds. Setting targetX to minX.`);
        targetX = minX;
      } else if (targetX > maxX) {
        // console.log(`targetX (${targetX}) is greater than maxX (${maxX}). Out of bounds. Setting targetX to maxX.`);
        targetX = maxX;
      }
    }

    // Check if targetY is out of bounds
    if (targetY !== undefined) {
      const minY = Math.min(...points.map(p => p.y));
      const maxY = Math.max(...points.map(p => p.y));

      if (targetY < minY) {
        // console.log(`targetY (${targetY}) is less than minY (${minY}). Out of bounds. Setting targetY to minY.`);
        targetY = minY;
      } else if (targetY > maxY) {
        // console.log(`targetY (${targetY}) is greater than maxY (${maxY}). Out of bounds. Setting targetY to maxY.`);
        targetY = maxY;
      }
    }
  }

  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];
    // console.log(`Checking segment between Point ${i}:`, p1, 'and Point', i + 1, p2);

    // Handle vertical line (same x, different y)
    if (p1.x === p2.x && targetX === p1.x) {
      const minY = Math.min(p1.y, p2.y);
      const maxY = Math.max(p1.y, p2.y);
      // console.log('Vertical line detected. Min Y:', minY, 'Max Y:', maxY);
      if (targetY !== undefined && targetY >= minY && targetY <= maxY) {
        // console.log('Target is within vertical line bounds:', { x: targetX, y: targetY });
        return { x: targetX, y: targetY };
      }
    }

    // Handle horizontal line (same y, different x)
    if (p1.y === p2.y && targetY === p1.y) {
      const minX = Math.min(p1.x, p2.x);
      const maxX = Math.max(p1.x, p2.x);
      // console.log('Horizontal line detected. Min X:', minX, 'Max X:', maxX);
      if (targetX !== undefined && targetX >= minX && targetX <= maxX) {
        // console.log('Target is within horizontal line bounds:', { x: targetX, y: targetY });
        return { x: targetX, y: targetY };
      }
    }

    // General case: Find point by X coordinate (linear interpolation)
    if (targetX !== undefined && p1.x <= targetX && p2.x >= targetX) {
      const t = (targetX - p1.x) / (p2.x - p1.x);
      const interpolatedY = p1.y + t * (p2.y - p1.y);
      // console.log('Interpolating by X. t:', t, 'Interpolated Y:', interpolatedY);
      if (!isNaN(interpolatedY)) {
        // console.log('Valid point found:', { x: targetX, y: interpolatedY });
        return { x: targetX, y: interpolatedY };
      }
    }

    // General case: Find point by Y coordinate (linear interpolation)
    if (targetY !== undefined && p1.y <= targetY && p2.y >= targetY) {
      const t = (targetY - p1.y) / (p2.y - p1.y);
      const interpolatedX = p1.x + t * (p2.x - p1.x);
      // console.log('Interpolating by Y. t:', t, 'Interpolated X:', interpolatedX);
      if (!isNaN(interpolatedX)) {
        // console.log('Valid point found:', { x: interpolatedX, y: targetY });
        return { x: interpolatedX, y: targetY };
      }
    }
  }

  console.warn('Line Graph: No valid point found after checking all segments.');
  return null; // No matching point found
};