import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import Icon from "../common/Icon";
import LinearProgress from '@mui/material/LinearProgress';

const StyledAlert = styled.div<{ color: "white" | "black" }>`
  background-color: ${({ color }) => 
    color === "white" ? Colors.white : Colors.grey9};
  color: ${({ color }) => 
    color === "white" ? Colors.grey8 : Colors.white};
  border: 1px solid ${({ color }) => 
    color === "white" ? Colors.grey1 : Colors.grey8};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-size: 16px;
  line-height: 1.4em;
  border-radius: 8px;
  padding: 14px 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
`;

export interface AlertProps {
  color?: "white" | "black";
  icon?: string;
  titleText?: string;
  bodyText?: string;
  seconds?: number;
}

const Toast: FunctionComponent<AlertProps> = ({
  color = "white",
  icon,
  titleText,
  bodyText,
  seconds,
}) => {
  const textColor = color === "white" ? Colors.grey8 : Colors.white;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (seconds) {
      const interval = 100; // Update every 100ms
      const totalIntervals = (seconds * 1000) / interval;
      let currentInterval = 0;

      const timer = setInterval(() => {
        currentInterval += 1;
        setProgress((currentInterval / totalIntervals) * 100);
        if (currentInterval >= totalIntervals) {
          clearInterval(timer);
        }
      }, interval);

      return () => clearInterval(timer);
    }
  }, [seconds]);

  const progressBarBackgroundColor = color === "white" ? Colors.grey2 : Colors.grey7;
  const progressBarFillColor = color === "white" ? Colors.grey8 : Colors.white;

  return (
    <StyledAlert color={color}>
      {icon && <Icon icon={icon} color={textColor} size={18} />}
      <div className="alert-message">
        {titleText && <strong>{titleText}</strong>}
        {titleText && bodyText && <br />}
        {bodyText}
      </div>
      {seconds && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: progressBarBackgroundColor,
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressBarFillColor,
            },
          }}
        />
      )}
    </StyledAlert>
  );
};

export default Toast;