// Button2Line.tsx
import { FunctionComponent, useState, MouseEvent } from "react";
import styled, { css } from "styled-components";
import Icon from "../common/Icon";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import _ from 'lodash';

const Button2LineRoot = styled.button<{ $iconTextColor: string }>`
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }

    .icon {
        color: ${({ $iconTextColor }) => $iconTextColor};
    }

    .text {
        font-size: 14px;
        font-family: ${Fonts.quicksandSemiLight.fontFamily};
        font-weight: ${Fonts.quicksandSemiLight.fontWeight};
        color: ${({ $iconTextColor }) => $iconTextColor};
        text-transform: uppercase;
        max-width: 100px;
    }
`;

export type Button2LineType = {
    text: string;
    icon: string;
    onClick: () => void;
    disabled?: boolean;
    color?: string;
};

const Button2Line: FunctionComponent<Button2LineType> = ({
    text,
    icon,
    onClick,
    disabled = false,
    color = 'black',
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleClick = _.debounce(() => {
        onClick();
    }, 500);

    const onClickRaw = (e: MouseEvent) => {
        e.preventDefault();
        if (!disabled) {
            handleClick();
        }
    };

    const setIconTextColor = (color: string, state: string) => {
        const colorOptions = {
            black: {
                default: Colors.grey9,
                hover: Colors.grey8,
                active: Colors.black,
                disabled: Colors.grey4,
            }
        };

        return colorOptions[color]?.[state] || Colors.black;
    };

    const state = disabled ? 'disabled' : isActive ? 'active' : isHovered ? 'hover' : 'default';
    const iconTextColor = setIconTextColor(color, state);

    return (
        <Button2LineRoot
            onClick={onClickRaw}
            disabled={disabled}
            $iconTextColor={iconTextColor}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
            onMouseDown={() => !disabled && setIsActive(true)}
            onMouseUp={() => !disabled && setIsActive(false)}
        >
            <Icon icon={icon} color={iconTextColor} size={14} />
            <div className="text">{text}</div>
        </Button2LineRoot>
    );
};

export default Button2Line;
