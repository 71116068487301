// Shape.tsx
import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { useHover } from '../HoverContext';
import Colors from '../common/Colors';

interface Circle {
  r: number;
  center: Point;
}

interface Point {
  x: number;
  y: number;
  label?: string;
}

export interface ShapeData {
  point?: Point[];
  label?: string;
  color?: string;
  circle?: Circle;
}

interface Dimensions {
  width: number;
  height: number;
}

export interface ShapeProps {
  dimensions?: Dimensions;
  shape?: ShapeData[] | ShapeData[][];
}

const Shape: React.FC<ShapeProps> = ({ dimensions, shape }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { handleMouseEnter, handleMouseLeave } = useHover();

  useEffect(() => {
    if (!svgRef.current) return;
  
    const svg = d3.select(svgRef.current);
    const defaultWidth = 700;
    const defaultHeight = 400;
    const margin = { top: 10, right: 10, bottom: 10, left: 10 };
    const width = defaultWidth - margin.left - margin.right;
    const height = defaultHeight - margin.top - margin.bottom;
  
    let xScale: d3.ScaleLinear<number, number>;
    let yScale: d3.ScaleLinear<number, number>;
  
    // Clear previous contents
    svg.selectAll('*').remove();
  
    // Set default styles
    const fillOpacity = 0.2;
    const strokeWidth = 3;
    const pointRadius = 6;
  
    let minX = 0, minY = 0, maxX = 0, maxY = 0;
  
    const updateBounds = (point: Point) => {
      if (point.x < minX) minX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.x > maxX) maxX = point.x;
      if (point.y > maxY) maxY = point.y;
    };
  
    if (Array.isArray(shape)) {
      if (Array.isArray(shape[0])) {
        (shape as ShapeData[][]).forEach(shapeArray => {
          shapeArray.forEach(shapeData => {
            if (shapeData.point) {
              shapeData.point.forEach(updateBounds);
            }
            if (shapeData.circle) {
              const { center, r } = shapeData.circle;
              updateBounds({ x: center.x - r, y: center.y - r });
              updateBounds({ x: center.x + r, y: center.y + r });
            }
          });
        });
      } else {
        (shape as ShapeData[]).forEach(shapeData => {
          if (shapeData.point) {
            shapeData.point.forEach(updateBounds);
          }
          if (shapeData.circle) {
            const { center, r } = shapeData.circle;
            updateBounds({ x: center.x - r, y: center.y - r });
            updateBounds({ x: center.x + r, y: center.y + r });
          }
        });
      }
    }
  
    // console.log('shape bounds', minX, maxX, minY, maxY);
  
    let dimWidth = maxX - minX;
    let dimHeight = maxY - minY;
  
    // console.log('shape dims', dimWidth, dimHeight);
  
    const aspectRatio = dimWidth / dimHeight;
    const scaleFactor = Math.min(width / dimWidth, height / dimHeight);
    const scaledWidth = dimWidth * scaleFactor;
    const scaledHeight = dimHeight * scaleFactor;
  
    // console.log('shape aspectRatio', aspectRatio);
    // console.log('shape scaleFactor', scaleFactor);
    // console.log('shape scale width height', scaledWidth, scaledHeight);
  
    const xOffset = (defaultWidth - scaledWidth) / 2;
    const yOffset = (defaultHeight - scaledHeight) / 2;
  
    const xDomain = [minX, maxX];
    const yDomain = [minY, maxY];
    const xRange = [xOffset, xOffset + scaledWidth];
    const yRange = [yOffset + scaledHeight, yOffset];
  
    xScale = d3.scaleLinear().domain(xDomain).range(xRange);
    yScale = d3.scaleLinear().domain(yDomain).range(yRange);
  
    // console.log('shape xScale domain:', xScale.domain(), 'range:', xScale.range());
    // console.log('shape yScale domain:', yScale.domain(), 'range:', yScale.range());
  
    svg.attr('viewBox', `0 0 ${defaultWidth} ${defaultHeight}`)
       .attr('preserveAspectRatio', 'xMidYMid meet')
       .style('width', '100%')
       .style('height', '100%');
  
    const drawShape = (shapeData: ShapeData) => {
      const { point, color, label, circle } = shapeData;
  
      if (circle) {
        // Draw circle
        svg
          .append('circle')
          .attr('cx', xScale(circle.center.x))
          .attr('cy', yScale(circle.center.y))
          .attr('r', circle.r * scaleFactor)
          .attr('fill', color)
          .attr('fill-opacity', fillOpacity)
          .attr('stroke', color)
          .attr('stroke-width', strokeWidth)
          .on('click', () => {
            handleMouseEnter(color);
          });
  
        // Draw dot at circle center
        svg
          .append('circle')
          .attr('cx', xScale(circle.center.x))
          .attr('cy', yScale(circle.center.y))
          .attr('r', pointRadius)
          .attr('fill', color)
          .on('click', () => {
            handleMouseEnter(color);
          });
  
        // Draw circle label
        if (label) {
          svg
            .append('text')
            .attr('x', xScale(circle.center.x))
            .attr('y', yScale(circle.center.y - 2))
            .attr('dy', '0.3em')
            .attr('text-anchor', 'middle')
            .attr('fill', color)
            .text(label);
        }
      } else if (point) {
        // Draw polygon
        svg
          .append('polygon')
          .attr('points', point.map((p) => `${xScale(p.x)},${yScale(p.y)}`).join(' '))
          .attr('fill', color)
          .attr('fill-opacity', fillOpacity)
          .attr('stroke', color)
          .attr('stroke-width', strokeWidth)
          .on('click', () => {
            handleMouseEnter(color);
          });
  
        // Draw points
        svg
          .selectAll(null) // use null to avoid selecting existing elements
          .data(point)
          .enter()
          .append('circle')
          .attr('cx', (d) => xScale(d.x))
          .attr('cy', (d) => yScale(d.y))
          .attr('r', pointRadius)
          .attr('fill', color)
          .on('click', () => {
            handleMouseEnter(color);
          });
  
        // Draw shape label
        if (label) {
          const centerX = d3.mean(point, (p) => xScale(p.x));
          const centerY = d3.mean(point, (p) => yScale(p.y));
  
          svg
            .append('text')
            .attr('x', centerX)
            .attr('y', centerY)
            .attr('dy', '0.3em')
            .attr('text-anchor', 'middle')
            .attr('fill', color)
            .text(label);
        }
      }
    };
  
    if (Array.isArray(shape)) {
      if (Array.isArray(shape[0])) {
        (shape as ShapeData[][]).forEach((shapeArray) => {
          shapeArray.forEach(drawShape);
        });
      } else {
        (shape as ShapeData[]).forEach(drawShape);
      }
    }
  }, [dimensions, shape, handleMouseEnter, handleMouseLeave]);  

  return <svg ref={svgRef}></svg>;
};

export default Shape;
