// PictureGraph.tsx
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../HoverContext';
import Dragger from './interactives/Dragger';
import { convertAllTypes } from '../../utils/convertTypes';
import { wrapText } from '../../utils/beautifyText';

interface AxisConfig {
  min?: number;
  max?: number;
  tick?: number;
  label?: string;
}

interface DataPoint {
  index: number;
  name: string;
  value: number;
  color: string;
  image?: string;
  draggable?: boolean;
  valuePos?: number[];
}

export interface PictureGraphProps {
  data: DataPoint[];
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  scale?: number;
  dragTick?: number;
  actualScale?: number;
  onStateChange?: (state: PictureGraphProps, skipLogic?: string) => void;
}

const pictureGraphPropsTypeDefinition: PictureGraphProps = {
  data: [{ index: 0, name: '', value: 0, color: '', image: '' }],
  xAxis: { min: 0, max: 0, tick: 0, label: '' },
  yAxis: { min: 0, max: 0, tick: 0, label: '' },
  scale: 1,
  dragTick: 1,
  actualScale: 1,
  onStateChange: () => {},
};

const PictureGraph: React.FC<PictureGraphProps> = (props) => {
  props = convertAllTypes(props, pictureGraphPropsTypeDefinition);
  let { data, xAxis, yAxis, scale = 1, dragTick = scale, actualScale = 1, onStateChange } = props;
  const MIN_SCALE = 0.37;
  const SCALE_BREAKPOINT = 0.7;
  if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const [isHovering, setIsHovering] = useState<string | null>(null);

  const prepData = useCallback((data: DataPoint[], scale: number) => {
    // console.log(data);
    const newData = data.map(d => ({
      ...d,
      valuePos: d.value !== undefined 
        ? d3.range(d.value === 0 ? 1 : Math.ceil(d.value / scale)).map(j => (j + 0.55) * scale) 
        : []
    }));
    // console.log(newData);
    return newData;
  }, []);   
  data = useMemo(() => prepData(data, scale), [data, scale, prepData]);

  const updateState = useCallback((newData: DataPoint[], skipLogic?: string) => {
    data = newData;
    if (onStateChange) {
      onStateChange({ ...props, data: newData, xAxis, yAxis, scale, dragTick }, skipLogic);
    }
  }, [onStateChange, xAxis, yAxis, scale, dragTick]);  

  const handleDrag = useCallback((newVal: number, lastDraggable: DataPoint) => {
    let skipLogic = undefined;
    const updatedData = data.map((d) => {
      if (d.name === lastDraggable.name) {
        const newValuePos = d3.range(newVal === 0 ? 1 : Math.ceil(newVal / scale)).map((j) => (j + 0.55) * scale);
        const index = data.findIndex(d => d.name === lastDraggable.name);
        skipLogic = `data[${index}].value`;
        // console.log('skipLogic', skipLogic);
        return { ...d, value: newVal, valuePos: newValuePos };
      }
      return d;
    });
    updateState(updatedData, skipLogic);
  }, [data, scale, updateState]);

  // useEffect(() => {
  //   console.log('isHovering:', isHovering);
  //   console.log('isDragging:', isDragging);
  // }, [isHovering, isDragging]);  

  const configureAxes = useCallback((data: DataPoint[], width: number, height: number, 
  margin: { top: number; right: number; bottom: number; left: number; },
  yDomain: [number, number], scale: number, xAxis?: AxisConfig, yAxis?: AxisConfig) => {
    
    const x = d3.scaleBand<string>()
      .domain(data.map((d) => d.name))
      .range([margin.left, width - margin.right])
      .padding(0.1);
  
    const y = d3.scaleLinear()
      .domain(yDomain)
      .nice()
      .range([height - margin.bottom, margin.top]);
  
    const xAxisCall = d3.axisBottom(x).tickSizeOuter(0);
  
    const yAxisCall = d3.axisLeft(y).tickValues(
      d3.range(
        yAxis?.min ?? 0,
        (yAxis?.max ?? Math.ceil(d3.max(data, d => d.value)! / scale) * scale) + 1,
        yAxis?.tick ?? 1
      )
    ).tickFormat(d => {
      const decimalPlaces = Math.max(
        (yAxis?.min?.toString().split('.')[1]?.length || 0),
        (yAxis?.max?.toString().split('.')[1]?.length || 0),
        (yAxis?.tick?.toString().split('.')[1]?.length || 0)
      );
      const numberValue = Number(d);
      return decimalPlaces > 0 ? numberValue.toFixed(decimalPlaces) : numberValue.toString();
    });
  
    return { x, y, xAxisCall, yAxisCall };
  }, []);

  const renderAxes = useCallback((svg, xAxisCall, yAxisCall, margin, height, width) => {
    const xAxisElement = svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(xAxisCall);

    const fontSize = 18;
    const scaledFontSize = fontSize / actualScale;
    const maxLabelWidth = 90;

    xAxisElement
      .selectAll('text')
      .attr('dy', '1em')
      .style('font-family', Fonts.quicksandMedium.fontFamily)
      .style('font-weight', Fonts.quicksandMedium.fontWeight)
      .style('font-size', `${scaledFontSize}px`)
      .style('fill', (d, i) => data[i].color || Colors.vizDefault)
      .call(wrapText, maxLabelWidth)
      .on('click', function (event, d) {
        const colorIndex = data.findIndex((item) => item.name === d);
        if (colorIndex !== -1) {
          const color = data[colorIndex].color || Colors.vizDefault;
          handleMouseEnter(color);
        }
      });

    svg.selectAll('.domain').attr('stroke-width', 2);

    if (actualScale > SCALE_BREAKPOINT) {
      if (xAxis?.label) {svg
          .append('text')
          .attr('class', 'x-axis-label')
          .attr('x', width / 2)
          .attr('y', height + margin.top - margin.bottom*0.75)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', 'black')
          .style('font-family', Fonts.quicksandLight.fontFamily)
          .style('font-weight', Fonts.quicksandLight.fontWeight)
          .style('font-size', `${fontSize}px`)
          .text(xAxis.label);
      }
    }

    const yAxisElement = svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(yAxisCall)
      .selectAll('text')
      .style('font-family', Fonts.quicksandLight.fontFamily)
      .style('font-weight', Fonts.quicksandLight.fontWeight)
      .style('font-size', `${scaledFontSize}px`);

    svg.selectAll('.domain').attr('stroke-width', 2);

    if (actualScale > SCALE_BREAKPOINT) {
      if (yAxis?.label) {
        svg
          .append('text')
          .attr('x', -height / 2)
          .attr('y', margin.left / 3)
          .attr('text-anchor', 'middle')
          .attr('fill', 'black')
          .attr('transform', 'rotate(-90)')
          .style('font-family', Fonts.quicksandLight.fontFamily)
          .style('font-weight', Fonts.quicksandLight.fontWeight)
          .style('font-size', `${fontSize}px`)
          .text(yAxis.label);
      }
    }

    // scale label
    if (scale !== 1) {
      const scaleLabelX = xAxis?.label ? width - 12 : width / 2;
      const scaleLabelAnchor = xAxis?.label ? 'end' : 'middle';
      svg
          .append('text')
          .attr('x', scaleLabelX)
          .attr('y', height + margin.top - margin.bottom*0.75)
          .attr('text-anchor', scaleLabelAnchor)
          .attr('alignment-baseline', 'middle')
          .attr('fill', 'black')
          .style('font-family', Fonts.quicksandLight.fontFamily)
          .style('font-weight', Fonts.quicksandLight.fontWeight)
          .style('font-size', `${scaledFontSize}px`)
          .text(`Scale = ${scale}`);
    }
    
  }, [data, xAxis, yAxis, handleMouseEnter, handleMouseLeave]);

  const renderElements = useCallback((svg, x, y, margin, height, dotRadius) => {
    const yDomain: [number, number] = [yAxis?.min ?? 0, yAxis?.max ?? Math.ceil(d3.max(data, d => d.value) / scale) * scale];
  
    const elements = svg
      .selectAll('.data-group')
      .data(data, (d: DataPoint) => d.name) // Use a key function to ensure proper data binding
      .join(
        enter => enter.append('g').attr('class', 'data-group'),
        update => update,
        exit => exit.remove()
      )
      .attr('transform', (d: DataPoint) => `translate(${x(d.name)! + x.bandwidth() / 2}, 0)`)
      .on('click', (event, d: DataPoint) => {
        handleMouseEnter(d.color || Colors.vizDefault);
      });
  
    elements.each(function (d: DataPoint) {
      const element = d3.select(this);
      const elementGroup = element.selectAll('.element-group').data([d]).join('g').attr('class', 'element-group');
  
      renderHighlight(elementGroup, d, y, dotRadius);
  
      const images = elementGroup.selectAll('image').data(d.value === 0 ? [] : d.image ? d.valuePos : [], (pos: number) => pos);
  
      images.join(
        enter => enter.append('image')
          .attr('class', 'image-element')
          .attr('href', d.image)
          .attr('x', -dotRadius)
          .attr('y', (pos) => y(pos) - dotRadius)
          .attr('width', dotRadius * 2)
          .attr('height', dotRadius * 2),
        update => update
          .attr('href', d.image)
          .attr('y', (pos) => y(pos) - dotRadius),
        exit => exit.remove()
      );
  
      const circles = elementGroup.selectAll('circle').data(d.value === 0 ? [] : d.image ? [] : d.valuePos, (pos: number) => pos);
  
      circles.join(
        enter => enter.append('circle')
          .attr('class', 'circle-element')
          .attr('cx', 0)
          .attr('cy', (pos) => y(pos))
          .attr('r', dotRadius)
          .attr('fill', d.color || Colors.vizDefault),
        update => update
          .attr('cy', (pos) => y(pos))
          .attr('fill', d.color || Colors.vizDefault),
        exit => exit.remove()
      );
    });
  }, [data, handleMouseEnter, handleMouseLeave, scale, isHovering, isDragging]);  
  
  const renderHighlight = (elementGroup: any, d: DataPoint, y: d3.ScaleLinear<number, number>, dotRadius: number) => {
    const rectanglePadding = { x: 8, y: 0 };
    const minY = y(d.value);
    const maxY = y(0);
  
    if (isHovering === d.name || isDragging === d.name) {
      elementGroup
        .append('rect')
        .attr('x', -dotRadius - rectanglePadding.x)
        .attr('y', minY - rectanglePadding.y)
        .attr('width', (dotRadius + rectanglePadding.x) * 2)
        .attr('height', maxY - minY + rectanglePadding.y * 2)
        .attr('fill', d.color)
        .attr('fill-opacity', 0.1)
        .attr('stroke', 'none')
        .attr('rx', 2)
        .attr('ry', 2);
    }
  };
  
  const renderDraggers = useCallback((svg, x, y, margin, height, scale) => {
    const yDomain = [yAxis?.min ?? 0, yAxis?.max ?? Math.ceil(d3.max(data, d => d.value) / scale) * scale];
    const draggableData = data.filter(d => d.draggable);
    draggableData.forEach(draggable => {
      Dragger({
        svgRef: svgRef,
        id: `${draggable.name}-segment-${draggable.index}-${draggable.color}`,
        color: draggable.color,
        direction: 'up',
        x: x(draggable.name)! + x.bandwidth() / 2,
        y: y(draggable.value),
        minPos: margin.top,
        maxPos: height - margin.bottom,
        value: draggable.value,
        tick: scale,
        decimalPrecision: 0,
        minVal: yDomain[0],
        maxVal: yDomain[1],
        showLabel: 'top',
        actualScale,
        handleDrag: (newValue) => { handleDrag(newValue, draggable) },
        startDrag: () => setIsDragging(draggable.name),
        endDrag: () => setIsDragging(null),
        startHover: () => setIsHovering(draggable.name),
        endHover: () => setIsHovering(null),
      });
    });
  }, [data, scale, yAxis, handleDrag]);

  const render = useCallback(() => {
    if (!svgRef.current) return;
  
    const svg = d3.select(svgRef.current);
    const width = 400;
    const height = 400;
    const margin = { top: 40, right: 10, bottom: 70/actualScale, left: 50 };
    const dotRadius = 14;
  
    svg
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', '100%');
  
    svg.selectAll('*').remove();
  
    const yDomain: [number, number] = [yAxis?.min ?? 0, yAxis?.max ?? Math.ceil(d3.max(data, d => d.value) / scale) * scale];
    const { x, y, xAxisCall, yAxisCall } = configureAxes(data, width, height, margin, yDomain, scale, xAxis, yAxis);
  
    renderElements(svg, x, y, margin, height, dotRadius);
    renderAxes(svg, xAxisCall, yAxisCall, margin, height, width);
    renderDraggers(svg, x, y, margin, height, scale);
  }, [svgRef, data, scale, xAxis, yAxis, configureAxes, handleMouseEnter, handleMouseLeave, renderElements, renderAxes, renderDraggers]);
  useEffect(() => {
    render();
  }, [render]);

  return <svg ref={svgRef}></svg>;
};

export default PictureGraph;
