import { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";

const LabelRoot = styled.div`
    background-color: ${Colors.grey1};
    border-radius: 12px;
    padding: 4px 10px;
    display: inline-block;
    font-family: ${Fonts.quicksandSemiBold.fontFamily};
    font-weight: ${Fonts.quicksandSemiBold.fontWeight};
    font-size: 12px;
    text-transform: uppercase;
    color: ${Colors.black};
`;

export type LabelProps = {
    text: string;
};

const Label: FunctionComponent<LabelProps> = ({ text }) => {
    return <LabelRoot>{text}</LabelRoot>;
};

export default Label;
