// ScanOverlay.tsx
import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Colors from './common/Colors';
import Icon from './common/Icon';

const cornerSize = 30;
const cornerStroke = 4;
const cornerDistFromEdge = 8;

const EDGE_PADDING = {
  HORIZONTAL: 30, // padding from left/right edges
  VERTICAL: 100 // padding from top/bottom edges
};

const CAPTURE_ZONE_LIMITS = {
  MIN_WIDTH: 150,
  MIN_HEIGHT: 80,
};

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OverlayWrapper = styled.div<{ $rootZIndex: number; }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: ${Colors.transparent};
  z-index: ${props => props.$rootZIndex};
  pointer-events: none;
`;

const CaptureZone = styled.div<{ $zIndex: number; $rootZIndex: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 200px;
  max-width: 100%;
  max-height: 80%;
  min-width: 150px;
  min-height: 80px;
  z-index: ${props => props.$zIndex};
  pointer-events: none;

  &::before {
    content: ' ';
    position: absolute;
    inset: 0;
    background-color: transparent;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    z-index: ${props => props.$rootZIndex - 1};
    pointer-events: none;
  }
`;

const CornerMarker = styled.div<{ $top?: string; $bottom?: string; $left?: string; $right?: string; $rotation: number; $cursor: string }>`
  position: absolute;
  width: ${cornerSize * 2}px;
  height: ${cornerSize * 2}px;
  top: ${props => props.$top};
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};
  right: ${props => props.$right};
  transform: rotate(${props => props.$rotation}deg);
  cursor: ${props => props.$cursor};
  touch-action: none;
  &::before, &::after {
    content: '';
    position: absolute;
    background-color: ${Colors.yellow};
    border-radius: 4px;
  }
  &::before {
    width: ${cornerSize}px;
    height: ${cornerStroke}px;
    top: 0;
    left: 0;
  }
  &::after {
    width: ${cornerStroke}px;
    height: ${cornerSize}px;
    top: 0;
    left: 0;
  }
  pointer-events: auto;
`;

interface ScanOverlayProps {
  rootZIndex?: number;
  zIndex?: number;
  onCornerPositionsChange?: (positions: any) => void;
  captureZoneRef: React.RefObject<HTMLDivElement>;
  showScanAnimation?: boolean;
  renderCapturedImage?: boolean;
  capturedImageUrl?: string;
}

const ScanOverlay: FunctionComponent<ScanOverlayProps> = ({
  rootZIndex = 1,
  zIndex = 100,
  onCornerPositionsChange,
  captureZoneRef,
  showScanAnimation = false,
  renderCapturedImage = false,
  capturedImageUrl,
}) => {
  const [cornerPositions, setCornerPositions] = useState(null);
  const [captureZoneDimensions, setCaptureZoneDimensions] = useState({ width: 600, height: 200 });
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [relativePosition, setRelativePosition] = useState({ x: 0.5, y: 0.5 }); // Store position as percentage

  const topLeftRef = useRef<HTMLDivElement>(null);
  const topRightRef = useRef<HTMLDivElement>(null);
  const bottomLeftRef = useRef<HTMLDivElement>(null);
  const bottomRightRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  
  const relativeContainer = document.getElementById('relative-container');
  const relativeWidth = relativeContainer?.offsetWidth;
  const relativeX = relativeContainer?.offsetLeft;
  const [relativeContainerDimensions, setRelativeContainerDimensions] = useState({ x: 0, width: 0 });

  // useEffect(() => {
  //   console.log('Relative X:', relativeX);
  //   console.log('Relative width:', relativeWidth);
  // }, [relativeWidth, relativeX]);

  const applyConstraints = () => {
    if (!captureZoneRef.current) return;

    // console.log('Applying constraints');

    const maxWidth = relativeWidth ? relativeWidth - (EDGE_PADDING.HORIZONTAL * 2) : screenWidth - (EDGE_PADDING.HORIZONTAL * 2);
    const minX = relativeX ? relativeX + EDGE_PADDING.HORIZONTAL : EDGE_PADDING.HORIZONTAL;
    const maxX = relativeX && relativeWidth ? 
      relativeX + relativeWidth - EDGE_PADDING.HORIZONTAL : 
      screenWidth - EDGE_PADDING.HORIZONTAL;

    // Apply width constraints
    const newWidth = Math.max(
      CAPTURE_ZONE_LIMITS.MIN_WIDTH,
      Math.min(captureZoneDimensions.width, maxWidth)
    );

    // Apply position constraints
    const newLeft = Math.max(
      minX,
      Math.min(position.left, maxX - newWidth)
    );

    // Only update if values have changed
    if (newWidth !== captureZoneDimensions.width || newLeft !== position.left) {
      setCaptureZoneDimensions(prev => ({ ...prev, width: newWidth }));
      setPosition(prev => ({ ...prev, left: newLeft }));
    }
  };

  const updateCornerPositions = () => {
    if (!topLeftRef.current || !topRightRef.current || !bottomLeftRef.current || !bottomRightRef.current || !overlayRef.current) {
      console.warn('Corner refs not ready');
      return;
    }

    if (!captureZoneRef.current) {
      console.warn('Capture zone ref not ready');
      return;
    }

    // console.log('Updating corner positions');
  
    const captureZoneRect = captureZoneRef.current.getBoundingClientRect();
    const newCornerPositions = {
      topLeft: {
        x: captureZoneRect.left + cornerDistFromEdge,
        y: captureZoneRect.top + cornerDistFromEdge,
      },
      topRight: {
        x: captureZoneRect.right - cornerDistFromEdge,
        y: captureZoneRect.top + cornerDistFromEdge,
      },
      bottomRight: {
        x: captureZoneRect.right - cornerDistFromEdge,
        y: captureZoneRect.bottom - cornerDistFromEdge,
      },
      bottomLeft: {
        x: captureZoneRect.left + cornerDistFromEdge,
        y: captureZoneRect.bottom - cornerDistFromEdge,
      }
    };
  
    // console.log('Updating corner positions:', newCornerPositions);
    setCornerPositions(newCornerPositions);
    onCornerPositionsChange?.(newCornerPositions);
  }; 

  // Update the initial centering
  useEffect(() => {
    // Wait for the relative container to be available
    const initializeOverlay = () => {
      if (!relativeContainer) {
        // If container isn't ready, try again in a moment
        requestAnimationFrame(initializeOverlay);
        return;
      }

      // Center the capture zone
      centerCaptureZone();
      
      // Update corner positions after a short delay to ensure DOM updates
      setTimeout(() => {
        updateCornerPositions();
      }, 100);
    };

    initializeOverlay();
  }, [relativeContainer]);

  useEffect(() => {
    if (position.left !== 0 || position.top !== 0) {
      updateCornerPositions();
    }
  }, [position, captureZoneDimensions]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      updateCornerPositions();
    });

    if (captureZoneRef.current) {
      observer.observe(captureZoneRef.current);
    }

    return () => {
      if (captureZoneRef.current) {
        observer.unobserve(captureZoneRef.current);
      }
    };
  }, [captureZoneRef, captureZoneDimensions, onCornerPositionsChange]);

  // Resize observer for relative container
  useEffect(() => {
    const container = document.getElementById('relative-container');
    if (!container) return;

    const observer = new ResizeObserver(() => {
      centerCaptureZone();
      applyConstraints();
      updateCornerPositions();
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [captureZoneDimensions.width, position.left]);

  const handleDrag = (e: React.MouseEvent | React.TouchEvent, corner: string) => {
    e.preventDefault();
    const startX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const startWidth = captureZoneRef.current?.offsetWidth ?? 0;
    const startHeight = captureZoneRef.current?.offsetHeight ?? 0;
    const startLeft = position.left;
    const startTop = position.top;

    const onMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
      const currentX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const currentY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;
      let newWidth = startWidth;
      let newHeight = startHeight;
      let newLeft = startLeft;
      let newTop = startTop;

      if (corner.includes('right')) {
        newWidth = startWidth + (currentX - startX);
      } else if (corner.includes('left')) {
        const deltaX = currentX - startX;
        newWidth = startWidth - deltaX;
        newLeft = startLeft + deltaX;
      }

      if (corner.includes('bottom')) {
        newHeight = startHeight + (currentY - startY);
      } else if (corner.includes('top')) {
        const deltaY = currentY - startY;
        newHeight = startHeight - deltaY;
        newTop = startTop + deltaY;
      }
      
      // Constrain width and horizontal position using relative container
      const maxWidth = relativeWidth ? relativeWidth - (EDGE_PADDING.HORIZONTAL * 2) : screenWidth - (EDGE_PADDING.HORIZONTAL * 2);
      const minX = relativeX ? relativeX + EDGE_PADDING.HORIZONTAL : EDGE_PADDING.HORIZONTAL;
      const maxX = relativeX && relativeWidth ? 
        relativeX + relativeWidth - EDGE_PADDING.HORIZONTAL : 
        screenWidth - EDGE_PADDING.HORIZONTAL;

      newWidth = Math.max(
        CAPTURE_ZONE_LIMITS.MIN_WIDTH, 
        Math.min(newWidth, maxWidth)
      );
      newLeft = Math.max(
        minX, 
        Math.min(newLeft, maxX - newWidth)
      );

      // Vertical constraints remain the same
      newHeight = Math.max(
        CAPTURE_ZONE_LIMITS.MIN_HEIGHT, 
        Math.min(newHeight, screenHeight - (EDGE_PADDING.VERTICAL * 2))
      );
      newTop = Math.max(
        EDGE_PADDING.VERTICAL, 
        Math.min(newTop, screenHeight - newHeight - EDGE_PADDING.VERTICAL)
      );

      setCaptureZoneDimensions({ width: newWidth, height: newHeight });
      setPosition({ left: newLeft, top: newTop });
      
      // Update relative position after setting new position
      const availableWidth = relativeWidth - newWidth;
      const availableHeight = screenHeight - newHeight;
      
      setRelativePosition({
        x: (newLeft - relativeX) / availableWidth,
        y: newTop / availableHeight
      });
      
      updateCornerPositions();
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchend', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onMouseMove, { passive: false });
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchend', onMouseUp);
  };

  const markers = [
    { $top: `${cornerDistFromEdge}px`, $left: `${cornerDistFromEdge}px`, $rotation: 0, $cursor: 'nwse-resize', $corner: 'top-left', ref: topLeftRef },
    { $top: `${cornerDistFromEdge}px`, $right: `${cornerDistFromEdge}px`, $rotation: 90, $cursor: 'nesw-resize', $corner: 'top-right', ref: topRightRef },
    { $bottom: `${cornerDistFromEdge}px`, $left: `${cornerDistFromEdge}px`, $rotation: -90, $cursor: 'nesw-resize', $corner: 'bottom-left', ref: bottomLeftRef },
    { $bottom: `${cornerDistFromEdge}px`, $right: `${cornerDistFromEdge}px`, $rotation: 180, $cursor: 'nwse-resize', $corner: 'bottom-right', ref: bottomRightRef },
  ];

  const centerCaptureZone = useCallback(() => {
    if (captureZoneRef.current && relativeContainerDimensions.width > 0) {
      // console.log('Centering capture zone');

      const { x: relativeX, width: relativeWidth } = relativeContainerDimensions;

      // Calculate the maximum width based on the relative container
      const maxWidth = Math.min(
        relativeWidth - (EDGE_PADDING.HORIZONTAL * 2),
        captureZoneDimensions.width
      );

      // Calculate the maximum height based on the screen height
      const maxHeight = Math.min(
        screenHeight - (EDGE_PADDING.VERTICAL * 2),
        captureZoneDimensions.height
      );

      // Calculate position based on relative offset from center
      const availableWidth = relativeWidth - maxWidth;
      const availableHeight = screenHeight - maxHeight;

      // Use relativePosition to maintain relative offset from center
      const left = relativeX + (availableWidth * relativePosition.x);
      const top = availableHeight * relativePosition.y;

      // Only update if the position or dimensions have changed
      if (
        Math.abs(position.left - left) > 1 ||
        Math.abs(position.top - top) > 1 ||
        Math.abs(captureZoneDimensions.width - maxWidth) > 1 ||
        Math.abs(captureZoneDimensions.height - maxHeight) > 1
      ) {
        setCaptureZoneDimensions({ width: maxWidth, height: maxHeight });
        setPosition({ left, top });

        requestAnimationFrame(() => {
          updateCornerPositions();
        });
      }
    }
  }, [relativeContainerDimensions, captureZoneDimensions, screenHeight, position, relativePosition]);

  const updatePositionFromRelative = () => {
    if (captureZoneRef.current && relativeContainer) {
      // Ensure width doesn't exceed container
      const maxWidth = Math.min(
        relativeWidth,
        captureZoneDimensions.width
      );
      
      // Calculate absolute position from relative position
      const left = relativeX + relativePosition.x * (relativeWidth - maxWidth);
      const top = relativePosition.y * (screenHeight - captureZoneDimensions.height);
      
      // Apply constraints
      const constrainedLeft = Math.max(
        relativeX,
        Math.min(left, relativeX + relativeWidth - maxWidth)
      );
      
      const constrainedTop = Math.max(
        EDGE_PADDING.VERTICAL,
        Math.min(top, screenHeight - captureZoneDimensions.height - EDGE_PADDING.VERTICAL)
      );
      
      // Update dimensions and position
      setCaptureZoneDimensions(prev => ({ ...prev, width: maxWidth }));
      setPosition({ left: constrainedLeft, top: constrainedTop });
    }
  };

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      applyConstraints();
      updatePositionFromRelative();
      updateCornerPositions();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [captureZoneDimensions, relativePosition]);

  useEffect(() => {
    const container = document.getElementById('relative-container');
    if (!container) return;

    const updateRelativeContainerDimensions = () => {
      const rect = container.getBoundingClientRect();
      setRelativeContainerDimensions(prev => {
        if (prev.x !== rect.x || prev.width !== rect.width) {
          return { x: rect.x, width: rect.width };
        }
        return prev;
      });
    };

    const observer = new ResizeObserver(updateRelativeContainerDimensions);
    observer.observe(container);

    // Initial update
    updateRelativeContainerDimensions();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    centerCaptureZone();
  }, [relativeContainerDimensions, centerCaptureZone]);

  useEffect(() => {
    if (relativeX !== undefined && relativeWidth !== undefined) {
      centerCaptureZone();
    }
  }, [relativeX, relativeWidth, centerCaptureZone]);

  return (
    <>
      <RelativeContainer id="relative-container"></RelativeContainer>
      <OverlayWrapper id="overlay-wrapper" ref={overlayRef} $rootZIndex={rootZIndex} >
        <CaptureZone
          ref={captureZoneRef}
          $zIndex={zIndex}
          $rootZIndex={rootZIndex}
          style={{
            width: `${captureZoneDimensions.width}px`,
            height: `${captureZoneDimensions.height}px`,
            left: `${position.left}px`,
            top: `${position.top}px`
          }}
        >
          <CapturedImage renderCapturedImage={renderCapturedImage} capturedImageUrl={capturedImageUrl} />
          {showScanAnimation && <ScanAnimation />}
          {markers.map((marker) => (
            <CornerMarker
              key={marker.$corner}
              ref={marker.ref}
              onMouseDown={(e) => handleDrag(e, marker.$corner)}
              onTouchStart={(e) => handleDrag(e, marker.$corner)}
              {...marker}
            />
          ))}
          <Icon icon={'plus'} color={Colors.whiteTransparent} size={28} />
        </CaptureZone>
      </OverlayWrapper>
    </>
  );
};
export default ScanOverlay;

// --------------- CapturedImage ---------------

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScanImage = styled.img`
  border-radius: 6px;
  border: none;
  // border: 1px solid white;
`;

interface CapturedImageProps {
  renderCapturedImage: boolean;
  capturedImageUrl: string;
}

const CapturedImage: FunctionComponent<CapturedImageProps> = ({ renderCapturedImage, capturedImageUrl }) => {
  return (
    <>
      {renderCapturedImage && capturedImageUrl && (
        <ImageContainer>
          <ScanImage src={capturedImageUrl} alt="Captured" />
        </ImageContainer>
      )}
    </>
  );
};

// --------------- ScanAnimation ---------------

const ScanLine = styled.div`
  position: absolute;
  top: ${cornerDistFromEdge}px;
  bottom: ${cornerDistFromEdge}px;
  left: ${cornerDistFromEdge}px;
  width: 2px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 70px 20px rgba(255, 255, 255, 0.4);
  clip-path: inset(0);
  animation: scanX 1s ease-in-out infinite alternate,
             scanY 2s ease-in-out infinite;

  @keyframes scanX {
    to {
      transform: translateX(calc(100% - ${cornerDistFromEdge}px));
      left: calc(100% - ${cornerDistFromEdge}px);
    }
  }

  @keyframes scanY {
    33% {
      clip-path: inset(0 0 0 -100px);
    }
    50% {
      clip-path: inset(0 0 0 0);
    }
    83% {
      clip-path: inset(0 -100px 0 0);
    }
  }
`;

const ScanAnimation: FunctionComponent = () => {
  return <ScanLine />;
};
