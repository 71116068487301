// useScan.js
import { gql, useMutation } from '@apollo/client';

const ANALYZE_SCAN_MUTATION = gql`
  mutation analyzeScan($imageId: ID!) {
    analyzeScan(imageId: $imageId) {
      text
      status
      error
    }
  }
`;

export function useAnalyzeScan() {
  const [analyzeScan, { data, loading, error }] = useMutation(ANALYZE_SCAN_MUTATION);

  const analyzeScanById = async (imageId) => {
    if (imageId) {
      const response = await analyzeScan({ variables: { imageId } });
      if (response && response.data && response.data.analyzeScan) {
        return {
          text: response.data.analyzeScan.text,
          status: response.data.analyzeScan.status,
          error: response.data.analyzeScan.error,
        };
      }
    }
    return Promise.resolve(null);
  };

  return { data, loading, error, analyzeScanById };
}
