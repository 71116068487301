// utils/convertTypes.tsx
export function convertAllTypes<T>(obj: any, typeDefinition: T, isRoot: boolean = true): T {
    const result: any = { ...obj };

    try {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            const expectedType = getType(typeDefinition, key);
            // console.log('Key:', key, ', Original value:', value, ', Expected type:', expectedType);

            // Leave null and undefined as is
            if (value === null || value === undefined) {
                //console.log(`Value for key: ${key} is null or undefined, leaving without changing.`);
            }
            // Recursively convert objects
            else if (expectedType === 'object' && !Array.isArray(value)) {
                //console.log(`Recursively converting object for key: ${key}`);
                result[key] = convertAllTypes(value, typeDefinition ? typeDefinition[key] : {}, false);
            } else if (expectedType === 'array-of-objects') {
                //console.log(`Recursively converting array of objects for key: ${key}`);
                result[key] = value.map((v: any) => convertAllTypes(v, typeDefinition[key][0], false));
            }
            // Convert single numbers
            else if (expectedType === 'number') {
                const convertedValue = parseFloat(value);
                if (Number.isNaN(convertedValue) || !Number.isFinite(convertedValue)) {
                    console.warn(`Invalid number detected for key: ${key}, using 0 instead`);
                    result[key] = 0;
                } else {
                    result[key] = convertedValue;
                }
            // Convert array of numbers
            } else if (expectedType === 'array-of-number') {
                const convertedArray = value.map((v: any) => {
                    const num = parseFloat(v);
                    if (Number.isNaN(num) || !Number.isFinite(num)) {
                        console.warn(`Invalid number detected in array for key: ${key}, using 0 instead`);
                        return 0;
                    }
                    return num;
                });
                // console.log(`Converted array of numbers for key: ${key}:`, convertedArray);
                result[key] = convertedArray;
            // Convert array of arrays of numbers
            } else if (expectedType === 'array-of-array-of-number') {
                const convertedNestedArray = value.map((arr: any[]) =>
                    arr.map((v: any) => {
                        const num = parseFloat(v);
                        if (Number.isNaN(num) || !Number.isFinite(num)) {
                            console.warn(`Invalid number detected in nested array for key: ${key}, using 0 instead`);
                            return 0;
                        }
                        return num;
                    })
                );
                // console.log(`Converted nested array of numbers for key: ${key}:`, convertedNestedArray);
                result[key] = convertedNestedArray;
            }
            // Convert single strings
            else if (expectedType === 'string') {
                const convertedValue = value.toString();
                //console.log(`Converted to string for key: ${key}:`, convertedValue);
                result[key] = convertedValue;
            // Convert array of strings
            } else if (expectedType === 'array-of-string') {
                const convertedArray = value.map((v: any) => v.toString());
                //console.log(`Converted array of strings for key: ${key}:`, convertedArray);
                result[key] = convertedArray;
            // Convert array of arrays of strings
            } else if (expectedType === 'array-of-array-of-string') {
                const convertedNestedArray = value.map((arr: any[]) => arr.map((v: any) => v.toString()));
                //console.log(`Converted nested array of strings for key: ${key}:`, convertedNestedArray);
                result[key] = convertedNestedArray;
            }
            // Convert single booleans
            else if (expectedType === 'boolean') {
                result[key] = value === 'true' || value === true;
            }
            // Convert array of booleans
            else if (expectedType === 'array-of-boolean') {
                result[key] = value.map((v: any) => v === 'true' || v === true);
            }
            // No conversion needed, still include field
            else {
                //console.log(`No conversion needed for key: ${key}, value:`, value);
                result[key] = value;
            }
        });
    } catch (error) {
        console.error('Error converting types:', error);
    }

    function getType(obj: any, key: string): string | undefined {
        if (Array.isArray(obj?.[key])) {
            const firstElement = obj[key][0];
            if (Array.isArray(firstElement)) {
                const nestedElementType = typeof firstElement[0];
                return `array-of-array-of-${nestedElementType}`;
            } else if (typeof firstElement === 'object' && firstElement !== null) {
                return 'array-of-objects';
            } else {
                return `array-of-${typeof firstElement}`;
            }
        } else if (obj && typeof obj[key] === 'object' && obj[key] !== null) {
            return 'object';
        } else {
            return typeof obj?.[key];
        }
    }

    // if (isRoot) {
    //     console.log('INITIAL OBJ TO CONVERT:', obj, '\nTYPE DEFINITION:', typeDefinition);
    //     console.log('FINAL CONVERTED RESULT:', result);
    // }
    return result as T;
}

// Helpers for dynamic type props
export type ValueType = 'number' | 'string' | 'boolean';

export type MatchValueType<T, V> = T extends 'number' ? (V extends number | null ? V : never) :
                                T extends 'string' ? (V extends string | null ? V : never) :
                                T extends 'boolean' ? (V extends boolean | null ? V : never) :
                                never;

export function getDefaultValueForType(type: ValueType): any {
    switch (type) {
      case 'number':
        return 0;
      case 'string':
        return '';
      case 'boolean':
        return false;
      default:
        return null;
    }
};