// MessageAlert.tsx
import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import Icon from "../common/Icon";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import Button from './Button';
import _ from 'lodash';

const getBGColor = (colorName) => {
    const colorMapping = {
        red: Colors.redPale, 
        yellow: Colors.yellowPale,
        green: Colors.greenPale, 
    };
    return colorMapping[colorName] || Colors.greenPale; // default 
};

const getAccentColor = (colorName) => {
    const accentColorMapping = {
        red: Colors.red, 
        yellow: Colors.yellow, 
        green: Colors.green, 
    };
    return accentColorMapping[colorName] || Colors.green; // default 
};

const getIconType = (colorName) => {
    const iconMapping = {
        red: 'exclamation',
        yellow: 'exclamation',
        green: 'info',
    };
    return iconMapping[colorName] || 'info'; // default
};

const MessageBackground = styled.div`
    background-color: ${({ color }) => getBGColor(color)};
    min-width: 300px;
    width: fit-content;
    max-width: calc(100% - 20px);
    border-radius: 14px; 
    position: relative;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    padding: 12px 14px 12px 14px;
    margin: auto;
    box-shadow: -2px 6px 6px rgba(0,0,0,0.1);
    z-index: 100;
    gap: 12px;
    text-align: left;
`;

const IconContainer = styled.div`
    border-radius: 50%;
    background-color: ${({ color }) => getAccentColor(color)};
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;  
    padding: 10px 10px 10px 10px;
    margin: auto;
`;

const TextContainer = styled.div`
    width: 100%;  
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: center; 
    gap: 4px;
    margin: auto; 
`;
const TitleText = styled.div`   
    font-weight: ${Fonts.quicksandBold.fontWeight};
    font-family: ${Fonts.quicksandBold.fontFamily};
    font-size: 16px;
    line-height: 1.2em;
    color: ${Colors.grayMed};
`;
const BodyText = styled.div`
    font-weight: ${Fonts.quicksandSemiLight.fontWeight};
    font-family: ${Fonts.quicksandSemiLight.fontFamily};
    font-size: 16px;
    line-height: 1.2em; 
    color: ${Colors.grayMed};
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;  
    margin: auto;
`;

export type MessageAlertType = {
    titleText: string;
    bodyText: string | string[];
    color: string;
    onClose?: () => void;
    includeClose?: boolean;
};

const modalRef = React.createRef<HTMLDivElement>();

const MessageAlert: FunctionComponent<MessageAlertType> = ({
    titleText,
    bodyText,
    color,
    onClose,
    includeClose = false,
 }) => {

    return ( 
        <MessageBackground color={color} ref={modalRef}>
            <IconContainer color={color}>
                <Icon icon={getIconType(color)} size={28} color={Colors.white} onClick={() => {}} />
            </IconContainer>
            <TextContainer>
                <TitleText>{titleText}</TitleText>
                {Array.isArray(bodyText) ? (
                bodyText.map((text, index) => (
                    <BodyText key={index}>{text}</BodyText>
                ))
                ) : (
                <BodyText>{bodyText}</BodyText>
                )}
            </TextContainer>
            {includeClose !== false && (
            <ButtonContainer>
                <Button icon={'delete'} color={'ghost'} onClick={onClose} />
            </ButtonContainer>)}
        </MessageBackground>
    ); }

export default MessageAlert;