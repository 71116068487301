// ButtonImage.tsx
import { FunctionComponent, useState, MouseEvent } from "react";
import styled from "styled-components";
import Colors, { blendWithWhite } from "../common/Colors";
import _ from 'lodash';

const ButtonImageRoot = styled.button<{ $borderColor: string }>`
    background-color: ${Colors.white};
    border: none;
    // border: 1px solid ${({ $borderColor }) => $borderColor};
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 62px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
        cursor: default;
        opacity: 0.4;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export type ButtonImageType = {
    imgFile: string;
    onClick: () => void;
    disabled?: boolean;
    color?: string;
};

const ButtonImage: FunctionComponent<ButtonImageType> = ({
    imgFile,
    onClick,
    disabled = false,
    color = 'black',
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleClick = _.debounce(() => {
        onClick();
    }, 500);

    const onClickRaw = (e: MouseEvent) => {
        e.preventDefault();
        if (!disabled) {
            handleClick();
        }
    };

    const setBorderColor = (color: string, state: string) => {
        const colorOptions = {
            black: {
                default: Colors.white,
                hover: Colors.grayPale,
                active: Colors.grey2,
                disabled: blendWithWhite(Colors.grey1, 0.4),
            }
        };

        return colorOptions[color]?.[state] || Colors.black;
    };

    const state = disabled ? 'disabled' : isActive ? 'active' : isHovered ? 'hover' : 'default';
    const borderColor = setBorderColor(color, state);

    return (
        <ButtonImageRoot
            onClick={onClickRaw}
            disabled={disabled}
            $borderColor={borderColor}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
            onMouseDown={() => !disabled && setIsActive(true)}
            onMouseUp={() => !disabled && setIsActive(false)}
        >
            <img src={imgFile} alt="button image" />
        </ButtonImageRoot>
    );
};

export default ButtonImage;
