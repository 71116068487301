// Generate.tsx
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components/basic/Button";
import { generateAddition } from "./grid/addition";
import { generateSubtraction } from "./grid/subtraction";
import { generateMultiplication } from "./grid/multiplication";
import { generateDivision } from "./grid/division";
import { generatePercent } from "./number_line/percent";
import { generateAddition as generateNLAddition } from "./number_line/addition";
import { generateSubtraction as generateNLSubtraction } from "./number_line/subtraction";
import { generateMultiplication as generateNLMultiplication } from "./number_line/multiplication";
import { generateDivision as generateNLDivision } from "./number_line/division";
import { generateInequality } from "./number_line/inequality";

export interface problem {
    id: string;
    createdAt: string;
    name: string;
    numColors: number;
    variables?: any;
    text: string;
    visualizations: {}[];
    answer?: any;
    trace?: { solution?: string, grade_standards?: string, plan?: string };
}

const MAXIMUM = 5000;

const generators = {
    "Grid Addition": generateAddition,
    "Grid Subtraction": generateSubtraction,
    "Grid Multiplication": generateMultiplication,
    "Grid Division": generateDivision,
    "NL Percent": generatePercent,
    "NL Addition": generateNLAddition,
    "NL Subtraction": generateNLSubtraction,
    "NL Multiplication": generateNLMultiplication,
    "NL Division": generateNLDivision,
    "NL Inequality": generateInequality
};

const Generate: React.FC = () => {
    const [selectedGenerator, setSelectedGenerator] = useState<string>("NL Inequality");

    const saveToJsonFile = (problems) => {
        const jsonContent = JSON.stringify({ "PROBLEMS": problems }, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        // link.download = `generate.json`;
        link.download = `generate_${selectedGenerator.toLowerCase()}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        URL.revokeObjectURL(url);
    };

    const onGenClick = () => {
        const generatorFunction = generators[selectedGenerator];
        if (generatorFunction) {
          const problems = generatorFunction(MAXIMUM);
          console.log(problems);
          saveToJsonFile(problems);
        } else {
          console.error("No generator function found for:", selectedGenerator);
        }
    };

    return (
        <DebugTop>
        <select value={selectedGenerator} onChange={(e) => setSelectedGenerator(e.target.value)}>
          {Object.keys(generators).map((genName) => (
            <option key={genName} value={genName}>
              {genName}
            </option>
          ))}
        </select>
        <Button size={'medium'} text={'Generate'} color={'black'} onClick={onGenClick} disabled={false} />
      </DebugTop>
    );
};

export default Generate;

const DebugTop = styled.div`
  position: fixed;
  top: 10px;
  left: 50px;
  display: flex;
  gap: 10px;
  padding: 10px;
  z-index: 1000;
`;