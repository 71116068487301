// Accordion.tsx
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import Fonts from '../components/common/Fonts';
import Colors from '../components/common/Colors';

const StyledAccordion = styled(MuiAccordion)<{$size: number}>`
  background-color: ${Colors.background};
  color: ${Colors.grey8};
  font-family: ${Fonts.quicksandMedium.fontFamily}, sans-serif !important;
  font-weight: ${Fonts.quicksandMedium.fontWeight} !important;
  font-size: ${props => props.$size + 'px'} !important;
  box-shadow: none;
  padding: 0 !important; // Remove default padding

  .MuiTypography-root {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
  
  &::before {
    display: none; // Remove the default top border
    /* border-top: 1px solid ${Colors.grey1}; */
  }

  &.Mui-expanded {
    margin: 0 !important;
  }
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  background-color: ${Colors.transparent};
  border-radius: 6px;
  min-height: 48px !important;
  padding: 0px 8px;
  width: 100%;
  &.Mui-expanded {
    min-height: 48px !important; // Maintain the same height when expanded
  }
  & .MuiAccordionSummary-content {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  &:hover {
    background-color: ${Colors.white};
  }
`;

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  background-color: ${Colors.transparent};
  padding: 6px 6px;
`;

const TitleWrapper = styled.div`
  
`;

const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContentWrapper = styled.div`
  
`;



interface AccordionProps {
  items: { title: string; content: React.ReactNode; open?: boolean }[];
  size?: number;
}

const Accordion: React.FC<AccordionProps> = ({ items, size = 18 }) => {  
  return (
    <div>
      {items.map((item, index) => (
        <StyledAccordion key={index} defaultExpanded={item.open} $size={size}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`} >
            <TitleWrapper>{item.title.length > 24 ? item.title.slice(0, 24) + '...' : item.title}</TitleWrapper>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <ContentWrapper>{item.content}</ContentWrapper>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export default Accordion;

