import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../components/common/Colors";
import Fonts from "../components/common/Fonts";
import Document from "../components/basic/Document";
import Button from "../components/basic/Button";
import Icon from "../components/common/Icon";
import ButtonLink from "../components/basic/ButtonLink";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${Colors.white};
  color: ${Colors.black};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-size: 36px;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.white};
  gap: 30px;
  padding: 160px 100px;
  z-index: 10;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 60px 0px 0px 0px;
  z-index: 100;
`;

const InputBG = styled.div` 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 700px;
  background-color: ${Colors.websiteYellow};
  border-radius: 40px;
  gap: 10px;
  padding: 10px;
`;

const HowSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding: 100px;
  z-index: 10;
`;

const MeetInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0px;
  padding-bottom: 60px;
`;

const ExampleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: ${Colors.background};
  gap: 20px;
  padding: 160px 100px;
  z-index: 10;
`;

const CTASection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.websiteYellow};
  gap: 30px;
  padding: 160px 100px;
  z-index: 10;
`;

const CTAInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  gap: 30px;
`;

const SideBySideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
  padding: 60px 0px;
`;

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 120px;
  font-weight: ${Fonts.quicksandBold.fontWeight};
  font-family: ${Fonts.quicksandBold.fontFamily};
  color: ${Colors.black};
  line-height: 1em;
  text-align: center;
  text-wrap: wrap;
  margin: 0;
  z-index: 10;
`;

const H2 = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  font-weight: ${Fonts.quicksandBold.fontWeight};
  font-family: ${Fonts.quicksandBold.fontFamily};
  color: ${Colors.black};
  line-height: 1.1em;
  text-align: center;
  text-wrap: wrap;
  margin: 0;
  z-index: 10;
`;

const H6 = styled.h6<{ $weight }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: ${({ $weight }) => $weight};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  color: ${Colors.black};
  line-height: 1.4em;
  text-align: left;
  text-wrap: wrap;
  margin: 0;
  z-index: 10;
`;

const P = styled.p<{ $padding, $size, $weight }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ $size }) => $size};
  font-weight: ${({ $weight }) => $weight};
  font-family: ${Fonts.quicksandLight.fontFamily};
  color: ${Colors.black};
  line-height: 1.4em;
  text-align: left;
  text-wrap: wrap;
  margin: 0;
  padding: ${({ $padding }) => $padding};
  z-index: 10;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Website: FunctionComponent = () => {
  return (
    <Root>
      <TopBar />
      <ContentContainer>
        <HeroSection>
          <H6 $weight={Fonts.quicksandSemiLight.fontWeight}>Hi there! 👋</H6>
          <H1>Need math help?</H1>
            <InputContainer>       
              <InputBG>
                <Document placeholder={"Type a math problem... "} value={''} multiline={false} onChange={()=>{}} />
                <div style={{ width: '52px' }}>
                <Button size={'medium'} icon={'send-right'} color={'black'} onClick={()=>{}} disabled={false} />
                </div>
              </InputBG>
              <P $padding={'0px 0px'} $size={'24px'} $weight={Fonts.quicksandLight.fontWeight}>Explorable visualizations that make concepts click 🎯</P>
            </InputContainer> 
        </HeroSection>
        <HowSection>
          <MeetInner>
          <img src={'/ui-assets/grid-background.svg'} style={{ position: 'absolute', top: '738px', left: '0', width: '100%', height: 'auto', opacity: 0.5, zIndex: 0 }}/>
            <Row>
              <H6 $weight={Fonts.quicksandSemiLight.fontWeight}>MEET:</H6>
              {/* <H6 $weight={Fonts.quicksandLight.fontWeight}>Make math make sense.</H6> */}
            </Row>
            <img src={'/ui-assets/seesolve-text-red.svg'} style={{ width: '100%', height: 'auto', zIndex: 10 }}/>
            <Right>
              <H6 $weight={Fonts.quicksandSemiLight.fontWeight}>Make math make sense.</H6>
            </Right>
          </MeetInner>
          <SideBySideContainer>
            <SideBySide title={'SCAN IT'} icon={'camera'} body={['Input any math problem.']} image={''}/>
            <SideBySide title={'SEE IT'} icon={'eye'} body={['Visualize complex concepts.', 'Explore interactively.', 'Understand deeply.']} image={''}/>
            <SideBySide title={'SOLVE IT'} icon={'lightbulb'} body={['Solve step-by-step.', 'Check answers.', 'Turn in homework with confidence.']} image={''}/>
            <SideBySide title={'ACE IT'} icon={'logo'} body={['Get unstuck.', 'Learn faster.', 'Get better grades.']} image={''}/>
          </SideBySideContainer>
        </HowSection>
        <ExampleSection>
          <SideBySideContainer>
              <SideBySide title={'TRY AN EXAMPLE'} icon={null} body={['Arithmetic', 'Place Value', 'Fractions', 'Bar Graphs', 'Line Graphs', 'Inequalities', 'Percents', 'Ratios', 'Alegbra', 'and more!']} 
              psize={18} image={''}/>
          </SideBySideContainer>
        </ExampleSection>
        <CTASection>
          <CTAInner>
            <H2>Ready to 🚀 level up your learning?</H2>
            <P $padding={'0px 0px'} $size={'32px'} $weight={Fonts.quicksandSemiLight.fontWeight}>⏱️ Save time. 🧠 Study smarter.</P>
            <Button size={'large'} text={'Start Solving'} color={'black'} onClick={()=>{}} disabled={false} />
          </CTAInner>
        </CTASection>
      </ContentContainer>
      <BottomBar />
    </Root>
  );
};

export default Website;

// ------------------------------------------------------------

const SideBySideItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: flex-start;
  align-items: center;
  width: 100%;
  height: 400px;
  gap: 40px;
  z-index: 10;
`;

const Side = styled.div<{ $margin: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  // margin: ${({ $margin }) => $margin};
  margin: 0;
`;

interface SideBySideProps {
  title: string;
  icon: string;
  body: string[];
  psize?: number;
  image: string;
}

const SideBySide: FunctionComponent<SideBySideProps> = ({ title, icon, body, psize, image }) => {
  return (
    <SideBySideItem>
      <Side $margin={'20px 0px'}>
        <Icon icon={icon} size={32} color={'black'}/>
        <H6 $weight={Fonts.quicksandBold.fontWeight}>{title}</H6>
        {body.map((text, index) => (
            <P $padding={'0px 0px'} $size={psize ? psize + 'px' : '24px'} $weight={Fonts.quicksandSemiLight.fontWeight} key={index}>{text}</P>
        ))}
      </Side>
      <Side $margin={'0'}>
        {image && <img src={image} style={{ width: '100%', height: 'auto' }}/>}
        {!image && <div style={{ width: '600px', height: '400px', backgroundColor: Colors.grayPale, borderRadius: '10px' }}/>}
      </Side>
    </SideBySideItem>
  );
};

// ------------------------------------------------------------

const TopBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${Colors.white};
  font-size: 24px;
  gap: 30px;
  padding: 10px;
  z-index: 20;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const BottomBarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.black};
  padding: 20px 10px;
  box-sizing: border-box;
  z-index: 20;
`;

const TopBar: FunctionComponent = () => {
  return (
    <TopBarContainer>
        <Left>
          <Icon icon={'logo'} size={24} color={'black'}/>
          <img src={'/ui-assets/seesolve-text.svg'} style={{ width: 'auto', height: '24px' }}/>
        </Left>
        <Right>
            <Button size={'small'} text={'Start Solving'} color={'black'} onClick={()=>{}} disabled={false} />
        </Right>
    </TopBarContainer>
  );
};

const BottomBar: FunctionComponent = () => {
  return (
    <BottomBarContainer>
      <Left>
        <Icon icon={'logo'} size={24} color={'white'}/>
        <img src={'/ui-assets/seesolve-text.svg'} style={{ width: 'auto', height: '24px', filter: 'invert(1)' }}/>
      </Left>
      <Right>
        <ButtonLink text={'Terms of Service'} color={'white'} onClick={()=>{}}/>
        <ButtonLink text={'Privacy Policy'} color={'white'} onClick={()=>{}}/>
        {/* <Button size={'small'} icon={'x'} color={'ghost white'} onClick={()=>{}} disabled={false} /> */}
      </Right>
    </BottomBarContainer>
  );
};