// PrivacyPolicy.tsx
import { FunctionComponent } from "react";
import ContentPage from "./components/ContentPage";

const PrivacyPolicy: FunctionComponent = () => {
  const title = "Privacy Policy";
  const subtitle = "Last updated August 26, 2024";
  const content = [
    {
      header: "Introduction",
      body: [
        "SeeSolve is your personal math tutor that turns any problem into an interactive visualization that helps you solve it. To help deliver our exceptional Service most effectively, we gather information about our users, their content and preferences.",
        "When you interact with SeeSolve, you trust us your information (“Personal Data”). We want to keep that trust by being transparent about our privacy practices regarding the collection, use, storage and sharing of your Personal Data. We also want you to understand the choices you have regarding your Personal Data.",
        "We encourage you to Contact Us if you have any questions or concerns."
      ],
    },
    {
      header: "Scope of This Privacy Policy",
      body: [
        "This Privacy Policy covers the Personal Data SeeSolve collects from users when you access our web pages located at https://seesolve.ai, our mobile application SeeSolve, use our content and systems, share any information with us, or communicate with us (together or individually, the “Service”).",
        "Each individual that accesses or uses the platform is a “User” and may be required to have an account with us. Any User of our Service must be at least 18 years of age, a resident of the United States and its territories and possessions, and agree to this Privacy Policy and our Terms of Use.",
        "We may collect, store and process Personal Data about Users to facilitate the provision of our Service. You should read this Privacy Policy carefully. By using the Service or submitting your Personal Data, you agree to these terms and you consent to the processing of your Personal Data in accordance with this Privacy Policy."
      ],
    },
    {
        header: "Your Rights and Preferences",
        body: [
          "Privacy and data protection laws vary across different geographic regions and give individuals certain rights in relation to their Personal Data. SeeSolve is based in and operates its Service from the United States. SeeSolve allows you to exercise your privacy rights and manage how we use your Personal Data. We encourage you to contact us to learn more about your privacy rights and how we collect and use your Personal Data."
        ],
    },
    {
        header: "What Information We Collect and How We Use It",
        body: [
          "Information you provide. We may collect Personal Data that you provide when you register for and use our Service, including your name, email or other contact information, and business information. We also may collect any correspondence we have if you contact us. We use this Personal Data to authenticate your access to the Service, to deliver, improve or personalize the Service, and for our internal operations, such as processing, maintaining your account, communicating with you, and for legal or regulatory purposes.",
            "• Email addresses. We may use your email address to send you service-related emails, like confirmations or customer service notices. You cannot opt out of these emails. We may also send you newsletters and information about our Service or those of our partners. You can opt out of these promotional emails at any time by clicking on the unsubscribe link in the email.",
            "• Content. We may collect content you create while using the Service, such as problems and answers. We will maintain, use and process the problems and answers to improve our Service.",
            "Automatic Information Collection. When you access or use our Service, we may automatically collect some usage information. For instance, we use cookies and other tracking technologies to automatically collect technical information, such as URL, cookie data, IP address, device type, unique device IDs, device attributes, network connection type (e.g., WiFi, 3G, LTE, Bluetooth) and provider, network and device performance, browser type, language, operating system, and other functional information.",
            "Analytics. We may use analytics tools to better understand how the Service is used and how we may improve or enhance it. We may also gather non-personally identifiable data about download and usage patterns. We may use the general geographic area of our Users, but this information is not linked to a particular person. These tools may gather information about the type of computer or browser you use, other websites you visited, how you used our Service, and the length of time that you use our Service. We use this information solely to maintain and improve the user experience with our Service."
        ],
    },
    {
        header: "How You Can Access, Change, or Delete Personal Data",
        body: [
          "Users can review and edit certain account information via the Account Settings page in the Service.  If you would like to change any other account information, or would like to delete your account, please contact us at support@seesolve.ai. Please remember that if we delete your account, you will lose access to all content associated with your account. We may still store User Personal Data in our records as required under our information retention policies or for internal business, legal or regulatory purposes.",
          <strong>How We Share User Personal Data</strong>,
          "We will not sell your Personal Data to third parties without your consent.  We may disclose or share your Personal Data with authorized third parties as described elsewhere in this Privacy Policy, including:",
            "• With vendors, consultants or service providers who need access to such information to carry out work on our behalf, including cloud storage firms, application service providers, and website performance analytics providers.",
            "• To enforce the terms of this Privacy Policy or our Terms of Service, or to protect the rights, property or safety of SeeSolve or its users.",
            "• In connection with a merger, sale of assets, bankruptcy proceedings, financing or acquisition of a part or all of our business to another entity, or in anticipation thereof.",
            "• To law enforcement, governmental entities or regulatory organizations to aid an ongoing investigation or in response to a valid legal request or judicial or regulatory process."
        ],
    },
    {
        header: "Connecting With Third Parties",
        body: [
          "In your interactions with SeeSolve and our Service, it may be necessary for us to sync, link or connect your Personal Data with authorized third parties, including providing Personal Data necessary to identify or authenticate your requested interactions. Your consent for these interactions will be required by us, by the third party or by both. We encourage you to review the privacy practices of any third party with which you choose to share your Personal Data.  SeeSolve is not responsible for the privacy practices of third parties."
        ],
    },
    {
        header: "Managing Your Data and Privacy",
        body: [
          "You may review and change your information, deactivate your account with us, manage the use of information that identifies you, decide what marketing communications you receive, and understand our use of cookies. We may retain certain information, including Personal Data, and you may still receive other non-promotional messages from us.",
          <strong>Managing Marketing Communications From Us</strong>,
          "SeeSolve will honor your choices when it comes to receiving marketing communications from us. If you have received marketing communications from us that you no longer wish to receive, you may:",
            "• Contact us or adjust your customer account settings to manage your preferences regarding your Personal Data and your interactions with SeeSolve.",
            '• If you have received electronic promotional communications from us and no longer wish to receive them, click the "unsubscribe" link in the promotional communication you received.',
            '• For SMS messages, reply "STOP" or follow the instructions in the message or settings to discontinue the communications.',
            '• If you are receiving push notifications from us to your mobile device and no longer wish to receive these types of communications, you may turn them off at the device level.',
            '• Remember that even if you choose not to receive marketing communications from us, we will continue to send you mandatory service or transactional communications, or communications related to the safe use of our Service.',
            '• You may Contact Us if you wish to adjust your Personal Data preferences and your interactions with us.',
          <strong>Cookies and Similar Tracking Technologies</strong>,
          "We use cookies or other similar tracking technologies (“Cookies”) in delivering our Service.  Cookies are small text files that we may save to your browser or device. We use cookies to enhance your use of the Service, such as by storing an encrypted user ID to authenticate you when you login to the Service or by using persistent Cookies to store your username and password, so you can more easily login each time. Persistent Cookies may remain on your computer after you access or use our Service and we use those to ensure that our Service functions properly. We do not use persistent Cookies to track users across different websites or for advertising or profile building purposes. By using the Service, you consent to the use of Cookies and other tracking technologies as described in this Privacy Policy.",
          <strong>Do Not Track</strong>,
          'Like most other companies, SeeSolve is not currently configured to respond to browser "Do Not Track" signals because, at this time, no formal "Do Not Track" standard has been adopted.'
        ],
    },
    {
        header: "Your Data Access Rights",
        body: [
          "You may have the right to exercise certain data protection rights regarding your Personal Data. SeeSolve will not discriminate against you for exercising your privacy rights.",
          <strong>Data Retention</strong>,
          "SeeSolve retains and uses your Personal Data in accordance with and as permitted by applicable law and regulations:",
            "• We will retain your information as long as necessary to serve you, to maintain your account for as long as your account is active, or as otherwise needed to operate our business.",
            "• If you decide not to receive promotional communications from SeeSolve, your opt out will not apply to information provided by us as a result of a purchase or use of our Service, Service experience or other transactions.",
            "• We may also continue to use some of your information for our legitimate business purposes and to improve our Service or to develop new ones.",
            '• We will retain and use your information as required by applicable regulations and our records and information management practices, comply with our legal and reporting obligations, resolve disputes, enforce our agreements, complete any outstanding transactions, and for the detection and prevention of fraud.',
          <strong>Responses from SeeSolve</strong>,
          "We respond to all requests from individuals regarding the exercise of their data protection rights in accordance with applicable data protection laws. While your data protection rights may vary depending on your location, they generally include:",
            "• Accessing, correcting, updating, or requesting deletion of your information.",
            "• Objecting to or asking us to restrict the processing of your information.",
            "• Requesting the portability of your information and providing the same in a timely manner and at no charge.",
            "• Electing whether or not to receive marketing communications from us.",
            "• Withdrawing your consent at any time for us to collect, process, store, or share your information (noting that such withdrawal will not affect the lawfulness of any processing that we conducted prior to your withdrawal, nor will it affect otherwise lawful processing or storage).",
            "We will respond to properly submitted and verified requests for access, disclosure, deletion, correction, portability or objection regarding your information, including Personal Data, within thirty (30) days.",
            "Please contact us for more information or to exercise a request regarding your data privacy rights. If you are concerned about our compliance with applicable data privacy laws and your Personal Data, you may also contact the Attorney General’s Office for the state in which you reside.",
          <strong>Data Controller</strong>,
          "SeeSolve is considered to be a “controller” of your information under data privacy regulations. Other entities, such as a company with which you are affiliated, may also act as controllers of your information and you should contact them if you have any questions about how they use your information."
        ],
    },
    {
        header: "Security of Your Information",
        body: [
            "We use reasonable physical, administrative, and technical measures to protect your Personal Data from unauthorized use or access. We transmit data using a secure socket layer (SSL) and encrypt all information that is stored in cookies. We use reasonable security measures and features in an effort to prevent unauthorized access to your information, including Personal Data, and seek out vendors who do the same. The safety and security of your Personal Data also depends on you. Where we have given you (or where you have chosen) a password for access to our Service, you are responsible for keeping this password confidential. You should not share your password with anyone. We urge you to be careful about giving out information in public areas, like message boards or social media.",
            "Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Data, we cannot guarantee its security during transmission to us. Any transmission of your information to us is at your own risk. SeeSolve is not responsible for circumvention of any privacy settings or security measures."
        ],
    },
    {
        header: "Changes to this Privacy Policy",
        body: [
          "We may revise and update this Privacy Policy at any time and for any reason at our sole discretion. We will provide notice of the most recent version of this Privacy Policy by updating the “Last Updated” date above and you waive any right to receive specific notice otherwise of changes. All changes are effective immediately upon posting and apply to all access to and use of the Service thereafter. If you don’t like any changes to this Privacy Policy, you may stop using the Service.  Your continued access to and use of the Service signifies your acceptance of the revised and then-current Privacy Policy."
        ],
    },
    {
        header: "Contact Us",
        body: [
            "If you have any questions or concerns about this Privacy Policy, our privacy practices, or your choices regarding your Personal Data and information, please contact us at the following:",
            "A. Via Email: support@seesolve.ai is the fastest and most-preferred way to reach us with your data management or privacy-related questions and concerns.",
            "B. Via Mail: SeeSolve: 19 Acadia Park, Unit 3, Somerville, MA 02143",
            "C. Via Phone: (617) 792 9644"
        ],
    }
  ];

  return <ContentPage title={title} subtitle={subtitle} content={content} />;
};

export default PrivacyPolicy;
