// tokenStorage.js
export const tokenStorage = {
    getToken: () => localStorage.getItem('token'),
    setToken: (token) => {
        localStorage.setItem('token', token);
        localStorage.setItem('hasEverLoggedIn', 'true');
    },
    clearToken: () => localStorage.removeItem('token'),

    getComputedProblemIds: () => {
        const ids = localStorage.getItem('computedProblemIds');
        return ids ? JSON.parse(ids) : [];
    },
    setComputedProblemIds: (ids) => localStorage.setItem('computedProblemIds', JSON.stringify(ids)),
    clearComputedProblemIds: () => localStorage.removeItem('computedProblemIds'),

    hasEverLoggedIn: () => localStorage.getItem('hasEverLoggedIn') === 'true',
    setHasEverLoggedIn: () => localStorage.setItem('hasEverLoggedIn', 'true'),
};

// localStorage.removeItem('computedProblemIds')
// localStorage.removeItem('hasEverLoggedIn')