// TableOLD.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import Fonts from '../common/Fonts';
import { useHover } from '../HoverContext';

export interface TablePropsOLD {
  data: any[][];
  columnNames?: string[];
  rowNames?: string[];
  dataColors?: string[][];
  columnColors?: string[];
  rowColors?: string[];
  onStateChange?: (state: TablePropsOLD) => void;
}

const StyledTable = styled.table`
  border-collapse: collapse;
  text-align: center;
  height: 100%;
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-size: 18px;
  display: block;
  overflow-x: auto;

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px 16px;
    min-width: 60px;
    font-family: ${Fonts.quicksandMedium.fontFamily};
    font-weight: ${Fonts.quicksandMedium.fontWeight};
    white-space: nowrap;
  }

  th {
    background-color: #f2f2f2;
    vertical-align: bottom;
    font-family: ${Fonts.quicksandBold.fontFamily};
    font-weight: ${Fonts.quicksandBold.fontWeight};
  }

  th.row-header {
    background-color: white;
  }

  @media (max-width: 600px) {
    th,
    td {
      padding: 8px 10px;
      font-size: 16px;
    }
  }
`;

const TableOLD: React.FC<TablePropsOLD> = ({ data, columnNames, rowNames, dataColors, columnColors, rowColors, onStateChange }) => {
  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [highlightedCell, setHighlightedCell] = useState<{ row: number; col: number } | null>(null);
  const [tableState, setTableState] = useState<TablePropsOLD>({
    data,
    columnNames,
    rowNames,
    dataColors,
    columnColors,
    rowColors,
  });

  const updateState = (newState: Partial<TablePropsOLD>) => {
    const updatedState = { ...tableState, ...newState };
    setTableState(updatedState);
    if (onStateChange) {
      onStateChange(updatedState);
    }
  };

  const handleCellClick = (row: number, col: number, color: string | undefined) => {
    if (highlightedCell) {
      handleMouseLeave();
      setHighlightedCell(null);
    }
    // if (!highlightedCell || highlightedCell.row !== row || highlightedCell.col !== col)
    else {
      handleMouseEnter(color);
      setHighlightedCell({ row, col });
    }
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          {columnNames && columnNames.map((columnName, index) => (
            <th key={index}>
              <span
                style={{ color: columnColors ? columnColors[index] : undefined }}
                onClick={() => handleCellClick(-1, index, columnColors ? columnColors[index] : undefined)}
              >
                {columnName}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {rowNames && (
              <th className="row-header">
                <span
                  style={{ color: rowColors ? rowColors[rowIndex] : undefined }}
                  onClick={() => handleCellClick(rowIndex, -1, rowColors ? rowColors[rowIndex] : undefined)}
                >
                  {rowNames[rowIndex]}
                </span>
              </th>
            )}
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>
                <span
                  style={{ color: dataColors && dataColors[rowIndex] ? dataColors[rowIndex][cellIndex] : undefined }}
                  onClick={() => handleCellClick(rowIndex, cellIndex, dataColors && dataColors[rowIndex] ? dataColors[rowIndex][cellIndex] : undefined)}
                >
                  {cell}
                </span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default TableOLD;
