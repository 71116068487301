// useDebug.js
import { gql, useMutation } from '@apollo/client';

const SUBMIT_DEBUG_FEEDBACK_MUTATION = gql`
  mutation submitDebugFeedback($problemId: ID!, $debugFeedback: String!) {
    submitDebugFeedback(problemId: $problemId, debugFeedback: $debugFeedback)
  }
`;

export function useSubmitDebugFeedback() {
  const [submitDebugFeedback, { data, loading, error }] = useMutation(SUBMIT_DEBUG_FEEDBACK_MUTATION);

  const submitDebugById = async (problemId, debugFeedback) => {
    if (problemId && debugFeedback) {
      const response = await submitDebugFeedback({
        variables: { problemId, debugFeedback },
      });
      if (response && response.data && response.data.submitDebugFeedback) {
        return response.data.submitDebugFeedback;
      }
    }
    return Promise.resolve(null);
  };

  return { data, loading, error, submitDebugById };
}