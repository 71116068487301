import React, { useRef, useEffect, useState } from "react";
import * as d3 from 'd3';
import Fonts from "../common/Fonts";
import Colors from "../common/Colors";
import { wrapText } from "../../utils/beautifyText";
import Dragger from './interactives/Dragger';
import Stepper from './interactives/Stepper';
import { convertAllTypes } from '../../utils/convertTypes';

export interface AreaModelProps {
    rowValue?: number;
    rowTotal?: number;
    columnValue?: number;
    columnTotal?: number;

    rowLabel?: string;
    columnLabel?: string;
    totalLabel?: string;

    rowColor?: string;
    columnColor?: string;
    totalColor?: string;

    draggable?: 'row' | 'column' | 'both' | null;
    steppable?: 'row' | 'column' | 'both' | null;
    tutorial?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | null;

    actualScale?: number;
    onStateChange?: (newState: AreaModelProps, skipLogic?: string | string[]) => void;
}

const areaModelPropsTypeDefinition: AreaModelProps = {
    rowValue: 0,
    rowTotal: 0, 
    columnValue: 0,
    columnTotal: 0,
    rowLabel: '',
    columnLabel: '',
    totalLabel: '',
    rowColor: '',
    columnColor: '',
    totalColor: '',
    actualScale: 1,
    onStateChange: () => {}
}

const AreaModel: React.FC<AreaModelProps> = (props) => {
    props = convertAllTypes(props, areaModelPropsTypeDefinition);
    let { rowValue = 0, rowTotal = 1, columnValue = 0, columnTotal = 1, rowLabel = "", columnLabel = "", totalLabel = "", 
        rowColor = "", columnColor = "", totalColor = Colors.vizDefault, draggable = null, steppable = null, tutorial = null, 
        actualScale = 1, onStateChange } = props;

    const MIN_SCALE = 0.37;
    if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;

    const svgRef = useRef<SVGSVGElement | null>(null);
    const [hasInteracted, setHasInteracted] = useState(false);

    const updateState = (newRowValue?: number, newColumnValue?: number, newRowTotal?: number, newColumnTotal?: number, skipLogic?: string | string[]) => {
        rowValue = newRowValue !== undefined ? newRowValue : rowValue;
        columnValue = newColumnValue !== undefined ? newColumnValue : columnValue; 
        rowTotal = newRowTotal !== undefined ? newRowTotal : rowTotal;
        columnTotal = newColumnTotal !== undefined ? newColumnTotal : columnTotal;
        if (skipLogic) setHasInteracted(true);

        if (onStateChange) {
            onStateChange({
                ...props,
                rowValue: newRowValue !== undefined ? newRowValue : rowValue,
                columnValue: newColumnValue !== undefined ? newColumnValue : columnValue,
                rowTotal: newRowTotal !== undefined ? newRowTotal : rowTotal,
                columnTotal: newColumnTotal !== undefined ? newColumnTotal : columnTotal,
            }, skipLogic);
        }
    };

    const render = () => {
        if (!svgRef.current) return;

        const width = 260;
        const height = 260;
        const padding = {
            left: 80 / actualScale,
            right: 80 / actualScale,
            top: 80 / actualScale,
            bottom: 80 / actualScale,
        };
        const outerStrokeWidth = 3;
        const cornerRadius = 2;

        const svg = d3.select(svgRef.current)
            .attr('viewBox', `0 0 ${width + padding.left + padding.right} ${height + padding.top + padding.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('width', '100%')
            .style('height', '100%');

        svg.selectAll('*').remove();

        const gridWidth = width / columnTotal;
        const gridHeight = height / rowTotal;

        const rowOpacity = rowValue && !columnValue ? 1.0 : 0.5;
        const columnOpacity = columnValue && !rowValue ? 1.0 : 0.5;

        // Fill rows
        if (rowValue) {
            const rows = svg.append('rect')
                .attr('x', padding.left)
                .attr('y', padding.top)
                .attr('width', width)
                .attr('height', rowValue * gridHeight)
                .attr('fill', rowColor || Colors.vizDefault)
                .attr('fill-opacity', rowOpacity)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
        }

        // Fill columns
        if (columnValue) {
            const columns = svg.append('rect')
                .attr('x', padding.left)
                .attr('y', padding.top)
                .attr('width', columnValue * gridWidth)
                .attr('height', height)
                .attr('fill', columnColor || Colors.vizDefault)
                .attr('fill-opacity', columnOpacity)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
        }

        // Fill overlapping area
        if (rowValue && columnValue) {
            const overlap = svg.append('rect')
                .attr('x', padding.left)
                .attr('y', padding.top)
                .attr('width', columnValue * gridWidth)
                .attr('height', rowValue * gridHeight)
                .attr('fill', totalColor)
                .attr('fill-opacity', 1.0)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
        }

        // Grid lines
        for (let i = 0; i <= rowTotal; i++) {
            svg.append('line')
                .attr('x1', padding.left)
                .attr('y1', padding.top + i * gridHeight)
                .attr('x2', padding.left + width)
                .attr('y2', padding.top + i * gridHeight)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
        }
        for (let i = 0; i <= columnTotal; i++) {
            svg.append('line')
                .attr('x1', padding.left + i * gridWidth)
                .attr('y1', padding.top)
                .attr('x2', padding.left + i * gridWidth)
                .attr('y2', padding.top + height)
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
        }

        // Outer rectangle
        svg.append('rect')
            .attr('x', padding.left)
            .attr('y', padding.top)
            .attr('width', width)
            .attr('height', height)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', outerStrokeWidth)
            .attr('rx', cornerRadius)
            .attr('ry', cornerRadius);
  
        // Add draggers if draggable
        if (draggable === 'column') {
            const columnDraggerX = padding.left + (columnValue * gridWidth);
            const columnDraggerY = padding.top + (height / 2);

            Dragger({
                svgRef: svgRef,
                id: 'area-model-column-dragger',
                tutorial: hasInteracted ? null : tutorial!,
                // color: columnColor || Colors.vizDefault,
                color: 'black',
                direction: 'right',
                x: columnDraggerX,
                y: columnDraggerY,
                minPos: padding.left,
                maxPos: padding.left + width,
                value: columnValue,
                minVal: 0,
                maxVal: columnTotal,
                tick: 1,
                decimalPrecision: 0,
                handleDrag: (newValue) => {updateState(undefined, newValue, undefined, undefined, 'columnValue')}
            });
        }

        if (draggable === 'row') {
            const rowDraggerX = padding.left + (width / 2);
            const rowDraggerY = padding.top + (rowValue * gridHeight);

            Dragger({
                svgRef: svgRef,
                id: 'area-model-row-dragger',
                tutorial: hasInteracted ? null : tutorial!,
                // color: rowColor || Colors.vizDefault,
                color: 'black',
                direction: 'down',
                x: rowDraggerX,
                y: rowDraggerY,
                minPos: padding.top,
                maxPos: padding.top + height,
                value: rowValue,
                minVal: 0,
                maxVal: rowTotal,
                tick: 1,
                decimalPrecision: 0,
                actualScale,
                handleDrag: (newValue) => {updateState(newValue, undefined, undefined, undefined, 'rowValue')}
            });
        }

        if (draggable === 'both') {
            const bothDraggerX = padding.left + (columnValue * gridWidth);
            const bothDraggerY = padding.top + (rowValue * gridHeight);

            Dragger({
                svgRef: svgRef,
                id: 'area-model-both-dragger',
                tutorial: hasInteracted ? null : tutorial!,
                // color: totalColor || Colors.vizDefault
                color: 'black',
                direction: 'both',
                x: bothDraggerX,
                y: bothDraggerY,
                minPos: { x: padding.left, y: padding.top },
                maxPos: { x: padding.left + width, y: padding.top + height },
                value: { x: columnValue,  y: rowValue },
                minVal: { x: 0, y: 0 },
                maxVal: { x: columnTotal, y: rowTotal },
                tick: 1,
                decimalPrecision: 0,
                actualScale,
                handleDrag: (newColumnValue, newRowValue) => {
                    updateState(newRowValue, newColumnValue, undefined, undefined, ['row', 'column']);
                }
            });
        }

        // Add steppers if steppable
        if (steppable === 'column' || steppable === 'both') {
            const columnStepperX = padding.left + width - width/6;
            const columnStepperY = padding.top - padding.top/2;

            Stepper({
                svgRef: svgRef.current!,
                id: 'area-model-column-stepper', 
                x: columnStepperX,
                y: columnStepperY,

                value: columnTotal,
                min: Math.max(columnValue, 1),
                max: 20,
                tick: 1,
                decimalPrecision: 0,
                showLabel: false,
                direction: 'horizontal',

                actualScale,
                onClick: (newValue) => updateState(undefined, undefined, undefined, newValue, 'columnTotal')
            });
        }

        if (steppable === 'row' || steppable === 'both') {
            const rowStepperX = padding.left/2;
            const rowStepperY = padding.top + height - height/5;

            Stepper({
                svgRef: svgRef.current!,
                id: 'area-model-row-stepper',
                x: rowStepperX,
                y: rowStepperY,

                value: rowTotal,
                min: Math.max(rowValue, 1),
                max: 20,
                tick: 1,
                decimalPrecision: 0,
                showLabel: false,
                direction: 'vertical',

                actualScale,
                onClick: (newValue) => updateState(undefined, undefined, newValue, undefined, 'rowTotal')
            });
        }

        const fontSize = 30 / actualScale;
        const fontSizeSmall = 24 / actualScale;

        // Column labels
        if (columnLabel) {
            svg.append('text')
                .attr('x', padding.left + (0.08 * gridWidth))
                // .attr('x', padding.left + 0.14 * width)
                .attr('y', padding.top*0.67)
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'text-top')
                .attr('fill', columnColor ? columnColor : 'black')
                .text(columnLabel)
                .style('font-family', Fonts.quicksandMedium.fontFamily)
                .style('font-weight', Fonts.quicksandMedium.fontWeight)
                .style('font-size', `${columnLabel.length > 5 ? fontSizeSmall : fontSize}px`)
        }
        
        // Row labels
        if (rowLabel) {
            const rowLabelText = svg.append('text')
                .attr('x', padding.left/2)
                .attr('y', padding.top + (0.6 * gridHeight))
                // .attr('y', padding.top + (0.12 * height))
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'text-top')
                .attr('fill', rowColor ? rowColor : 'black')
                .text(rowLabel)
                .style('font-family', Fonts.quicksandMedium.fontFamily)
                .style('font-weight', Fonts.quicksandMedium.fontWeight)
                .style('font-size', `${rowLabel.length > 5 ? fontSizeSmall : fontSize}px`)
            wrapText(rowLabelText, padding.left);
            // console.log('row label', rowLabel, rowLabelText.text());
        }

        // Multiplication Label
        // if (rowLabel && columnLabel) {
        //     svg.append('text')
        //         .attr('x', padding.left*0.75)
        //         .attr('y', padding.top*0.75)
        //         .attr('text-anchor', 'middle')
        //         .attr('alignment-baseline', 'middle')
        //         .attr('fill', Colors.grey6)
        //         .text('×')
        //         .style('font-family', Fonts.quicksandLight.fontFamily)
        //         .style('font-weight', Fonts.quicksandLight.fontWeight)
        //         .style('font-size', `${fontSize}px`)
        //         .style('line-height', '1.4em');
        // }

        // Total label
        if (totalLabel) {
            svg.append('text')
                .attr('x', padding.left + width) 
                .attr('y', padding.top + height + padding.bottom/2) 
                .attr('text-anchor', 'end') // Align text to the end (right)
                .attr('alignment-baseline', 'middle')
                .attr('fill', totalColor ? totalColor : 'black')
                .text(totalLabel)
                .style('font-family', Fonts.quicksandMedium.fontFamily)
                .style('font-weight', Fonts.quicksandMedium.fontWeight)
                .style('font-size', `${fontSize}px`)
                .style('line-height', '1.4em');
        }
    };

    useEffect(() => {
        render();
    }, [rowValue, rowTotal, columnValue, columnTotal, rowColor, columnColor, totalColor, rowLabel, columnLabel, totalLabel, actualScale]);

    return <svg ref={svgRef}></svg>;
};

export default AreaModel;
