// useFeedback.js
import { gql, useMutation } from '@apollo/client';

const SUBMIT_FEEDBACK_MUTATION = gql`
  mutation submitFeedback($problemId: ID!, $feedback: String, $additionalFeedback: String) {
    submitFeedback(problemId: $problemId, feedback: $feedback, additionalFeedback: $additionalFeedback)
  }
`;

export function useSubmitFeedback() {
  const [submitFeedback, { data, loading, error }] = useMutation(SUBMIT_FEEDBACK_MUTATION);

  const submitFeedbackById = async (problemId, feedback, additionalFeedback) => {
    if (problemId && (feedback === 'POSITIVE' || feedback === 'NEGATIVE')) {
      const response = await submitFeedback({
        variables: { 
          problemId,
          feedback,
          additionalFeedback
        },
      });

      if (response) {
        // console.log('submitFeedback response', response?.data?.submitFeedback);
        return response?.data?.submitFeedback;
      }
    }
    return Promise.resolve(null);
  };

  return { data, loading, error, submitFeedbackById };
}