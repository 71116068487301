// ButtonFeedbackGroup.tsx
import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import ButtonFeedback, { ButtonFeedbackProps } from "./ButtonFeedback";

const ButtonFeedbackGroupRoot = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
`;

interface ButtonFeedbackGroupProps {
    options: Omit<ButtonFeedbackProps, 'onClick'>[];
    onChange?: (selectedIndex: number | undefined) => void;
    disabled?: boolean;
}

const ButtonFeedbackGroup: FunctionComponent<ButtonFeedbackGroupProps> = ({
    options,
    onChange = () => {},
    disabled = false
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

    const handleSelect = (index: number) => {
        const newIndex = selectedIndex === index ? undefined : index;
        setSelectedIndex(newIndex);
        onChange(newIndex);
    };

    return (
        <ButtonFeedbackGroupRoot>
            {options.map((option, index) => (
                <ButtonFeedback
                    key={index}
                    {...option}
                    selected={selectedIndex === index}
                    disabled={disabled}
                    onClick={() => handleSelect(index)}
                />
            ))}
        </ButtonFeedbackGroupRoot>
    );
};

export default ButtonFeedbackGroup;

