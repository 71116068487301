// ComputedProblemsContext.tsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useCopyProblemToAccount } from "../apollo/useQuestion";
import { getIsLoggedIn } from '../apollo/useAuth';
import { tokenStorage } from '../apollo/tokenStorage';

interface ComputedProblemsContextType {
  computedProblemIds: string[];
  setComputedProblemIds: (ids: string[]) => void;
  copyProblemsToAccount: (ids: string[]) => Promise<void>;
}

const ComputedProblemsContext = createContext<ComputedProblemsContextType | undefined>(undefined);

export const ComputedProblemsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [computedProblemIds, setComputedProblemIds] = useState<string[]>(() => {
    // Initialize from tokenStorage
    return tokenStorage.getComputedProblemIds();
  });

  const updateComputedProblemIds = useCallback((ids: string[]) => {
    setComputedProblemIds(ids);
    // Store in localStorage if user is not logged in
    if (!getIsLoggedIn()) {
      tokenStorage.setComputedProblemIds(ids);
    }
  }, []);

  // useEffect(() => {
  //   console.log('computedProblemIds', computedProblemIds);
  // }, [computedProblemIds]);

  const { copyProblemToAccount } = useCopyProblemToAccount();
  const copyProblemsToAccount = useCallback(async (ids: string[]) => {
    if (ids.length > 0 && getIsLoggedIn()) {
      try {
        for (const id of ids) {
          await copyProblemToAccount(id);
          // console.log('copied problem to account', id);
        }
      } catch (error) {
        if (error.message.includes('already copied')) {
            console.warn('Problem already in account.');
        } else if (error.message.includes('Problem not found')) {
            console.warn('Problem not found in database.');
        } else {
            console.error("Error copying problems to account:", error);
        }
      }
    }
  }, [copyProblemToAccount]);

  return (
    <ComputedProblemsContext.Provider value={{ 
      computedProblemIds, 
      setComputedProblemIds: updateComputedProblemIds,
      copyProblemsToAccount 
    }}>
      {children}
    </ComputedProblemsContext.Provider>
  );
};

export const useComputedProblems = () => {
  const context = useContext(ComputedProblemsContext);
  if (context === undefined) {
    throw new Error('useComputedProblems must be used within a ComputedProblemsProvider');
  }
  return context;
};
