// Expression.tsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import { useHover } from './HoverContext';
import { convertToSuperscript, convertFraction } from '../utils/beautifyText';
import { useProblemStateContext } from './ProblemState';

const EXPRESSION_COLOR = true;

interface Expression {
  type: string;
  value?: string | number;
  name?: string;
  unit?: string;
  id: string;
  color?: string;

  numeratorValue?: number;
  denominatorValue?: number;
  xValue?: number;
  yValue?: number;
}

interface ExpressionProps {
  expression: Expression[];
  problemId: string;
  partIndex: number;
  index: number;
}

const Quantity = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: ${Fonts.quicksandBold.fontFamily};
  font-weight: ${Fonts.quicksandBold.fontWeight};
  box-sizing: border-box;
`;

const Operator = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  box-sizing: border-box;
`;

const Variable = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: ${Fonts.quicksandBold.fontFamily};
  font-weight: ${Fonts.quicksandBold.fontWeight};
  box-sizing: border-box;
`;

const Name = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  box-sizing: border-box;
`;

const Unit = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: ${Fonts.quicksandLight.fontFamily};
  font-weight: ${Fonts.quicksandLight.fontWeight};
  box-sizing: border-box;
`;

const Space = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  box-sizing: border-box;
`;

const Expression: React.FC<ExpressionProps> = ({ expression, problemId, partIndex, index }) => {
  const { updateExpressions } = useProblemStateContext();
  const { hoveredColor, hoveredId, handleMouseEnter, handleMouseLeave, adjustColorForHover } = useHover();
  const [highlightedComponent, setHighlightedComponent] = useState<{ id: string; color: string } | null>(null);
  const expressionRef = useRef<HTMLDivElement>(null);

  const handleStateChange = (newState: any, skipLogic?: string | string[]) => {
    // console.log('expression handle state change', problemId, expression);
    const getFullPath = (skipLogic: string) => {
      return `parts[${partIndex}].visualizations[${index}].${skipLogic}`;
    };
    let skipLogicFullPath = undefined;
    if (skipLogic) {
      if (Array.isArray(skipLogic)) {
        skipLogicFullPath = skipLogic.map((logic) => getFullPath(logic));
      } else {
        skipLogicFullPath = getFullPath(skipLogic);
      }
      // console.log('skipLogic:', skipLogic, 'skipLogicFullPath:', skipLogicFullPath);
    }
    updateExpressions(problemId, partIndex, index, {
      ...expression,
      ...newState,
    }, skipLogicFullPath);    
  };

  const handleComponentClick = (component: Expression) => {
    
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (expressionRef.current && !expressionRef.current.contains(event.target as Node)) {
        handleMouseLeave();
        setHighlightedComponent(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleMouseLeave]);

  if (!expression || expression.length === 0) {
    return null;
  }

  return (
    <div ref={expressionRef}>
      {renderExpression(expression, handleMouseEnter, handleMouseLeave, highlightedComponent, handleComponentClick, hoveredColor, hoveredId, adjustColorForHover)}
    </div>
  );
};

const renderExpression = (
  expression: Expression[],
  handleMouseEnter: (color: string, id: string) => void,
  handleMouseLeave: () => void,
  highlightedComponent: { id: string; color: string } | null,
  handleComponentClick: (component: Expression) => void,
  hoveredColor: string | null,
  hoveredId: string | null,
  adjustColorForHover: (color: string) => string
) => {
  return expression.map((component, index) => {
    const { type, value, name, unit, color, id, numeratorValue, denominatorValue, xValue, yValue } = component;
    let fontColor = EXPRESSION_COLOR ? color : Colors.expressionDefault;
    if (!fontColor) fontColor = Colors.expressionDefault;
    
    // console.log('expression', value, 'color', fontColor);

    let displayValue = value;
    const transformExpression = () => {
      if (type === 'variable' || type === 'name') {
        displayValue = name || value;
      }
      if (type === 'fractionQuantity') {
        displayValue = convertFraction(numeratorValue, denominatorValue);
      }
      if (type === 'coordinate') {
        displayValue = `(${xValue}, ${yValue})`;
      }

      if (type === 'operator' && value === '^' && (expression[index + 1]?.type === 'quantity' || expression[index + 1]?.type === 'variable')) {
        displayValue = '';
      }
      if ((type === 'quantity' || type === 'variable') && expression[index - 1] && expression[index - 1].type === 'operator' && expression[index - 1].value === '^') {
        displayValue = convertToSuperscript(String(value));
      }

      if (type === 'operator' && (value === '*' || value === 'x' || value === '×' || value === '·') 
        && ((expression[index - 1]?.type === 'quantity' && !expression[index - 1]?.unit || expression[index - 1]?.type === 'fractionQuantity') 
        && expression[index + 1]?.type === 'variable')) {
        displayValue = '';
      }
    };
    transformExpression();

    const handleClickWrapper = () => {
      handleComponentClick(component);
    };

    return (
      <React.Fragment key={index}>
        {renderExpressionComponent(type, displayValue, unit, fontColor, handleClickWrapper)}
      </React.Fragment>
    );
  });
};

const renderExpressionComponent = (
  type: string,
  displayValue: React.ReactNode,
  unit: string | undefined,
  fontColor: string,
  handleClickWrapper: () => void
) => {
  switch (type) {
    case 'quantity':
    case 'fractionQuantity':
    case 'coordinate':
      return (
        <Quantity color={fontColor}>
          {renderUnitBeforeOrAfterValue(unit, displayValue, fontColor, handleClickWrapper)}
        </Quantity>
      );
    case 'variable':
      return (
        <Variable color={fontColor}>
          {renderUnitBeforeOrAfterValue(unit, displayValue, fontColor, handleClickWrapper)}
        </Variable>
      );
    case 'name':
      return (
        <Name color={fontColor} onClick={handleClickWrapper}>
          {displayValue}:{' '}
        </Name>
      );
    case 'operator':
      return (
        <Operator color={fontColor}>
          {displayValue}{' '}
        </Operator>
      );
    default:
      return <span>{displayValue}{' '}</span>;
  }
};

const renderUnitBeforeOrAfterValue = (
  unit: string | undefined,
  displayValue: React.ReactNode,
  fontColor: string,
  handleClickWrapper: () => void
) => {
  return (
    <>
      {unit === '$' && (
        <>
          <Unit color={fontColor} onClick={handleClickWrapper}>
            {unit}
          </Unit>
        </>
      )}
      <span onClick={handleClickWrapper}>{displayValue}</span>
      {unit && unit !== '$' && (
        <>
          {unit !== '%' && (<Space color={fontColor}>{' '}</Space>)}
          <Unit color={fontColor} onClick={handleClickWrapper}>
            {unit}
          </Unit>
        </>
      )}
      {' '}
    </>
  );
};

export default Expression;