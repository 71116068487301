// ButtonLink.tsx
import { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";

export type ButtonLinkType = {
    text?: string;
    onClick?: () => void;
    color?: "blue" | "black" | "white";
    size?: "small" | "large";
};

const getColor = (color: "blue" | "black" | "white" = "blue") => {
    switch (color) {
        case "black":
            return Colors.grey9;
        case "white":
            return Colors.grey1;
        case "blue":
        default:
            return Colors.blue;
    }
};

const getHoverColor = (color: "blue" | "black" | "white" = "blue") => {
    switch (color) {
        case "black":
            return Colors.grey9;
        case "white":
            return Colors.grey1;
        case "blue":
        default:
            return Colors.blueLight;
    }
};

const getActiveColor = (color: "blue" | "black" | "white" = "blue") => {
    switch (color) {
        case "black":
            return Colors.black;
        case "white":
            return Colors.white;
        case "blue":
        default:
            return Colors.blueDark;
    }
};

const getSize = (size: "small" | "large" = "small") => {
    switch (size) {
        case "large":
            return css`
                font-size: 24px;
                line-height: 32px;
                padding: 0px 10px;
                font-family: ${Fonts.quicksandSemiBold.fontFamily};
                font-weight: ${Fonts.quicksandSemiBold.fontWeight};
            `;
        case "small":
        default:
            return css`
                font-size: 14px;
                line-height: 22px;
                padding: 0px 10px;
                font-family: ${Fonts.quicksandMedium.fontFamily};
                font-weight: ${Fonts.quicksandMedium.fontWeight};
            `;
    }
};

const ButtonLinkRoot = styled.div<{ color: "blue" | "black" | "white" ; size: "small" | "large" }>`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-wrap: nowrap;
    cursor: pointer;
    color: ${({ color }) => getColor(color)};
    ${({ size }) => getSize(size)}

    &:hover {
        color: ${({ color }) => getHoverColor(color)}; 
        text-decoration: underline;
    }

    &:active {
        color: ${({ color }) => getActiveColor(color)};
        text-decoration: underline;
    }

    &:disabled {
        cursor: default;
    }
`;

const ButtonLink: FunctionComponent<ButtonLinkType> = ({ text, onClick = () => {}, color = "blue", size = "small" }) => {
    return (
        <ButtonLinkRoot color={color} size={size} onClick={onClick}>
            {text}
        </ButtonLinkRoot>
    );
};

export default ButtonLink;
