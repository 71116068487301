// NumberLine.tsx
import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import { useHover } from '../HoverContext';
import Colors from '../common/Colors';
import Dragger from './interactives/Dragger';
import { getMaxDecimalPlaces } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

export interface NumberLineProps {
  min?: number | null;
  max?: number | null;
  tick?: number | null;
  tickOffset?: number | null;
  data: Array<{
    type: 'line' | 'hop' | 'point';
    start: number;
    end?: number | null;
    hopInterval?: number | null;
    startPoint?: 'dot' | 'circle' | 'arrow' | null;
    endPoint?: 'dot' | 'circle' | 'arrow' | null;
    hopPoints?: 'dot' | 'circle' | 'arrow' | null;
    renderPartialHops?: boolean | null;
    startColor: string;
    endColor: string;
    lineColor: string;
    hopColor?: string | null;
    startLabel: string | null;
    endLabel: string | null;
    hopLabels?: string | null;
    partialHopLabel?: string | null;
    lineLabel?: string | null;
    range?: number | null;
    images?: Array<{ name: string; start: number; end: number; color: string; img?: string; }> | null;
    startDraggable?: boolean | null;
    startDragMin?: number | null;
    startDragMax?: number | null;
    endDraggable?: boolean | null;
    endDragMin?: number | null;
    endDragMax?: number | null;
    dragTick?: number | null;
    startTutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;
    endTutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;
  }>;
  actualScale?: number;
  onStateChange?: (state: NumberLineProps, skipLogic?: string) => void;
}

const numberLinePropsTypeDefinition: NumberLineProps = {
  min: 0,
  max: 0,
  tick: 0,
  tickOffset: 0,
  actualScale: 1,
  data: [
    {
      type: 'line',
      start: 0,
      end: 0,
      hopInterval: 0,
      startPoint: 'dot',
      endPoint: 'dot',
      hopPoints: 'arrow',
      renderPartialHops: false,
      startColor: '',
      endColor: '',
      lineColor: '',
      hopColor: '',
      startLabel: '',
      endLabel: '',
      hopLabels: '',
      partialHopLabel: '',
      lineLabel: '',
      range: 0,
      images: [
        {
          name: '',
          start: 0,
          end: 0,
          color: '',
          img: ''
        }
      ],
      startDraggable: false,
      startDragMin: 0,
      startDragMax: 0,
      endDraggable: false,
      endDragMin: 0,
      endDragMax: 0,
      dragTick: 0,
    },
  ],
  onStateChange: () => {},
};

const formatData = (data: NumberLineProps['data']) => {
  return data.map(segment => {
    const range = getRange(segment);
    // console.log('start', segment.start, 'end', segment.end, 'range', range);
    return {
      ...segment,
      renderPartialHops: true,
      range: range,
    };
  });
};

const getRange = (segment) => {
  let range = null;
  if (segment.start !== undefined && segment.end !== undefined) {
    range = Math.abs(segment.end - segment.start);
    // console.log('start and end', segment.start, segment.end, range);
  } else if (segment.start !== undefined || segment.end !== undefined) {
    range = 0;
  }
  // console.log('range', range);
  return range;
};

// TODO: fix rangeVar not updating when range is updated

const NumberLine: React.FC<NumberLineProps> = (props) => {
  const formattedData = formatData(props.data);
  props = { ...props, data: formattedData };
  props = convertAllTypes(props, numberLinePropsTypeDefinition);
  // console.log(props);

  const MIN_SCALE = 0.37;
  let { actualScale = 1 } = props;
  if (actualScale < MIN_SCALE) actualScale = MIN_SCALE;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useHover(); 

  const decimalPrecision = useMemo(() => {
    const dragTicks = props.data.map(segment => segment.dragTick).filter((hopInterval): hopInterval is number => hopInterval !== undefined);
    return getMaxDecimalPlaces([...(props.tick !== undefined ? [props.tick] : []), ...dragTicks]);
  }, [props.tick]);

  const allValues = useMemo(() => props.data.flatMap(segment => [segment.start, segment.end].filter((value): value is number => value !== undefined)), [props.data]);
  const dataMin = useMemo(() => d3.min(allValues) as number, [allValues]);
  const dataMax = useMemo(() => d3.max(allValues) as number, [allValues]);
  const padding = 0.1;
  const paddedMin = Math.floor(dataMin - Math.abs(dataMin * padding));
  const paddedMax = Math.ceil(dataMax + Math.abs(dataMax * padding));

  let { min, max, tick, tickOffset } = props;
  min = min !== undefined ? min : paddedMin;
  max = max !== undefined ? max : paddedMax;
  tick = tick !== undefined ? tick : (max - min) / 10;
  const numTicks = ((max - min) / tick) + 1;
  // console.log(`min: ${min} max: ${max} tick: ${tick} numTicks: ${numTicks}`);

  const width = 800;
  const baseHeight = 80/actualScale;
  const hopHeight = 50;
  const imageSize = Math.min(Math.max(60 - (30 * (numTicks - 12)) / (15 - 12), 20), 60);
  // console.log('num ticks', numTicks, 'image size', imageSize);
  
  const hasHops = props.data.some(segment => segment.type === 'hop');
  const hasImages = props.data.some(segment => segment.images && segment.images.length > 0);
  const hasLabels = props.data.some(segment => segment.lineLabel || segment.hopLabels);
  const height = hasHops ? baseHeight + hopHeight : baseHeight;

  const margin = { 
    top: (hasHops && hasLabels ? hopHeight : (hasHops || hasLabels ? hopHeight*0.6 : 10)) / actualScale,
    right: 30 / actualScale, 
    bottom: (hasImages ? imageSize*1.8 : 30) / actualScale,
    left: 30 / actualScale 
  };

  const pointSize = 8 / actualScale;
  const fontSize = 32 / actualScale;
  const fontSizeSmall = 24 / actualScale;
  const textYPos = 28;
  const textOffset = 25 / actualScale;

  const xScale = useMemo(() => d3.scaleLinear()
    .domain([min, max])
    .range([margin.left + pointSize * 2, width - margin.right - pointSize * 2]), [min, max, margin.left, margin.right]);

  const isValueRenderedInAxis = useCallback((value: number): boolean => {
    const tickValues = tickOffset !== undefined && tickOffset !== null
      ? [min, tickOffset, ...d3.range(tickOffset + tick, max + tick, tick)]
      : d3.range(min, max + tick, tick);
    return tickValues.includes(value);
  }, [min, max, tick, tickOffset]);

  const handleDrag = useCallback((index: number, position: 'start' | 'end', newValue: number) => {
    // console.log('numberLine handleDrag', newValue); 
  
    const updatedData = props.data.map((segment, i) => {
      if (i === index) {
        const newSegment = {
          ...segment,
          [position]: newValue,
        };
        newSegment.range = getRange(newSegment);
        // console.log('newSegment', newSegment);
        return newSegment;
      } else {
        return segment;
      }
    });

    const skipLogic = `data[${index}].${position}`;
    if (skipLogic) setHasInteracted(true);
    // console.log('numberLine skipLogic', skipLogic);

    if (props.onStateChange) {
      const updatedState = {
        ...props,
        data: updatedData,
      };
      // console.log('Updated state before calling onStateChange:', updatedState, skipLogic);
      props.onStateChange(updatedState, skipLogic);
    }
  }, [props]);  

  const renderBase = useCallback((svg) => {
    const labelStyles = {
      default: {
        fontFamily: Fonts.quicksandLight.fontFamily,
        fontWeight: Fonts.quicksandLight.fontWeight,
        fill: Colors.grey6,
      },
      emphasized: {
        fontFamily: Fonts.quicksandMedium.fontFamily,
        fontWeight: Fonts.quicksandMedium.fontWeight,
      }
    };
  
    // Explicity generate tick values
    const tickValues = tickOffset !== undefined && tickOffset !== null
      ? [min, tickOffset, ...d3.range(tickOffset + tick, max + tick, tick)]
      : d3.range(min, max + tick, tick);
    const xAxis = d3.axisBottom(xScale)
      .tickValues(tickValues) // Use the explicitly set tick values
      .tickFormat((d: d3.NumberValue, i: number) => {
        const num = Number(d);
        if (props.data.some(segment => (segment.start === num && (segment.startLabel !== null && segment.startLabel !== undefined)) 
          || (segment.end === num && (segment.endLabel !== null && segment.endLabel !== undefined)))) {
          return null; // Exclude labels that match startLabel or endLabel
        }
        if (min % 1 !== 0 || max % 1 !== 0 || tick % 1 !== 0) {
          return num.toFixed(1); // Include decimals
        }
        if (num < 0) {
          return '-' + Math.abs(num); // draw '-' symbol for negative numbers
        }
        return d3.format('d')(num); // Always render default labels
      })
      .tickSize(8);
  
    const g = svg.append('g')
      .attr('transform', `translate(0,${height / 2 + margin.top})`)
      .call(xAxis);
  
    // Number line
    svg.append('line')
      .attr('x1', xScale(min) - pointSize * 2)
      .attr('y1', height / 2 + margin.top)
      .attr('x2', xScale(max) + pointSize * 2)
      .attr('y2', height / 2 + margin.top)
      .attr('stroke', 'black')
      .attr('stroke-width', 4)
      .attr('stroke-linecap', 'round')
      .attr('marker-start', 'url(#arrowhead-left)')
      .attr('marker-end', 'url(#arrowhead-right)');
  
    // Arrowheads
    svg.append('defs').append('marker')
      .attr('id', 'arrowhead-left')
      .attr('viewBox', '-10 -5 10 10')
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 6)
      .attr('markerHeight', 12)
      .append('svg:path')
      .attr('d', 'M 0,-5 L -10,0 L 0,5')
      .attr('fill', Colors.black)
      .style('stroke', 'none');
  
    svg.append('defs').append('marker')
      .attr('id', 'arrowhead-right')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 6)
      .attr('markerHeight', 12)
      .append('svg:path')
      .attr('d', 'M 0,-5 L 10,0 L 0,5')
      .attr('fill', Colors.black)
      .style('stroke', 'none');

    // Default axis labels
    let axisFontSize = fontSize;
    if (numTicks > 2) axisFontSize = Math.min(38, fontSize);
    g.selectAll('.tick text')
      .style('font-size', `${axisFontSize}px`)
      .attr('y', textYPos + 2/actualScale)
      .attr('alignment-baseline', 'top')
      .style('font-family', labelStyles.default.fontFamily)
      .style('font-weight', labelStyles.default.fontWeight)
      .style('fill', labelStyles.default.fill);
  }, [xScale, min, max, tick, height, margin.top, pointSize, props.data, fontSize, textYPos]);  
  
  const createArrowheadMarker = useCallback((svg, color: string, id: string) => {
    svg.append('defs').append('marker')
      .attr('id', id)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 8)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 4)
      .attr('markerHeight', 8)
      .attr('xoverflow', 'visible')
      .append('svg:path')
      .attr('d', 'M 0,-5 L 10,0 L 0,5')
      .attr('fill', color)
      .style('stroke', 'none');
  }, []);

  const drawPoint = useCallback((svg, x: number, type: 'dot' | 'circle' | 'arrow' | 'null', color: string, className: string) => {
    if (type === 'dot') {
      svg.append('circle')
        .attr('cx', x)
        .attr('cy', height / 2 + margin.top)
        .attr('r', pointSize)
        .attr('fill', color)
        .attr('class', className)
        .on('click', () => {
          handleMouseEnter(color);
        });
    } else if (type === 'circle') {
      svg.append('circle')
        .attr('cx', x)
        .attr('cy', height / 2 + margin.top)
        .attr('r', pointSize * 0.9)
        .attr('fill', 'white')
        .attr('stroke', color)
        .attr('stroke-width', 3)
        .attr('class', className)
        .on('click', () => {
          handleMouseEnter(color);
        });
    }
  }, [height, margin.top, pointSize, handleMouseEnter]);

  const adjustPosition = useCallback((svg, x: number, pointType: 'dot' | 'circle' | 'arrow' | 'null') => {
    if (pointType === 'dot' || pointType === 'circle') {
      return x - 2;
    } else if (pointType === 'arrow') {
      return x - 5;
    } else {
      return x;
    }
  }, []);

  const renderLabel = useCallback((svg, position, value, color, label, context) => {
    if (label && value !== undefined && value !== null) {
      svg.append('text')
        .attr('x', xScale(value))
        .attr('y', height / 2 + margin.top + textYPos)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'hanging')
        .attr('fill', color)
        .style('font-family', Fonts.quicksandMedium.fontFamily)
        .style('font-weight', Fonts.quicksandMedium.fontWeight)
        .style('font-size', `${fontSize}px`)
        .text(label)
        .on('click', () => {
          handleMouseEnter(color);
        });
    }
  }, [props, xScale, height, margin.top, textOffset, fontSize, handleMouseEnter]);
  
  const renderHops = useCallback((svg, segment, start, end, hopInterval, controlPointY, uniqueId) => {
    // console.log(`start ${start} end ${end} hopInterval ${hopInterval}`);

    const segmentRange = Math.abs(segment.end - segment.start);
    if (hopInterval > segmentRange) {
      console.warn('numberLine invalid hopInterval', `segmentRange ${segmentRange} hopInterval ${hopInterval}`)
    }
    // console.log(`segmentRange ${segmentRange} hopInterval ${hopInterval}`);
    
    let numHopsData = Math.abs((end - start) / hopInterval);
    if (segment.renderPartialHops !== true) numHopsData = Math.floor(numHopsData);
    // console.log(`numHops ${numHopsData}`);

    // Show a vertical hop for interval 0
    if (hopInterval === 0) {
      hopInterval = 0.0000000000001;
      numHopsData = 1;
    }

    if (hopInterval !== undefined && hopInterval !== null) {
      for (let i = 0; i < numHopsData; i++) {
        const currentPos = start < end ? start + i * hopInterval : start - i * hopInterval;
        const startX = xScale(currentPos);
        let endX = xScale(start < end ? currentPos + hopInterval : currentPos - hopInterval);
        if (segment.renderPartialHops === true && i+1 > numHopsData) {
          const partialHopNumber = numHopsData-i;
          const partialHopInterval = partialHopNumber * hopInterval;
          endX = xScale(start < end ? currentPos + partialHopInterval: currentPos - partialHopInterval);
          // console.log('this is a partial hop', partialHopNumber, partialHopInterval, Math.abs(startX-endX));
        }
        const midX = (startX + endX) / 2;
  
        const markerId = `arrowhead-hopInterval-${currentPos}-${uniqueId}`;
        createArrowheadMarker(svg, segment.lineColor, markerId);
        const pathD = `M${startX},${height / 2 + margin.top - 20} Q${midX},${controlPointY - 20} ${endX},${height / 2 + margin.top - 20}`;
  
        svg.append('path')
          .attr('d', pathD)
          .attr('fill', 'none')
          .attr('stroke', 'transparent')
          .attr('stroke-width', 20)
          .attr('pointer-events', 'stroke')
          .on('click', () => {
            handleMouseEnter(segment.lineColor);
          });
  
        const path = svg.append('path')
          .attr('d', pathD)
          .attr('fill', 'none')
          .attr('stroke', segment.lineColor)
          .attr('stroke-width', 4)
          .attr('stroke-linecap', 'round')
          .attr('stroke-linejoin', 'round');
  
        if (segment.hopPoints === 'arrow' || segment.endPoint === 'arrow') {
          path.attr('marker-end', `url(#${markerId})`);
        }
  
        if (segment.hopPoints === 'dot') {
          drawPoint(svg, endX, 'dot', segment.lineColor, 'hopInterval-point');
        } else if (segment.hopPoints === 'circle') {
          drawPoint(svg, endX, 'circle', segment.lineColor, 'hopInterval-point');
        }
  
        if (segment.hopLabels && !(i+1 > numHopsData && !segment.partialHopLabel)) {
          const textY = segment.hopLabels && segment.lineLabel ? controlPointY + textYPos + 4 : controlPointY - textYPos;
          const fontWeight = segment.hopLabels && segment.lineLabel ? Fonts.quicksandLight.fontWeight : Fonts.quicksandMedium.fontWeight;
          svg.append('text')
            .attr('x', midX)
            .attr('y', textY)
            .attr('text-anchor', 'middle')
            .attr('fill', segment.hopColor || segment.lineColor)
            .style('font-family', Fonts.quicksandMedium.fontFamily)
            .style('font-weight', Fonts.quicksandMedium.fontWeight)
            .style('font-size', `${fontSize}px`)
            .text(i+1 > numHopsData ? segment.partialHopLabel : segment.hopLabels)
            .on('click', () => {
              handleMouseEnter(segment.lineColor);
            });
        }
      }
    } else {
      const startX = xScale(start);
      const endX = xScale(end);
      const midX = (startX + endX) / 2;
  
      const markerId = `arrowhead-hopInterval-${uniqueId}`;
      createArrowheadMarker(svg, segment.lineColor, markerId);
  
      const pathD = `M${startX},${height / 2 + margin.top - 20} Q${midX},${controlPointY - 20} ${endX},${height / 2 + margin.top - 20}`;
  
      svg.append('path')
        .attr('d', pathD)
        .attr('fill', 'none')
        .attr('stroke', 'transparent')
        .attr('stroke-width', 20)
        .attr('pointer-events', 'stroke')
        .on('click', () => {
          handleMouseEnter(segment.lineColor);
        });
  
      const path = svg.append('path')
        .attr('d', pathD)
        .attr('fill', 'none')
        .attr('stroke', segment.lineColor)
        .attr('stroke-width', 4)
        .attr('stroke-linecap', 'round')
        .attr('stroke-linejoin', 'round');
  
      if (segment.hopPoints === 'arrow' || segment.endPoint === 'arrow') {
        path.attr('marker-end', `url(#${markerId})`);
      }
  
      if (!segment.startDraggable) {
        drawPoint(svg, xScale(start), segment.startPoint, segment.startColor, 'start-point');
      }
      if (!segment.endDraggable) {
        drawPoint(svg, xScale(end), segment.endPoint, segment.endColor, 'end-point');
      }
    }
  
    if (!segment.startDraggable) {
      drawPoint(svg, xScale(start), segment.startPoint, segment.startColor, 'start-point');
    }
    if (!segment.endDraggable) {
      drawPoint(svg, xScale(end), segment.endPoint, segment.endColor, 'end-point');
    }
  
    if (segment.lineLabel) {
      const yPosition = hasHops ? 
        (segment.type === 'hop' ? controlPointY - textYPos 
          : height / 2 - hopHeight/2 + margin.top - textYPos*0.8) 
          : height / 2 + margin.top - textYPos;

      svg.append('text')
        .attr('x', (xScale(start) + xScale(end)) / 2)
        .attr('y', yPosition)
        .attr('text-anchor', 'middle')
        .attr('fill', segment.lineColor)
        .style('font-family', Fonts.quicksandMedium.fontFamily)
        .style('font-weight', Fonts.quicksandMedium.fontWeight)
        .style('font-size', `${fontSize}px`)
        .text(segment.lineLabel)
        .on('click', () => {
          handleMouseEnter(segment.lineColor);
        });
      }
  }, [xScale, createArrowheadMarker, drawPoint, handleMouseEnter, height, margin.top, textYPos]);

  const renderLine = useCallback((svg, segment, start, end, uniqueId, context) => {
    const startX = xScale(start);
    const endX = (end !== undefined && end !== null) ? xScale(end) : startX;
    // console.log('start', start, 'end', end, 'startX', startX, 'endX', endX);

    if (end !== undefined) {
      const markerId = `arrowhead-line-${uniqueId}`;
      createArrowheadMarker(svg, segment.lineColor, markerId);
  
      const line = svg.append('line')
        .attr('x1', startX)
        .attr('y1', height / 2 + margin.top)
        .attr('x2', endX)
        .attr('y2', height / 2 + margin.top)
        .attr('stroke', segment.lineColor)
        .attr('stroke-width', 4)
        .attr('stroke-linecap', 'round')
        .on('click', () => {
          handleMouseEnter(segment.lineColor);
        });
  
      if (segment.endPoint === 'arrow') {
        line.attr('marker-end', `url(#${markerId})`);
      }
  
      if (!segment.startDraggable) {
        drawPoint(svg, xScale(start), segment.startPoint, segment.startColor, 'start-point');
      }
      if (!segment.endDraggable) {
        drawPoint(svg, endX, segment.endPoint, segment.endColor, 'end-point');
      }
    } else {
      drawPoint(svg, xScale(start), segment.startPoint, segment.startColor, 'start-point');
    }

    if (segment.lineLabel) {
      const yPosition = hasHops ? 
        (segment.type === 'hop' ? height / 2 - hopHeight + margin.top - textYPos : height / 2 - hopHeight/2 + margin.top - textYPos*0.8) :
        height / 2 + margin.top - textYPos;

      const xPos = (startX + endX) / 2;
      // console.log('line label', segment.lineLabel, 'start x', startX, 'end x', endX, 'x pos', xPos);
      svg.append('text')
        .attr('x', xPos)
        .attr('y', yPosition)
        .attr('text-anchor', 'middle')
        .attr('fill', segment.lineColor)
        .style('font-family', Fonts.quicksandMedium.fontFamily)
        .style('font-weight', Fonts.quicksandMedium.fontWeight)
        .style('font-size', `${fontSize}px`)
        .text(segment.lineLabel)
        .on('click', () => {
          handleMouseEnter(segment.lineColor);
        });
      }
  }, [adjustPosition, xScale, createArrowheadMarker, drawPoint, handleMouseEnter, height, margin.top, textYPos, fontSize]);

  const renderImages = useCallback((svg) => {
    const g = svg.select('g');
  
    g.selectAll('.tick')
      .filter(d => d === min - tick || d === max + tick)
      .remove();
  
    if (hasImages) {
      props.data.forEach(segment => {
        if (segment.images) {
          segment.images.forEach(image => {
            for (let i = image.start; i <= image.end; i++) {
              g.selectAll('.tick')
                .filter(d => d === i)
                .append('image')
                .attr('xlink:href', image.name)
                .attr('width', imageSize)
                .attr('height', imageSize)
                .attr('x', -imageSize / 2)
                .attr('y', 70)
                .on('click', (event, d) => {
                  event.stopPropagation();
                  handleMouseEnter(image.color);
                })
                .on('mouseleave', (event) => {
                  event.stopPropagation();
                });
            }
          });
        }
      });
    }
  }, [handleMouseEnter, hasImages, imageSize, min, max, tick, props]);

  const renderData = useCallback((svg) => {
    const controlPointY = height / 2 - hopHeight + margin.top;
  
    const draggerConfigs = props.data.reduce((configs, segment, index) => {
      const { type = 'line', startColor = Colors.vizDefault, endColor = Colors.vizDefault, lineColor = Colors.vizDefault, startLabel, endLabel, startDraggable, endDraggable, dragTick } = segment;
      const start = segment.start, end = segment.end;
      const hopInterval = segment.hopInterval ? Math.abs(segment.hopInterval) : Math.abs(end - start);
      const uniqueId = `${start}-${end}-${index}-${startColor}-${endColor}-${lineColor}`;
      const context = { startData: start, endData: end };
      const startTutorial = segment.startTutorial;
      const endTutorial = segment.endTutorial;

      const startDragMin = segment.startDragMin !== undefined && segment.startDragMin !== null ? segment.startDragMin : min;
      const startDragMax = segment.startDragMax !== undefined && segment.startDragMax !== null ? segment.startDragMax : max;
      const endDragMin = segment.endDragMin !== undefined && segment.endDragMin !== null ? segment.endDragMin : min;
      const endDragMax = segment.endDragMax !== undefined && segment.endDragMax !== null ? segment.endDragMax : max;
  
      renderLabel(svg, 'start', start, startColor, startLabel, context);
      renderLabel(svg, 'end', end, endColor, endLabel, context);

      if (type === 'hop' && end !== undefined && end !== null) {
        if (!segment.hopPoints) segment.hopPoints = 'arrow';
        renderHops(svg, segment, start, end, hopInterval, controlPointY, uniqueId)
      } else {
        renderLine(svg, segment, start, end, uniqueId, context)
      }

      const tickOffsetCalc = index !== 0 ? segment.start : undefined;
      // console.log(`segment ${index} tickOffset ${tickOffsetCalc}`);
  
      if (start !== undefined && start !== null) configs.push({ index, position: 'start', value: start, color: startColor, draggable: startDraggable, dragTick, dragMin: startDragMin, dragMax: startDragMax, direction: 'right', tutorial: startTutorial });
      if (end !== undefined && end !== null) configs.push({ index, position: 'end', value: end, color: endColor, draggable: endDraggable, dragTick, dragMin: endDragMin, dragMax: endDragMax, direction: 'right', tutorial: endTutorial, tickOffset: tickOffsetCalc });
      return configs;
    }, []);
  
    draggerConfigs.forEach(config => renderDragger(config.index, config.position, config.value, config.color, config.draggable, config.dragTick, config.dragMin, config.dragMax, config.direction, config.tutorial, config.tickOffset));
  }, [props, decimalPrecision, handleDrag, height, hopHeight, isValueRenderedInAxis, margin.top, renderHops, renderLabel, renderLine, xScale, min, max, tick]);

  const renderDragger = (index: number, position: 'start' | 'end', value: number, color: string, draggable: boolean, dragTick: number | undefined, dragMin: number | null, dragMax: number | null, direction: 'right' | 'left', tutorial: any, tickOffset?: number) => {
    if (!draggable) return;
  
    Dragger({
      svgRef: svgRef,
      id: `dragger-${position}-${index}`,
      color,
      direction,
      x: xScale(value),
      y: height / 2 + margin.top,
      minPos: xScale(dragMin),
      maxPos: xScale(dragMax),
      value,
      minVal: dragMin,
      maxVal: dragMax,
      tick: dragTick ? dragTick : null,
      tutorial: hasInteracted ? null : tutorial,
      decimalPrecision: dragTick ? decimalPrecision : null,
      tickOffset,
      actualScale: actualScale,
      handleDrag: (newValue) => handleDrag(index, position, newValue)
    });
  };  

  const render = useCallback(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height + margin.top + margin.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', 'auto');

    svg.selectAll('*').remove();

    renderBase(svg);
    renderImages(svg);
    renderData(svg);
  }, [renderBase, renderImages, renderData, width, height, margin.top, margin.bottom]);

  useEffect(() => {
    render();
  }, [min, max, tick, props, handleMouseEnter, handleMouseLeave, render]);

  return <svg ref={svgRef}></svg>;
};

export default NumberLine;
